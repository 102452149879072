import axios from "axios"
import ApiManager from '@/helpers/ApiManager.js'
axios.defaults.baseURL = process.env.VUE_APP_API_URL

const getDefaultState = () => {
    return {
        registrationForm: {
            isExperience: false,
            isTransfer: false,
            businessName: "",
            taxNumber: "",
            iban: "",
            bicswift: "",
            address: "",
            logo: [],
            country: "pt",
            zipCode: "",
            city: "",
            numberOfEmployees: null,
            tradeName: "",
            description: "",
            website: "",
            email: "",
            firstName: "",
            lastName: "",
            password: "",
            confirmPassword: "",
            phone: ""
        },
        errors: {},
        registrationFormFieldsLocation: {
            businessName: 1,
            taxNumber: 1,
            iban: 1,
            bicswift: 1,
            numberOfEmployees: 1,
            country: 1,
            city: 1,
            address: 1,
            zipCode: 1,
    
            tradeName: 2,
            description: 2,
            website: 2,
            logo: 2,
            
            firstName: 3,
            lastName: 3,
            phone: 3,
            email: 3,
            password: 3,
            confirmPassword: 3
    
        },
        activeServices: [],
        selectedServices: [],
        activeServicesSearchString: "",
        fileredServices: [],
        filteredVehicles: [],
        selectedVehicles: [],
        activeVehicles: [],
        activeVehiclesSearchString: "",
        companies: null,
        serviceViews: [],
        teamMembers: [],
        roles: [],
    }
}

const state = getDefaultState()

const getters = {
    getForm: (state) => state.registrationForm,
    getFormErrors: (state) => state.errors,
    getRegisterCountryOptions: (state, getters, rootState, rootGetters) => {
        let response = {}
        for (let key in rootGetters['frontend/getPlatformCountries']) {
            response[key] = rootGetters['frontend/getPlatformCountries'][key].name
        }
        return response
    },
    getRegisterCityOptions: (state, getters, rootState, rootGetters) => {
        if (!state.registrationForm.country || !rootGetters['frontend/getPlatformCountries']) {
            return {}
        }

        const country = state.registrationForm.country.toLowerCase()

        return rootGetters['frontend/getPlatformCountries'][country]?.cities?.reduce((accumulator, value) => {
            return {...accumulator, [value]: value}
        }, {})
    },
    getRegistrationFormFieldsLocation: (state) => state.registrationFormFieldsLocation,
    getActiveServices: (state) => state.activeServices,
    getFilteredServices: (state) => state.fileredServices,
    getSelectedServices: (state) => state.selectedServices,
    getActiveServicesSearchString: (state) => state.activeServicesSearchString,
    getAutocompleteSuggestion: (state) => {
        if (
            state.activeServicesSearchString === "" ||
            state.activeServicesSearchString.length < 2
        ) {
            return null
        }

        let searchString = state.activeServicesSearchString.toLowerCase()
        let filterResult = state.activeServices.find(
            service => service.name.toLowerCase().startsWith(searchString)
        );

        return filterResult
            ? filterResult.name
            : null
    },
    getFilteredVehicles: (state) => state.filteredVehicles,
    getSelectedVehicles: (state) => state.selectedVehicles,
    getActiveVehicles: (state) => state.activeVehicles,
    getActiveVehiclesSearchString: (state) => state.activeVehiclesSearchString,
    getCompanies: state => state.companies,
    getServiceViews: state => state.serviceViews,
    getTeamMembers: state => state.teamMembers,
    getRoles: state => state.roles,
    getRoleOptions: state => {
        return state.roles.reduce((result, role) => {
            result[role.id] = {
                label: role.role_name,
                description: role.description
            }
            return result
        }, {})
    }
}

const actions = {
    presetFormData: ({ commit }, payload) => {
        commit('RESET_FORM_DATA', payload)
        return true
    },
    setFormData: ({ commit }, payload) => {
        commit('RESET_FIELD_ERROR', payload)
        const [key] = Object.keys(payload)
        if(key === 'country') {
            payload = {...payload, city: ''}
        }
        commit('SET_FORM_DATA', payload)
    },

    submitForm: async ({ state, commit }) => {
        let formData = new FormData()
        for (const [key, value] of Object.entries(state.registrationForm)) {
            if (typeof value === "object") {
                for (let item in value) {
                    formData.append(key + "[]", value[item])
                }
            } else {
                if (['isExperience', 'isTransfer'].includes(key)) {
                    formData.append(key, value ? 1 : 0)
                } else {
                    formData.append(key, value)
                }
            }
        }

        return await ApiManager.post(
            `/api/partners`,
            formData,
            {
                'Content-Type': 'multipart/form-data'
            }
        ).then((r) => {
            if(r.errors) {
                commit("SET_ERRORS", r.errors)
                return false
            }

            if (!r.data.status) {
                return false
            }

            localStorage.setItem('confirmEmail', state.registrationForm.email)

            return true  
        })
    },

    submitUpdateForm: async ({ state, commit, rootState }) => {
        let formData = new FormData()
        for (const [key, value] of Object.entries(state.registrationForm)) {
            
            if (typeof value === "object") {
                for (let item in value) {
                    formData.append(key + "[]", value[item])
                }
            } else if(value !== "") {
                if (['isExperience', 'isTransfer'].includes(key)) {
                    formData.append(key, value ? 1 : 0)
                } else {
                    formData.append(key, value)
                }
            }
        }

        return await ApiManager.post(
            `/api/dashboard/partners/${rootState.user.user.id}`,
            formData,
            {
                'Content-Type': 'multipart/form-data'
            }
        ).then((r) => {
            if (r.errors) {
                commit("SET_ERRORS", r.errors)
            }
            
            return true
        }).catch((e) => {
            console.error(e)
            return false
        })

    },

    submitTransfersForm: async ({ state, commit }, payload) => {
        let formData = new FormData()
        for (const [key, value] of Object.entries(payload)) {
            
            if (typeof value === "object") {
                for (let item in value) {
                    formData.append(key + "[]", value[item])
                }
            } else {
                formData.append(key, value)
            }
            
        }

        return await ApiManager.post(
            `/api/partners-transfers`,
            formData,
            {
                'Content-Type': 'multipart/form-data'
            }
        ).then((r) => {
            if(!r) {
                return false
            }
            if (r.errors) {
                commit("SET_ERRORS", r.errors)
                return false
            }

            localStorage.setItem('confirmEmail', payload.email)
            
            return true
        })
        
    },

    resetErrors: ({ commit }) => {
        commit("SET_ERRORS", {})
    },

    fetchServices: async ({ commit, dispatch }, status) => {

        let getAttempt = await ApiManager.get(`/api/dashboard/services?status=${status}`)
        if (getAttempt) {
            commit("SET_ACTIVE_SERVICES", getAttempt.data)
            dispatch("applyFilter")
            return true
        }
        return false
    },

    disableService: async ({ commit }, payload) => {
        await ApiManager.post(`/api/dashboard/services/${payload.id}/status`, {service_status:"inactive"})
        return true
    },

    enableService: async ({ commit }, payload) => {
        await ApiManager.post(`/api/dashboard/services/${payload.id}/status`, {service_status:"active"})
        return true
    },

    bulkUpdate: async ({ commit }, payload) => {
        await ApiManager.post(`/api/dashboard/services/bulk-update`, payload)
        return true
    },

    setSelected: ({ state, commit }, payload) => {
        commit("SET_SELECTED_SERVICES", payload)
    },

    resetSelected: ({commit}) => {
        commit("SET_SELECTED_SERVICES", [])
    },

    addSelected: ({ commit, state }, payload) => {
        let tempSelectedServices = state.selectedServices
        tempSelectedServices.push(payload)
        commit("SET_SELECTED_SERVICES", tempSelectedServices)
    },

    removeSelected: ({ commit, state }, payload) => {
        let tempSelectedServices = state.selectedServices.filter(id => id !== payload)
        commit("SET_SELECTED_SERVICES", tempSelectedServices)
    },

    setActiveServicesSearchString: ({ commit }, payload) => {
        commit("SET_ACTIVE_SERVICES_SEARCH_STRING", payload)
    },

    applyFilter: ({ commit }, payload) => {
        let tempServices = structuredClone(state.activeServices)
        if (state.activeServicesSearchString === "") {
            commit("SET_FILTERED_SERVICES", tempServices)
        } 

        let searchStr = state.activeServicesSearchString.toLowerCase()
        
        tempServices = tempServices.filter((service) => {
            return service.name.toLowerCase().includes(searchStr)
        })

        commit("SET_FILTERED_SERVICES", tempServices)
    },
    
    applyVehicleFilter: ({ commit, state }, payload) => {
        let tempVehicles = structuredClone(state.activeVehicles)
        if (state.activeServicesSearchString === "") {
            commit("SET_FILTERED_VEHICLES", tempVehicles)
        } 

        let searchStr = state.activeServicesSearchString.toLowerCase()
        
        tempVehicles = tempVehicles.filter((vehicle) => {
            return vehicle.car_type.toLowerCase().includes(searchStr) || vehicle.accessibility.toLowerCase().includes(searchStr)
        })

        commit("SET_FILTERED_VEHICLES", tempVehicles)
    },

    resetState: ({ commit }) => {
        commit("RESET_STATE")
    },

    setSelectedVehicles: ({ state, commit }, payload) => {
        commit("SET_SELECTED_VEHICLES", payload)
    },

    resetSelectedVehicles: ({commit}) => {
        commit("SET_SELECTED_VEHICLES", [])
    },

    fetchVehicles: async ({ commit, dispatch }, status) => {
        let getAttempt = await ApiManager.get(`/api/dashboard/vehicles?vehicle_status=${status}`)
        if (getAttempt) {
            commit("SET_ACTIVE_VEHICLES", getAttempt.data)
            dispatch("applyVehicleFilter")
            return true
        }
        return false
    },

    setActiveVehiclesSearchString: ({ commit }, payload) => {
        commit("SET_ACTIVE_VEHICLES_SEARCH_STRING", payload)
    },

    updateVehicles: async ({ commit }, payload) => {
        await ApiManager.post(`/api/dashboard/vehicles/bulk-update`, payload)
        return true
    },
    fetchCompanies: async ({ commit }, payload) => {
        const getAttempt = await ApiManager.get('/api/companies')

        if (getAttempt) {
            commit("SET_COMPANIES", getAttempt.data)
            return true
        }

        return false
    },

    fetchServiceViews: async ({ commit }) => {
        const result = await ApiManager.get('/api/dashboard/service-views')

        if (result) {
            commit("SET_SERVICE_VIEWS", result.data.content)
            return true
        }

        return false
    },

    fetchTeamMembers: async ({ commit }) => {
        const result = await ApiManager.get('/api/team-members')

        if (result) {
            commit("SET_TEAM_MEMBERS", result.data.content)
            return true
        }

        return false
    },
    
    fetchTeamMember: async ({ commit }, id) => {
        const result = await ApiManager.get(`/api/team-members/${id}`)

        if (result) {
            return result.data.content
        }

        return false
    },
    
    createTeamMember: async ({ commit }, payload) => {
        const response = await ApiManager.post('/api/team-members', payload)
        return response
    },
    
    updateTeamMember: async ({ commit }, payload) => {
        const response = await ApiManager.post(`/api/team-members/${payload.id}/update`, payload)
        return response
    },

    deleteTeamMember: async ({ commit }, payload) => {
        const response = await ApiManager.post(`/api/team-members/${payload.id}/destroy`, payload)
        return response
    },

    fetchRoles: async ({ commit }) => {
        const result = await ApiManager.get('/api/roles')

        if (result) {
            commit("SET_ROLES", result.data.content)
            return true
        }

        return false
    },

}

const mutations = {
    RESET_FORM_DATA: (state, formData) => {
        state.registrationForm = formData
    },
    SET_FORM_DATA: (state, formData) => {
        state.registrationForm = {
            ...state.registrationForm,
            ...formData
        }
    },
    SET_ERRORS: (state, errors) => {
        Object.entries(errors).map(error => {
            const [key, value]  = error
            const keyPieces     = key.split('.')

            if(keyPieces[1] && !isNaN(Number(keyPieces[1]))) {
                if(!errors[keyPieces[0]]) {
                    errors[keyPieces[0]] = []
                }
                errors[keyPieces[0]].push(String(value).replace(key, keyPieces[0]))
            }
        })
        state.errors = errors
    },
    RESET_FIELD_ERROR: (state, field) => {
        let [key] = Object.keys(field)
        if(Object.keys(state.errors).includes(key))
            delete state.errors[key]
    },
    SET_ACTIVE_SERVICES: (state, services) => {
        state.activeServices = services
    },
    SET_SELECTED_SERVICES: (state, servicesIds) => {
        state.selectedServices = servicesIds
    },
    SET_ACTIVE_SERVICES_SEARCH_STRING: (state, searchString) => {
        state.activeServicesSearchString = searchString
    },
    SET_FILTERED_SERVICES: (state, services) => {
        state.fileredServices = services
    },
    SET_SELECTED_VEHICLES: (state, vehicleIds) => {
        state.selectedVehicles = vehicleIds
    },
    SET_ACTIVE_VEHICLES_SEARCH_STRING: (state, searchString) => {
        state.activeVehiclesSearchString = searchString
    },
    SET_ACTIVE_VEHICLES: (state, vehicles) => {
        state.activeVehicles = vehicles
    },
    SET_FILTERED_VEHICLES: (state, vehicles) => {
        state.filteredVehicles = vehicles
    },
    RESET_STATE (state) {
        Object.assign(state, getDefaultState())
    },
    SET_COMPANIES: (state, companies) => {
        state.companies = companies
    }, 
    SET_SERVICE_VIEWS: (state, views) => {
        state.serviceViews = views
    },
    SET_TEAM_MEMBERS: (state, teamMembers) => {
        state.teamMembers = teamMembers
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}