import ApiManager from "@/helpers/ApiManager"

function paramsObjectToString(paramsObject) {
    let paramsArr = []
    for (let key in paramsObject) {
        if (paramsObject[key]) {
            paramsArr.push(key + "=" + paramsObject[key])
        }
    }

    return paramsArr.join("&");
}

const state = {
    revenue: {},
    serviceViews: [],
}

const getters = {
    getRevenue: state => state.revenue ?? {},
    getServiceViews: state => state.serviceViews ?? [],
}

const actions = {
    fetchRevenue: async ({ commit }, payload) => {
        const paramsString = paramsObjectToString({ ...payload })
        const getAttempt = await ApiManager.get(`api/super/dash/revenue?${paramsString}`)

        if (getAttempt) {
            commit('SET_REVENUE', getAttempt.data.content)
            return true
        }

        return false
    },
    fetchServiceViews: async ({ commit }, payload) => {
        const paramsString = paramsObjectToString({ ...payload })
        const getAttempt = await ApiManager.get(`api/super/dash/service-views?${paramsString}`)

        if (getAttempt) {
            commit('SET_SERVICE_VIEWS', getAttempt.data.content)
            return true
        }

        return false
    },
}

const mutations = {
    SET_REVENUE: (state, payload) => {
        state.revenue = payload
    },
    SET_SERVICE_VIEWS: (state, payload) => {
        state.serviceViews = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}