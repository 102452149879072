import { computed, ref, onMounted, onBeforeUnmount } from 'vue'

export function useScreenSizeBehaviours() {
    const isMobileSize = ref(window.innerWidth < 768)

    const resizeViewPort = () => {
        isMobileSize.value = window.innerWidth < 768
    }

    onMounted(() => {
        window.addEventListener('resize', resizeViewPort);
    });
    
    onBeforeUnmount(() => {
        window.removeEventListener('resize', resizeViewPort);
    });
    return {isMobileSize}
}