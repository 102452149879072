<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'

import { useToast } from 'vue-toast-notification'
import ApiManager from '@/helpers/ApiManager'

import SvgComponent from '../SvgComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

const store = useStore()
const toast = useToast()

const text = ref(null)
const results = ref(null)
const inputSearchRef = ref(null)

const getAvatar = avatar => avatar 
    ? `${process.env.VUE_APP_API_URL}/storage/avatar/${avatar}`
    : require('@/assets/images/user_fallback_avatar.png')

async function search() {
    if (! text.value) {
        results.value = null
        return false
    }

    results.value = null

    const { data } = await ApiManager.post('api/super/users/search', {
        search: text.value
    })
    
    if (data.content.length === 0) {
        toast.error('There are no results for the search', { position: 'top-right' })
        return false
    }
    
    results.value = data.content
}

const selectResult = result => {
    store.dispatch('superAdm/addTab', result)

    text.value = null
    results.value = null
}

const handleClickOutside = event => {
    const searchElement = inputSearchRef.value

    if (searchElement && !searchElement.contains(event.target)) {
        results.value = null
    }
}

onMounted(() => {
    document.addEventListener('mousedown', handleClickOutside)
})
</script>

<template>
    <div ref="inputSearchRef">
        <div class="search-input-wrapper">
            <input placeholder="SEARCH HERE" v-model="text" @keyup.enter="search()" @blur="clear" />
    
            <ButtonComponent @click="search()" class="btn-search" color="transparent" simple :size="{ width: '20px', height: '21px' }">
                <SvgComponent name="IconMagnifying" />
            </ButtonComponent>
        </div>
        <div v-if="results" class="display-results">
            <div v-for="result in results"  :key="result.id" class="result-item" @click="selectResult(result)">
                <img :src="getAvatar(result?.avatar[0]?.file_name || '')" class="result-image" width="40" />
                <div class="results-info">
                    <p>{{ `${result.user_type === 'SUPPLIER' ? result.company_name : result.name}` }}</p>
                    <p class="light">ID: {{ result.id }}</p>
                </div>
                <p class="light">{{ result.user_type }}</p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.search-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    input {
        width: 100%;
        height: 47px;
        border: none;
        background-color: #F3F4F7;
        border-radius: 12px;
        padding: 16px;
        color: #162659;
        font-family: Montserrat;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        outline: none;
    }

    .btn-search {
        position: absolute;
        right: 16px;
    }

    &.is-open{
        z-index: 6;
    }
}

.display-results {
    position: absolute;
    top: 220px;
    width: calc(100% - 67px);
    padding: 5px;
    font-family: Montserrat;
    font-size: 11px;
    background-color: #FFFFFF;
    z-index: 99999999;
    border: 1px solid #F3F4F7;
    border-radius: 10px;
}

.result-item {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

.result-item:not(:last-child) {
    border-bottom: 1px solid #F3F4F7;
}

.result-item:hover {
    background-color: #F3F4F7;
}

.result-image {
    border-radius: 6px;
    margin-right: 5px;
}

.results-info {
    width: 60%;
}

.light {
    color: #8A92AC;
}
</style>