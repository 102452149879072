<script setup>
// import libraries
import { computed, onMounted, reactive, ref, watch } from 'vue'
import router from '@/router'
// custom imports
import Button from '@/components/ButtonComponent.vue';
import SvgComponent from '@/components/SvgComponent.vue';
import { useLoginForm } from '@/composables/useLoginForm';
import EmailInputComponent from '@/components/form/EmailInputComponent.vue';
import PasswordInputComponent from '@/components/form/PasswordInputComponent.vue';
import { useScreenSizeBehaviours } from '@/composables/useScreenSizeBehaviours';


// (variables)
const {
    form,
    login,
    errors
} = useLoginForm()

const { isMobileSize } = useScreenSizeBehaviours()

const customStyles = computed(() => {
    if (!isMobileSize.value) {
        return {
            'max-width': '400px',
            'overflow': 'hidden',
            'padding-top': '20px'
        }
    }
    return {}
})


// Methods
function loginAttempt() {
    console.log("login goes here", form.value);
}

function openLoginWithGoogleModal() {
    alert("login with google modal goes here");
}

function openLoginWithAppleModal() {
    alert("login with apple modal goes here");
}

function openLoginWithFacebookModal() {
    alert("login with facebook modal goes here");
}

function onClickBecomeAPartner() {
    router.push({name:"business"})
}

</script>

<template>
    <div class="login-container">
        <div class="login" :style="customStyles">
            <SvgComponent class="logo" name="SeeLogoDesktop" v-if="!isMobileSize" />
            <div class="login-titles">
                <h1>Login</h1>
                <p>CHOOSE HOW TO AUTHENTICATE</p>
            </div>

            <div class="grid">
                <Button simple icon bordered @click="openLoginWithGoogleModal()">
                    <SvgComponent name="IconGoogle" />
                </Button>
                <Button simple icon bordered @click="openLoginWithAppleModal()">
                    <SvgComponent name="IconApple" />
                </Button>
                <Button simple icon bordered @click="openLoginWithFacebookModal()">
                    <SvgComponent name="IconFacebook" />
                </Button>
            </div>

            <div class="login-form-wrap">
                <!-- <input type="text" v-model="form.email" placeholder="Email adress">
            <input type="password" v-model="form.password" placeholder="Password"> -->
                <EmailInputComponent class="login-input" v-model="form.email" :errors="errors['email']"
                    placeholder="Email address" @keypress.enter="login" />
                <PasswordInputComponent class="login-input" v-model="form.password" :errors="errors['password']"
                    placeholder="Password" @keypress.enter="login" />
                <div v-if="errors.message" class="error-resume">
                    <p class="error-resume-title">
                        ERROR:
                    </p>
                    <p class="error-resume-text">
                        {{ errors.message }}
                    </p>
                </div>
                <Button class="login-button" color="primary" full @click="login">CONTINUE WITH EMAIL</Button>
            </div>

            <p class="instructions">Don't have an account?<br><a @click="onClickBecomeAPartner()">Become a partner here</a></p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.login-container {
    background-image: url('@/assets/images/bg.jpeg');
    background-position: center;
    background-size: cover;
    height: 100vh;
}

button {
    margin: 5px 0;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 13px;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-buttons {
    display: flex;
    justify-content: space-between;
    padding: 0 13px;
}

.login {
    background: #fff;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: scroll;
    padding: 99px 41px 0 41px;
    box-sizing: border-box;
}

h1 {
    color: var(--secondary-text-color);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

p {
    color: var(--secondary-text-color);
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.login-titles {
    margin-bottom: 60px;
}

.login-input {
    margin: 0;
}

.login-input::v-deep input {
    display: block;
    width: 100%;
    padding: 0 16px;
    height: 47px;
    line-height: 47px;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid var(--primary-icon-color-50);

    color: var(--secondary-text-color);
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--secondary-text-color);
        opacity: 0.5;
    }

    &+.invalid-feedback:before {
        top: 16px;
    }
}

.login-input::v-deep input[type="email"] {
    margin-top: 12px;
}

.login-input::v-deep input[type="password"] {
    margin-top: 17px;
}

.login-button {
    margin-top: 17px;
}

.instructions {
    text-align: center;
    line-height: 1.6;

    a {
        color: var(--primary-text-color);
    }
}

.error-resume {
    margin-top: 17px;
}

.logo {
    display: flex;
    justify-content: center;
    margin: 50px auto 90px;
}

</style>