<script setup>
import { inject, ref, reactive } from 'vue'
import { Cropper } from 'vue-advanced-cropper'
import CardComponent from '@/components/CardComponent.vue'
import UserButtonComponent from '@/components/UserButtonComponent.vue'
import { useLoginForm } from '@/composables/useLoginForm'
import ButtonComponent from '@/components/ButtonComponent.vue'
import SvgComponent from '@/components/SvgComponent.vue'
import DropDown from '@/components/DropDown.vue'
import ModalComponent2 from '@/components/ModalComponent2.vue'
import router from '@/router'
import { useStore } from 'vuex'

const auth = inject('auth')
const store = useStore()
const { logout } = useLoginForm()

const cropCover = ref(false)
const cropAvatar = ref(false)
const imageToCrop = ref(null)
const avatarInput = ref(null)
const coverInput = ref(null)
const coverCropper = ref(null)
const avatarCropper = ref(null)

const profileOptions = reactive({
    "avatar": "EDIT PROFILE PHOTO",
    "cover": "EDIT COVER PHOTO",
})

const onClickProfileOptions = function (option) {
    if (option === 'avatar') {
        avatarInput.value.value = ''
        avatarInput.value.click()
    } else {
        coverInput.value.value = ''
        coverInput.value.click()
    }
}

const handleAvatarFileChange = (event) => {
    const selectedFile = event.target.files[0]

    if (selectedFile && selectedFile.type.startsWith('image/')) {
        const reader = new FileReader()

        reader.onload = () => {
            imageToCrop.value = reader.result
            cropAvatar.value = true
        }
        reader.readAsDataURL(selectedFile)
    } else {
        imageToCrop.value = null
    }
}

const handleCoverFileChange = (event) => {
    const selectedFile = event.target.files[0]

    if (selectedFile && selectedFile.type.startsWith('image/')) {
        const reader = new FileReader()

        reader.onload = () => {
            imageToCrop.value = reader.result
            cropCover.value = true
        }
        reader.readAsDataURL(selectedFile)
    } else {
        imageToCrop.value = null
    }
}

const cropCoverImage = () => {
    const { canvas } = coverCropper.value.getResult()
    if (canvas) {
        const form = new FormData()
        canvas.toBlob(blob => {
            form.append('cover', blob)

            store.dispatch('user/updateCover', form).then(() => {
                cropCover.value = false
            })
        }, 'image/jpeg')
    }
}

const cropAvatarImage = () => {
    const { canvas } = avatarCropper.value.getResult()
    if (canvas) {
        const form = new FormData()
        canvas.toBlob(blob => {
            form.append('avatar', blob)

            store.dispatch('user/updateAvatar', form).then(() => {
                cropAvatar.value = false
            })
        }, 'image/jpeg')
    }
}

const cancelCrop = () => {
    cropAvatar.value = false
    cropCover.value = false
}

function performLogout() {
    router.push({name: "home"}).then(() => {
        logout()
    })
}
</script>

<template>
    <div v-if="auth">
        <header>
            <CardComponent>
                <figure>
                    <img v-if="auth.cover_image" :src="auth.cover_image" />
                    <img v-else src="@/assets/images/bg.jpeg" />
                </figure>
                <div class="d-flex space-between header-content-wrapper">
                    <div class="w-100">
                        <div class="d-flex align-items-center header-info">
                            <UserButtonComponent :user="auth" />
                            <div class="user-info" v-if="auth">
                                <div>
                                    <span class="user-type">{{ auth.first_name }} {{ auth.last_name }} | {{ auth.user_type
                                    }}</span>
                                    <DropDown class="btn-profile-options visible-mobile-flex">
                                        <template v-slot:header>
                                            <ButtonComponent class="btn-dots d-flex" color="transparent" simple :size="{ height: '27px' }">
                                                <SvgComponent name="IconEllipsis" />
                                            </ButtonComponent>
                                        </template>
                                        <template v-slot:body>
                                            <ul class="profile-options">
                                                <li v-for="(profileOption, optionKey) in profileOptions" :key="optionKey"
                                                    @click="onClickProfileOptions(optionKey)"
                                                    :class="{ active: optionKey === selectedOrder }" class="profile-option">
                                                    {{ profileOption }}
                                                </li>
                                            </ul>
                                        </template>
                                    </DropDown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DropDown class="btn-profile-options visible-desktop-flex">
                        <template v-slot:header>
                            <ButtonComponent class="btn-dots d-flex" color="transparent" simple :size="{ height: '27px' }">
                                <SvgComponent name="IconEllipsis" />
                            </ButtonComponent>
                        </template>
                        <template v-slot:body>
                            <ul class="profile-options">
                                <li v-for="(profileOption, optionKey) in profileOptions" :key="optionKey"
                                    @click="onClickProfileOptions(optionKey)"
                                    :class="{ active: optionKey === selectedOrder }" class="profile-option">
                                    {{ profileOption }}
                                </li>
                            </ul>
                        </template>
                    </DropDown>
                </div>
            </CardComponent>
        </header>

        <main>
            <nav class="main-tabs visible-desktop-flex">
                <CardComponent>
                    <ul>
                        <li><router-link to="/affiliates/profile">About me</router-link></li>
                        <li><router-link to="/affiliates/profile/settings">Settings</router-link></li>
                        <li><a href="#" @click="performLogout">Logout</a></li>
                    </ul>
                </CardComponent>
            </nav>
            <div class="main-content">
                <div class="row d-flex direction-column">
                    <div class="d-flex tabs-wrapper visible-mobile-flex">
                        <router-link to="/affiliates/profile" custom v-slot="{ isExactActive, navigate }">
                            <ButtonComponent :color="isExactActive ? 'light' : 'light-primary'" @click="navigate"
                                :class="{ active: isExactActive }">About me</ButtonComponent>
                        </router-link>
                        <!-- <router-link to="/affiliates/profile/team" custom v-slot="{ isExactActive, navigate }">
                            <ButtonComponent :color="isExactActive ? 'light' : 'light-primary'" @click="navigate"
                                :class="{ active: isExactActive }">Team</ButtonComponent>
                        </router-link> -->
                        <router-link to="/affiliates/profile/reviews" custom v-slot="{ isExactActive, navigate }">
                            <ButtonComponent :color="isExactActive ? 'light' : 'light-primary'" @click="navigate"
                                :class="{ active: isExactActive }">Reviews</ButtonComponent>
                        </router-link>
                        <router-link to="/affiliates/profile/settings" custom v-slot="{ isExactActive, navigate }">
                            <ButtonComponent :color="isExactActive ? 'light' : 'light-primary'" @click="navigate"
                                :class="{ active: isExactActive }">Settings</ButtonComponent>
                        </router-link>
                    </div>
                </div>
                <CardComponent>
                    <router-view>
                    </router-view>
                </CardComponent>
            </div>
        </main>

        <input type="file" ref="avatarInput" class="hidden" @change="handleAvatarFileChange" />
        <input type="file" ref="coverInput" class="hidden" @change="handleCoverFileChange" />
        <ModalComponent2 id="imageCrop" :showModal="cropCover" @close="cancelCrop">
            <h1 class="modal-title">Edit Cover Photo</h1>
            <Cropper ref="coverCropper" class="cropper" :src="imageToCrop" :stencil-props="{
                aspectRatio: 449 / 111
            }" @change="changeImage" />
            <footer>
                <ButtonComponent rounded color="default" @click="cancelCrop">Cancel</ButtonComponent>
                <ButtonComponent rounded color="primary" @click="cropCoverImage">Save</ButtonComponent>
            </footer>
        </ModalComponent2>

        <ModalComponent2 id="avatarCrop" :showModal="cropAvatar" @close="cancelCrop">
            <h1 class="modal-title">Edit Profile Photo</h1>
            <Cropper ref="avatarCropper" class="cropper" :src="imageToCrop" :stencil-props="{
                aspectRatio: 1
            }" @change="changeImage" />
            <footer>
                <ButtonComponent rounded color="default" @click="cancelCrop">Cancel</ButtonComponent>
                <ButtonComponent rounded color="primary" @click="cropAvatarImage">Save</ButtonComponent>
            </footer>
        </ModalComponent2>
    </div>
</template>

<style lang="scss" scoped>
header {
    margin-bottom: 26px;

    .cardcomponent-wrapper {
        padding: 23px 7px 7px;

        @media (max-width: 767px) {
            padding: 13px 9px 9px;

            ::v-deep {
                main {
                    padding: 0;
                }
            }
        }

        figure {
            height: 261.76px;
            margin: 0;
            border-radius: 12px;
            overflow: hidden;

            @media (max-width: 767px) {
                height: 118px;
                border-radius: 6px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .header-content-wrapper {
        margin: 0 10px 0 21px;
        padding-top: 10px;
        gap: 9px;

        @media (max-width: 767px) {
            margin: 0 0 0 10px
        }

        .header-info {
            gap: 27px;
            width: 100%;

            @media (max-width: 767px) {
                gap: 10px;
            }

            .user-button {
                width: 91px;
                height: 91px;
                min-width: 91px;
                min-height: 91px;
                border-radius: 50%;
                margin-top: -36px;

                @media (max-width: 767px) {
                    min-width: 53px;
                    min-height: 47px;
                    width: 53px;
                    height: 47px;
                }
            }

            .user-info {
                display: flex;
                flex-direction: column;
                width: 100%;

                &>div {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                }

                .user-name {
                    color: var(--primary-color);
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                    @media (max-width: 767px) {
                        font-size: 11px;
                    }
                }

                .user-type {
                    text-transform: uppercase;
                    color: var(--secondary-text-color);
                    font-family: Montserrat;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-top: 6px;

                    @media (max-width: 767px) {
                        font-size: 11px;
                    }
                }
            }

            .user-email {
                margin-left: 27px;
                color: var(--secondary-text-color);
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                @media (max-width: 767px) {
                    font-size: 11px;
                }
            }

        }
    }
}

main {
    display: flex;
    gap: 20px;
    align-items: flex-start;

    .main-tabs {
        flex: 1;
        min-width: 311px;

        ul {
            list-style: none;
            padding: 0 26px;
            margin-bottom: -16px;

            li {
                display: flex;
                width: 100%;
                height: 52.25px;

                &:not(:last-child) {
                    border-bottom: 1.5px solid #DCF2FD;
                }

                a {
                    text-decoration: none;
                    color: #162659;
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                }

                .router-link-exact-active {
                    color: #25AAE5;
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
        }
    }

    .main-content {
        flex: 3;
    }
}

.btn-profile-options {
    align-self: flex-start;
    padding-top: 8px;
}

.profile-options {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.20);
    border-radius: 12px;
    margin: 0;
    list-style: none;
    padding: 5px 0;
    width: 165.385px;
    height: 87.536px;
    flex-shrink: 0;

    .profile-option {
        padding: 12px 17px;
        cursor: pointer;
        margin: 0;
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &:first-child {
            border-bottom: 1px solid var(--light-secondary-color);
        }
    }
}

.tabs-wrapper {
    margin-bottom: -10px;
    gap: 2.1px;

    button {
        min-width: 102px;
        position: relative;
        padding-bottom: 25.5px;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: none;
        color: var(--primary-icon-color-50);
        padding-left: 0;
        padding-right: 0;
        

        border-radius: 0;

        &:first-child {
            border-radius: 12px 0 0 0;
            box-shadow: -3px -3px 6px -3px rgba(0, 0, 0, 0.20);
        }

        &:last-child {
            border-radius: 0 12px 0 0;
        }

        &:not(.active):hover {
            background-color: var(--light-secondary-color);
        }

        &.active {
            z-index: 1;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: var(--primary-color);
        }
    }
}</style>