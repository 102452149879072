<script setup>
// import libraries
import { computed } from 'vue';
import RevenueRequestComponent from './RevenueRequestComponent.vue';
import ButtonComponent from './ButtonComponent.vue';
import CardComponent from './CardComponent.vue';
import SvgComponent from './SvgComponent.vue';
// custom imports

// consts (props, otherConstants)
const emit = defineEmits(['export-xlsx'])
const props = defineProps({
    title: String,
    revenues: Array
})


function clickDownloadButton() {
    emit('export-xlsx')
}
</script>

<template>
   <CardComponent class="revenue-component" :title="title">
        <template v-slot:header>
            <header class="d-flex direction-column align-items-start space-between">
                <div class="w-100 d-flex space-between">
                    <div class="d-flex direction-column">
                        <h1 class="card-title">{{ title }}</h1>
                    </div>
                    <ButtonComponent class="btn-dots visible-mobile-block" color="transparent" simple :size="{ width: '27px', height: '27px' }" v-if="false">
                        <SvgComponent name="IconEllipsis" />
                    </ButtonComponent>
                </div>
                <div class="w-100 space-between options visible-desktop-flex">
                    <div class="btn-options d-flex">
                        <!-- <ButtonComponent class="btn-dropdown d-flex align-items-center" bordered color="secondary">
                            <SvgComponent name="IconFilter" />
                            <span>FILTER</span>
                            <SvgComponent name="IconArrowDown" />
                        </ButtonComponent>
                        <ButtonComponent class="btn-dropdown d-flex align-items-center" bordered color="secondary">
                            <span>order</span>
                            <SvgComponent name="IconArrowDown" />
                        </ButtonComponent> -->
                    </div>
                </div>
                <div class="w-100 d-flex space-between">
                    <span class="quantity">Showing: {{ revenues.length }} results</span>
                    <a @click="clickDownloadButton()" class="download-button">Download XLS file</a>
                </div>
            </header>
        </template>
        <RevenueRequestComponent type="new" v-for="revenue, index in revenues" :key="index" :revenue="revenue" />
    </CardComponent>
</template>

<style lang="scss" scoped>
.revenue-component {
    @media (max-width:767px) {
        padding-bottom:21px;
    }
    header {
        padding: 22px 17px 12px;

        @media (max-width:767px) {
            min-height:129px;
        }
        
        h1.card-title {
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
        }
        p {
            color: var(--secodnary-text-color);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 5px 0 0 0;
        }
        .btn-options{
            gap: 15px;
            align-items: center;

            button {
                min-width: 115px;
                padding: 15.5px;
            }
            .btn-dropdown {
                border-color: var(--primary-icon-color-30);
                max-height: 47px;

                span {
                    margin-left: 15.47px;
                    margin-right: 11.12px;
                }
            }
            .btn-decline {
                border-color: var(--tertiary-color);
                color: var(--tertiary-color);
            }
        }

        .checkbox {
            display: flex;
            gap: 11.16px;
            margin-top: 14.34px;
            color: var(--secodnary-text-color);
            font-family: Montserrat;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            align-items: center;
        }
        .quantity {
            color: var(--secodnary-text-color);
            font-family: Montserrat;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 1px;

            @media screen and (max-width: 767px) {
                line-height: normal;
            }
        }

        .options {
            
            @media (min-width: 768px) {
                justify-content: flex-end;
                margin-bottom:15px;
            }
            @media screen and (max-width: 767px) {
            margin-top: 62px;
            .btn-options {
                    .btn-dropdown {
                        display: none;
                    }

                    button {
                        min-width: 84.205px;
                    }
                }
            }
        }

        .btn-dots {
            margin-top: -20px;

            @media screen and (min-width: 768px) {
                margin-top: 0;
            }
        }
    }
}

.download-button{
    color: var(--primary-color);
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor:pointer;
}
</style>