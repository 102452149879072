<script setup>
    // import libraries
    import { ref } from 'vue'

    // consts (props, otherConstants)
    const props = defineProps({
        defaultOpen: {type:Boolean, default:false},
        light: {type:Boolean, default:false},
    })
    
    // reactiveVariables
    let isOpen = ref(props.defaultOpen)

    // Methods
    function onClickHeader() {
        isOpen.value = !isOpen.value
    }
</script>

<template>
    <div class="accordeon-wrap" :class="{active: isOpen, light:light}">
        <div class="accordeon-header" @click="onClickHeader()">
            <slot name="header"></slot>
        </div>
        <div class="accordeon-body">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.accordeon-header{
    color: var(--secondary-text-color);
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding:8px 20px 8px 0;
    border-bottom:1px solid var(--secondary-text-color);
    position: relative;
    cursor:pointer;

    &:after{
        content:"";
        width: 0;
        height: 0;
        border-style: solid;
        // border-width: 0 4.85px 4.9px 4.85px;
        border-width: 4.9px 4.85px 0 4.85px;
        border-color: var(--secondary-text-color) transparent transparent transparent;
        // border-color: transparent transparent var(--secondary-text-color) transparent;
        position: absolute;
        top:50%;
        right:0;
        transform:translateY(-50%) rotate(0deg);
    }
}

.accordeon-body{
    overflow:hidden;
    max-height:0;
    transition:all 0.35s ease;
    color: var(--secondary-text-color);
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.accordeon-wrap{
    margin-bottom:20px;

    &.active{
        .accordeon-header{
            &:after{
                transform:translateY(-50%) rotate(180deg);
            }
        }

        .accordeon-body{
            max-height: 9999px;
        }
    }
}
.accordeon-wrap.light{
    .accordeon-header{
        border-bottom: 1px solid var(--light-color);
    }
    .accordeon-header:after{
        border-color: var(--light-color) transparent transparent transparent;
    }

}
</style>