<script setup>
// import libraries
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'
import CardComponent from '@/components/CardComponent.vue'
import Accordeon from '@/components/Accordeon.vue'

// (variables)
const store = useStore()

// ComputedVariables
const isAuthenticated = computed(() => store.getters['user/getIsLoggedIn'])


// Methods
function onClickStartEarning() {
    console.log("onClickStartEarning")
}

// Callbacks Lifecycle
onMounted(() => {
    if (!isAuthenticated.value) {
        router.push({
            "name": "login"
        })
    }
})
</script>

<template>
    <div class="salutation">
        <h1>Be a Promoter</h1>
        <p>Become a promoter yourself</p>
    </div>
    <CardComponent class="plans" title="CHOOSE YOUR PLAN">
        <div class="subtitle">
            <p>Subscribe and get more views</p>
        </div>
        
        <div class="intro">
            <p>Here you can choose the best way to boost your views.</p>
            <p>Each plan opens different possibilities, click on the one you wish to add.</p>
        </div>

        <div class="plan plan-desktop-reverse">
            <div class="img-wrap">
                <img alt="Basic" src="@/assets/images/plan_basic.png">
            </div>
            <div class="plan-description-wrap">
                <p>With the Basic Plan, you’ll get X, Y, Z.</p>
                <p>It’s the easiest way boost your services, for just (xyz).</p>
            </div>
        </div>
        <div class="plan">
            <div class="img-wrap">
                <img alt="Most popular" src="@/assets/images/plan_most_popular.png">
            </div>
            <div class="plan-description-wrap">
                <p>7 out of 10 partners boost their views with this plan.</p>
                <p>The Most Popular Plan gives you X, Y Z.</p>
            </div>
        </div>
        <div class="plan plan-desktop-reverse">
            <div class="img-wrap">
                <img alt="Accelerate" src="@/assets/images/plan_accelerate.png">
            </div>
            <div class="plan-description-wrap">
                <p>Want to accelerate your growth? This is the right plan!</p>
                <p>It allows you to X, Y, Z, for a period of (xyz), with just (xyz).</p>
            </div>
        </div>
    </CardComponent>

    <CardComponent class="plans" title="BE A PROMOTER">
        <div class="subtitle">
            <p>Get discount codes and start earning</p>
        </div>

        <div class="visible-mobile-block">
            <!-- Careful editing this copy : it repeats itself in desktop mode -->
            <p>If you want a passive income, <strong>monetize your audience</strong>! Fast, easy and safe.<br>Become our promoter and <strong>earn commissions on all experiences</strong>.</p>
            <p>You too can be a <span translate="no"> SEE Countries </span>’s promoter. <strong>It’s as easy as a few clicks</strong>.</p>
            <p>With our easy-to-use platform, you just need a registration to start earning.<br>We only need your name, contact, TAX number and IBAN.</p>
        </div>
        <div class="plan plan-desktop-reverse">
            <div class="img-wrap">
                <img alt="Be a promoter" src="@/assets/images/be_a_promoter.png">
            </div>
            <div class="plan-description-wrap">
                <div class="visible-desktop-block w-100">
                    <!-- Careful editing this copy : it repeats itself in mobile mode -->
                    <p>If you want a passive income, <strong>monetize your audience</strong>! Fast, easy and safe.<br>Become our promoter and <strong>earn commissions on all experiences</strong>.</p>
                    <p>You too can be a <span translate="no"> SEE Countries </span>’s promoter. <strong>It’s as easy as a few clicks</strong>.</p>
                    <p>With our easy-to-use platform, you just need a registration to start earning.<br>We only need your name, contact, TAX number and IBAN.</p>
                </div>
                <div class="w-100 plan-description-section-2">
                    <p>You’ll be eligible to generate discount codes for the services you wish to promote, immediately.</p>
                    <p>Spread the word and give those discount codes throughout your audience.</p>
                    <p>As they book their services, you’ll be cashing in on every one of them!</p>
                </div>

                <Accordeon :defaultOpen="true">
                    <template v-slot:header>
                        Having doubts? <strong>Check our FAQS</strong> here.
                    </template>

                    <template v-slot:body>
                        <p>
                            Q - <strong>Can I become a promoter?</strong> <br>
                            A - Everyone can! You don’t need any special qualifications or skills.
                        </p>

                        <p>
                            Q - <strong>I’m not used to work with high-tech platforms. Now what?</strong> <br>
                            A – It’s OK. We use tools that require no specific software knowledge.
                        </p>

                        <p>
                            Q - <strong>How much can I earn as promoter?</strong> <br>
                            A – You earn a commission of 5% on any bookings.
                        </p>

                        <p>
                            Q - <strong>How do I get paid?</strong> <br>
                            A – On a monthly basis, by bank transfer.
                        </p>

                        <p>
                            Q - <strong>What services can I promote?</strong> <br>
                            A - All on Experiences, Wellness and Transfers.
                        </p>

                        <p>
                            Q - <strong>Are there any services I can’t promote?</strong> <br>
                            A - You can’t promote your own services.
                        </p>

                        <p>
                            Q - <strong>When can I start earning?</strong> <br>
                            A – Right now, by clicking <a @click="onClickStartEarning()">here</a>
                        </p>
                    </template>
                </Accordeon>
            </div>
        </div>
    </CardComponent>

    
</template>

<style lang="scss" scoped>
p{
    color: var(--secondary-text-color);
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;


    strong{
        font-weight:600;
    }
    a{
        font-weight: 800;
    }
}
.intro{
    @media (min-width:768px) {
        padding:0 46px;
    }
    @media (max-width:767px) {
        display:block;
        padding-bottom:13px;
    }
}
.subtitle {
    margin-top:-14px;
    margin-bottom:30px;

    @media (max-width:767px) {
        margin-bottom:49px;
        margin-top:-19px;
    }
    p{
        margin:0;
    }
}
.plan{
    width:100%;
    @media (min-width:768px) {
        display:flex;
        // justify-content: space-between;
        padding:0 46px 52px 46px;
    }
    @media (max-width:767px) {
        padding-bottom: 52px;
        
        &:last-child{
            padding-bottom:0;
        }
    }
    .img-wrap{
        @media (min-width:768px) {
            width:35%;
        }
    }
    img{
        width:100%;
        height:auto;

        

        @media (max-width:767px) {
            display:block;
            margin:0 auto 15px auto;
        }
    }

    .plan-description-wrap{

        @media (min-width:768px) {

            margin-top:45px;
            margin-left:15%;
            width:50%;
        }
    }

    &.plan-desktop-reverse {
        @media (min-width:768px) {
            flex-direction: row-reverse;
    
            .plan-description-wrap{
                margin-left:0;
                margin-right:15%;
            }
        }
    }

}
.plans{
    @media (min-width:768px) {
        margin-bottom:62px;
    }
    @media (max-width:767px) {
        margin-bottom:34px;
    }

    ::v-deep .plans{
        padding-bottom:43px;
    }
}
.plan-description-section-2{
    padding-bottom:14px;
}
</style>