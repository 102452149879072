<script setup>
import { useScreenSizeBehaviours } from '@/composables/useScreenSizeBehaviours';
import { computed } from 'vue';

const emit = defineEmits(['click'])

const { isMobileSize } = useScreenSizeBehaviours()

const props = defineProps({
    // imageMobile: {type: String, default: require('@/assets/images/mobile_ad.svg')},
    // imageDesktop: {type: String, default: require('@/assets/images/desktop_ad.svg')},
    imageMobile: {type: String, default: require('@/assets/images/banner-partner-mobile.webp')},
    imageDesktop: {type: String, default: require('@/assets/images/banner-partner.webp')},
})

const image = computed(() => isMobileSize.value ? props.imageMobile : props.imageDesktop)

function onClick() {
    emit('click')
}
</script>

<template>
    <div class="ad-container" @click="onClick">
        <img :src="image" />
    </div>
</template>

<style lang="scss" scoped>
.ad-container {
    cursor: pointer;
    width: 100%;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.20));
    border-radius: 12px;
    overflow: hidden;

    img {
        object-fit: cover;
        width: 100%;
    }
}
</style>