<script setup>
import { reactive, computed } from 'vue';
import VueApexCharts from "vue3-apexcharts";
import ToggleCardComponent from '../ToggleCardComponent.vue';
import { useScreenSizeBehaviours } from '@/composables/useScreenSizeBehaviours';

const { isMobileSize } = useScreenSizeBehaviours()

const props = defineProps({
    views: Array
})

const getData = computed(() => {
    const views = []
    for (let view of props.views) {
        views.push([new Date(view.date).getTime(), view.count])
    }

    return views
})

const data = reactive({
    series: [{
        name: 'Views',
        data: getData,
    }],
    chartOptions: {
        fill: {
            type: 'solid',
        },
        stroke: {
            colors: ['#0B55BD']
        },
        chart: {
            type: 'area',
            height: 150,
            toolbar: {
                show: false
            },
            zoom: {
                autoScaleYaxis: true
            },
        },
        colors: ['#25AAE5', '#0B55BD'],
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
            style: 'hollow',
        },
        xaxis: {
            show: false,
            type: 'datetime',
            // labels: {
            //     format: 'MMM',
            // },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            show: true,
            axisBorder: {
                show: false,
            },
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy'
            },
        },
        grid: {
            show: false,
        },
        responsive: [{
            breakpoint: 1024,
            options: {
                chart: {
                    width: '100%',
                }
            }
        }],
    }
})
</script>

<template>
    <ToggleCardComponent title="NUMBER OF VIEWS" :toggable="isMobileSize">
        <div id="viewsChart">
            <VueApexCharts id="chart" type="line" :options="data.chartOptions" :series="data.series"></VueApexCharts>
        </div>
    </ToggleCardComponent>
</template>

<style lang="scss" scoped>
::v-deep main {
    padding-bottom: 22px;
}
#viewsChart {
    display: flex;
    justify-content: center;
}

.labels {
    display: flex;
    max-width: 320px;
    margin: auto 29px;


    div {
        display: flex;
        flex: 1;
        padding-left: 17px;
        position: relative;
        height: 39px;
        flex-direction: column;
        justify-content: center;

        &:before {
            position: absolute;
            width: 9px;
            content: "";
            display: block;
            background-color: var(--dark-blue);
            height: 100%;
            border-radius: 4.5px;
            left: 0;
        }

        &:nth-child(2) {
            &:before {
                background-color: var(--primary-color);
            }
        }

        h2 {
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0;
        }

        h1 {
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
        }
    }
}
</style>