const superRoutes = [
    {
        path: '/super-admin',
        component: () => import('@/layouts/SuperDashboardLayout'),
        meta: {
            requireUserTypes: ['SUPER'],
        },
        children: [
            {
                path: 'dashboard',
                name: 'superDashboard',
                component: () => import('@/views/super/DashboardView.vue'),
                meta: {
                    requireRoles: ['super-admin', 'finance']
                },
            },
            {
                path: 'bookings-and-quotes',
                name: 'superBookings',
                component: () => import('@/views/super/BookingAndQuotesView.vue'),
                meta: {
                    requireRoles: ['super-admin', 'partner-department', 'customer-service'],
                },
            },
            {
                path: 'create-booking',
                name: 'superCreateBooking',
                component: () => import('@/views/super/CreateBooking.vue'),
                meta: {
                    requireRoles: ['super-admin'],
                },
            },
            {
                path: 'booking-success',
                name: 'superBookingSuccess',
                component: () => import('@/views/super/BookingSuccess.vue'),
                meta: {
                    requireRoles: ['super-admin'],
                },
            },
            {
                path: 'services',
                name: 'superServices',
                component: () => import('@/views/super/ServicesView.vue'),
                meta: {
                    requireRoles: ['super-admin', 'partner-department', 'affiliate-department'],
                },
            },
            {
                path: 'partners',
                name: 'superPartners',
                component: () => import('@/views/super/PartnersView.vue'),
                meta: {
                    requireRoles: ['super-admin', 'partner-department'],
                },
            },
            {
                path: 'affiliates',
                name: 'superAffiliates',
                component: () => import('@/views/super/AffiliatesView.vue'),
                meta: {
                    requireRoles: ['super-admin', 'affiliate-department'],
                },
            },
            {
                path: 'customers',
                name: 'superClients',
                component: () => import('@/views/super/ClientsView.vue'),
                meta: {
                    requireRoles: ['super-admin', 'customer-service'],
                },
            },
            {
                path: 'promo-codes',
                name: 'superPromoCodes',
                component: () => import('@/views/super/PromoCodesView.vue'),
                meta: {
                    requireRoles: ['super-admin', 'partner-department', 'affiliate-department'],
                },
            },
            {
                path: 'sales',
                name: 'superSales',
                component: () => import('@/views/super/SalesView.vue'),
                meta: {
                    requireRoles: ['super-admin', 'finance'],
                },
            },
            {
                path: 'website',
                name: 'superWebsite',
                component: () => import('@/views/super/WebsiteView.vue'),
                meta: {
                    requireRoles: ['super-admin'],
                },
            },
            {
                path: 'team',
                name: 'superTeam',
                component: () => import('@/views/super/TeamView.vue'),
                meta: {
                    requireRoles: ['super-admin'],
                },
            },
            // {
            //     path: 'client/:user_id',
            //     children: [
            //         {
            //             path: '',
            //             children: [
            //                 {
            //                     path: 'bookings',
            //                     component: () => import('@/views/dashboards/client/BookingsView.vue'),
            //                     children: [
            //                         {
            //                             path: '',
            //                             component: () => import('@/views/dashboards/client/BookingsListView.vue')
            //                         },
            //                         {
            //                             path: 'previous',
            //                             component: () => import('@/views/dashboards/client/BookingsListView.vue')
            //                         },
            //                         {
            //                             path: 'canceled',
            //                             component: () => import('@/views/dashboards/client/BookingsListView.vue')
            //                         },
            //                     ]
            //                 },
            //                 {
            //                     path: 'messages/:id?',
            //                     component: () => import('@/views/dashboards/client/MessagesView.vue')
            //                 },
            //                 {
            //                     path: 'profile',
            //                     component: () => import('@/views/dashboards/client/ProfileView.vue')
            //                 },
            //                 {
            //                     path: 'settings',
            //                     component: () => import('@/views/dashboards/client/SettingsView.vue')
            //                 },
            //                 {
            //                     path: 'help',
            //                     component: () => import('@/views/dashboards/client/HelpView.vue')
            //                 }
            //             ]
            //         },
            //     ],
            // },
        ]
    },
]

export default superRoutes