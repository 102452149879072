<script setup>
// import libraries
import { computed, reactive, inject, ref } from 'vue';
import BookingRequestComponent from './BookingRequestComponent.vue';
import ButtonComponent from './ButtonComponent.vue';
import CardComponent from './CardComponent.vue';
import SvgComponent from './SvgComponent.vue';
import ElevetorComponent from '@/components/ElevatorComponent.vue';
import DropDown from './DropDown.vue';
import { useStore } from 'vuex';

const store = useStore()

// custom imports
const emit = defineEmits(['onClick', 'onChangeServiceType', 'onPaginate', 'onSort'])

// consts (props, otherConstants)
const props = defineProps({
    bookings: Array,
    title: String,
    description: String,
    displayAsElevator: { type: Boolean, default: true },
    slidesLength: { type: Number, default: 0 },
    total: Number,
})

const auth = inject('auth')
const activeUser = computed(() => store.getters['superAdm/getActiveUser'])

const modules = activeUser.value.id
    ? JSON.parse(activeUser.value?.company?.modules ?? '{}')
    : JSON.parse(auth.value?.company?.modules)

const serviceType = ref('experiences')
const isServiceTranfer = computed(() => serviceType.value === 'transfers')

const shouldShowOptions = computed(() => props.bookings[0]?.status === 'new')

// const selectedOrderByOption = ref("most-recent")
const selectedOrder = ref("most-recent")
const orderByOptions = reactive({
    "newest": "Newest",
    "oldest": "Oldest",
})
const selectedOrderName = computed(() => orderByOptions[selectedOrder.value])

function onClick(value) {
    emit('onClick', value)
}
function onClickOrderBy(clickedOption) {
    selectedOrder.value = clickedOption
    emit('onSort', clickedOption === 'newest' ? 'desc' : 'asc')
}

function onPaginate() {
    emit('onPaginate')
}

function onChangeServiceType(type) {
    serviceType.value = type
    emit('onChangeServiceType', type)
}
</script>

<template>
    <div v-if="modules.experiences == 1 && modules.transfers == 1" class="d-flex tabs-wrapper">
        <ButtonComponent :color="!isServiceTranfer ? 'light' : 'light-primary'" :class="{active: !isServiceTranfer}" @click="onChangeServiceType('experiences')">
            EXPERIENCES
        </ButtonComponent>
        <ButtonComponent :color="isServiceTranfer ? 'light' : 'light-primary'" :class="{active: isServiceTranfer}" @click="onChangeServiceType('transfers')">
            TRANSFERS
        </ButtonComponent>
    </div>
    <CardComponent class="booking-component" :title="title">
        <template v-slot:header>
            <header class="d-flex direction-column align-items-start space-between">
                <div class="w-100 d-flex space-between">
                    <div class="d-flex direction-column">
                        <h1 class="card-title">{{ title }}</h1>
                        <p>{{ description }}</p>
                    </div>
                    <ButtonComponent class="btn-dots visible-mobile-block" color="transparent" simple
                        :size="{ width: '27px', height: '27px' }" v-if="false">
                        <SvgComponent name="IconEllipsis" />
                    </ButtonComponent>
                </div>
                <div class="w-100 d-flex space-between options">
                    <div>
                        <label class="checkbox" v-if="shouldShowOptions">
                            <input type="checkbox" />
                            <span></span> Select all
                        </label>
                    </div>
                    <div class="btn-options d-flex" v-if="shouldShowOptions">
                        <ButtonComponent color="primary">ACCEPT</ButtonComponent>
                        <ButtonComponent class="btn-decline" bordered color="secondary">DECLINE</ButtonComponent>
                        <ButtonComponent class="btn-dropdown d-flex align-items-center" bordered color="secondary">
                            <SvgComponent name="IconFilter" />
                            <span>FILTER</span>
                            <SvgComponent name="IconArrowDown" />
                        </ButtonComponent>
                    </div>
                    <div class="btn-options d-flex" v-else>
                        <!-- <ButtonComponent class="btn-dropdown d-flex align-items-center" bordered color="secondary" v-if="false">
                            <SvgComponent name="IconFilter" />
                            <span>FILTER</span>
                            <SvgComponent name="IconArrowDown" />
                        </ButtonComponent> -->
                        <DropDown>
                            <template v-slot:header>
                                <ButtonComponent class="btn-dropdown d-flex align-items-center" bordered color="secondary">
                                    <span>order <strong>{{ selectedOrderName }}</strong>&nbsp;&nbsp;</span>
                                    <SvgComponent name="IconArrowDown" />
                                </ButtonComponent>
                            </template>

                            <template v-slot:body>
                                <ul class="order-by-options">
                                    <li v-for="(orderOption, orderKey) in orderByOptions" :key="orderKey"
                                        @click="onClickOrderBy(orderKey)" :class="{ active: orderKey === selectedOrder }"
                                        class="order-by-option">
                                        {{ orderOption }}
                                    </li>
                                </ul>
                            </template>
                        </DropDown>
                    </div>
                </div>
                <span class="quantity">Showing: {{ slidesLength }} results</span>
            </header>
        </template>

        <template v-if="Object.entries(bookings).length">
            <ElevetorComponent @nextSlide="onPaginate" :slidesLength="slidesLength">
                <BookingRequestComponent v-for="booking, index in bookings" :key="index" :booking="booking" type="new"
                    :class="`the-item the-item-${index}`" @onClick="onClick"/>
            </ElevetorComponent>
        </template>
    </CardComponent>
</template>

<style lang="scss" scoped>
.booking-component header {
    padding: 22px 17px 12px;

    h1.card-title {
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
    }

    p {
        color: var(--secodnary-text-color);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 5px 0 0 0;
    }

    .btn-options {
        gap: 15px;
        align-items: center;

        button {
            min-width: 115px;
            padding: 15.5px;
        }

        .btn-dropdown {
            border-color: var(--primary-icon-color-30);
            max-height: 47px;

            span {
                margin-left: 15.47px;
                margin-right: 11.12px;
            }
        }

        .btn-decline {
            border-color: var(--tertiary-color);
            color: var(--tertiary-color);
        }
    }

    .checkbox {
        display: flex;
        gap: 11.16px;
        margin-top: 14.34px;
        color: var(--secodnary-text-color);
        font-family: Montserrat;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        align-items: center;
    }

    .quantity {
        color: var(--secodnary-text-color);
        font-family: Montserrat;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 1px;

        @media screen and (max-width: 767px) {
            line-height: normal;
        }
    }

    .options {
        @media screen and (max-width: 767px) {
            margin-top: 62px;

            .btn-options {
                .btn-dropdown {
                    display: none;
                }

                button {
                    min-width: 84.205px;
                }
            }
        }
    }

    .btn-dots {
        margin-top: -20px;

        @media screen and (min-width: 768px) {
            margin-top: 0;
        }
    }
}

.order-by-options {
    border: 1px solid var(--primary-icon-color-50);
    border-radius: 12px;
    margin: 0;
    list-style: none;
    padding: 5px 0;

    .order-by-option {
        padding: 8px 17px;
        cursor: pointer;
        margin: 0;
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.tabs-wrapper {
    margin-bottom: -65px;
    gap: 2.1px;

    button {
        min-width: 102px;
        position: relative;
        padding-bottom: 25.5px;
        font-weight: 500;

        border-radius: 0;

        &:first-child {
            border-radius: 12px  0 0 0;
            box-shadow: -3px -3px 6px -3px rgba(0, 0, 0, 0.20);
        }

        &:last-child {
            border-radius: 0 12px 0 0;
        }

        &:not(.active):hover {
            background-color: var(--light-secondary-color);
        }

        &.active {
            z-index: 1;
            font-weight: 700;
        }
    }
}
</style>