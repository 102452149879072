<script setup>
// import libraries
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'
import * as XLSX from 'xlsx';
import RevenueComponent from '@/components/RevenueComponent.vue'
import ApiManager from '@/helpers/ApiManager'

// consts (props, otherConstants)
// (variables)
const store = useStore()
const revenues = ref([])

// ComputedVariables
const isAuthenticated = computed(() => store.getters['user/getIsLoggedIn'])

const generateXLSX = function() {
    const data = revenues.value
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "Revenues")
    XLSX.writeFile(wb, "Revenues.xlsx")
}

// Callbacks Lifecycle
onMounted(() => {
    if (!isAuthenticated.value) {
        router.push({
            "name": "login"
        })
    }

    // getSoldItems
    ApiManager.get('api/revenue').then(response => {
        const data = response.data
        if(data.status) {
            revenues.value = data.content?.data?.map(revenue => {
                return {
                    'id': revenue.id,
                    'service_id': revenue.unserialized_service.id,
                    'service_name': revenue.unserialized_service.service_name,
                    'date': JSON.parse(revenue.annotations)?.date,
                    'booking_id': revenue.booking_id,
                    'price': revenue.value,
                    'revenue': Number(revenue.value - revenue.discount).toFixed(2),
                    'user_name': revenue.booking.user.first_name + ' ' + revenue.booking.user.last_name,
                    'status': revenue.booking_item_status,
                }
            })
        }
    })
})
</script>

<template>
    <div class="salutation">
        <h1>Sales</h1>
        <p>See your sales</p>
    </div>
    <div class="row d-flex direction-column">
        <RevenueComponent :revenues="revenues" title="REVENUE DATA" description="REVENUE DATA" @export-xlsx="generateXLSX" />
    </div>
    <div class="see-company-info">
        <h1>SEE Countries, Lda</h1>
        <p>TAGUSVALLEY – PARQUE DE CIÊNCIA E TECNOLOGIA</p>
        <p>RUA JOSÉ DIAS SIMÃO, 2200-062 ABRANTES</p>
        <p>NIPC 518 018 946</p>
    </div>
</template>

<style lang="scss" scoped>
.row {
    flex-direction: column;
    gap: 25px;
    margin-bottom: 20px;

    &:not(.direction-column) {
        @media screen and (min-width: 1200px) {
            flex-direction: row;
        }
    }

    .column {
        display: flex;
        gap: 15px;
        flex: 1;

        &:first-child {
            @media screen and (min-width: 1200px) {
                max-width: 348px;
                flex-direction: column;
            }

            @media screen and (max-width: 1199px) {
                flex-direction: column;
                gap: 25px;
            }
        }

        .app-fullcalendar {
            width: 100%;
            max-height: 380px;
        }
    }
}

.company-card {
    ::v-deep .company-card {
        display: flex;
        flex-direction: row;
        min-height: 114px;
        padding: 17px;
        gap: 12px;

        &>div {
            flex: 1;
            position: relative;

            &.image-container {
                max-width: 101.3px;
                height: 101.3px;
                box-sizing: border-box;
                margin-bottom: 20px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 8px;
                }

                &:after {
                    display: block;
                    content: "";
                    width: 46.25px;
                    height: 46.25px;
                    position: absolute;
                    background-color: var(--primary-color);
                    background-image: url(@/assets/svg/IconRoute.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    border: 2px solid #ffffff;
                    border-radius: 50%;
                    right: -20px;
                    bottom: -20px;
                }
            }

            &.company-info {
                margin-left: 16px;

                h1 {
                    color: var(--primary-color-v2);
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding: 0;
                    margin: 0;
                }

                p {
                    color: #162659;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 6px 0;
                    margin: 0;
                }

                .reviews {
                    display: flex;
                    align-items: center;
                    gap: 11.75px;
                    color: #162659;
                    font-family: Montserrat;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 12.77px;
                    ;
                }

                .last-activity {
                    display: flex;
                    align-items: center;
                    color: #162659;
                    font-family: Montserrat;
                    font-size: 9px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    svg{
                        margin-right: 7.68px;
                        display: flex;
                    }
                }
            }
        }
    }
}

.direction-column {
    gap: 54px;
}

.see-company-info {
    width: 400px;
    background-color: #E9F7FD;
    padding: 20px 25px;

    @media screen and (max-width: 767px) {
        width: 100%;
    }

    h1 {
        color: #162659;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
    }

    p {
        color: #162659;
        font-family: Montserrat;
        font-size: 13px;
    }
}
</style>