import ApiManager from '@/helpers/ApiManager.js'

import { convertDateFormat, decreaseDateByHours } from '@/composables/dateFormat'

function calcFreeCancelationDate(service, purchaseNotes) {
    const serviceAnnotations = typeof service.annotations === "string"
        ? JSON.parse(service.annotations) 
        : service.annotations

    if (serviceAnnotations?.free_cancellation > 0 && serviceAnnotations?.cancellation_policy > 0) {
        return decreaseDateByHours(purchaseNotes.date, serviceAnnotations?.cancellation_policy)
    }

    return null
}

const normalizeBookingItems = (bookingItems) => {
    return bookingItems.map((item) => {
        const totalStars = item.booking.user.reviews.reduce((total, review) => total + review.stars, 0)
        const reviews = item.booking.user.reviews.length
        const stars = totalStars / reviews
        const bookingAnnotations = item.booking.annotations && typeof item.booking.annotations === 'string'
            ? JSON.parse(item.booking.annotations)
            : {}

        let avatar = null;
        if(item?.booking?.user && item.booking.user.avatar[0] && item.booking.user.avatar[0]?.file_name) {
            avatar = `${process.env.VUE_APP_API_URL}/storage/avatar/${item.booking.user.avatar[0].file_name}`
        }

        const itemAnnotations = typeof item.annotations === "string" 
            ? JSON.parse(item.annotations) 
            : item.annotations

        let response = {
            user: {
                id: item.booking.user.id,
                name: item.booking.user.first_name + ' ' + item.booking.user.last_name,
                birthDate: new Date(item.booking.user.profile.birthdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
                avatar: avatar ?? require('@/assets/images/user_fallback_avatar.png'),
                createdAt: convertDateFormat(item.booking.user.created_at),
            },
            freeCancelation: calcFreeCancelationDate(item.unserialized_service, itemAnnotations),
            date: new Date(item.booking_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
            hour: new Date(item.booking_date).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) + 'h',
            eventDate: item.booking_date,
            rating: stars,
            createdAt: item.created_at,
            language: item.annotations?.selected_language ?? 'Portuguese',
            status: item.booking_item_status,
            details: typeof item.annotations === "string" ? JSON.parse(item.annotations) : item.annotations,
            value: item.value,
            discount: item.discount,
            id: item.id,
            servicdData: item.unserialized_service,
            notes: bookingAnnotations.notes ?? null,
            pendingHours: item.pending_hours,
        }

        response.service = null
        if (item.unserialized_service && item.unserialized_service.service) {
            response.service = item.unserialized_service.service
        } else if (item.service && item.service.service_name) {
            response.service = item.service.service_name
        }

        if (item.service && item.service.duration) {
            response.duration = item.service.duration
        }

        if(item.service && item.service.duration) {
            response.tours = item.service.duration
        }

        return response
    })
}

const state = {
    bookingItems: {},
    bookingItemsResponse: {},
    bookingHistory: {},
    filters: {}
}

const getters = {
    getBookingItems: (state) => state.bookingItems,
    getNewBookingItems: (state) => state.bookingItems.new ?? [],
    getAcceptedBookingItems: (state) => state.bookingItems.accepted ?? [],
    getArchivedBookingItems: (state) => state.bookingItems.archived ?? [],
    getCancelledBookingItems: (state) => {
        let completed = state.bookingItems?.archived?.filter(item => item.status === 'cancelled')
        return completed ?? []
    },
    getScheduledBookingItems: (state) => state.bookingItems.accepted ?? [],
    getCompletedBookingItems: (state) => {
        let completed = state.bookingItems?.archived?.filter(item => item.status === 'completed')
        return completed ?? []
    },
    getAcceptedBookingsTotal: (state) => state.bookingItemsResponse.accepted?.total ?? 0,
    getArchivedBookingsTotal: (state) => state.bookingItemsResponse.archived?.total ?? 0,
    getNewBookingsTotal: (state) => state.bookingItemsResponse.new?.total ?? 0,
    getBookingHistory: (state) => state.bookingHistory,
}

const actions = {
    fetchBookingItems: async ({ commit, state }, payload = {}) => {
        let loadedAnyPage = state.bookingItemsResponse[(payload.destination)]?.current_page
        let endpoint = '/api/dashboard/booking-items'

        if(!payload.reset && loadedAnyPage && !payload.sort) {
            endpoint = state.bookingItemsResponse[(payload.destination)].next_page_url
            if (! endpoint) {
                return false
            }
        }

        let getAttempt = await ApiManager.get(endpoint, { params: payload })
        if (getAttempt) {
            commit("SET_BOOKING_ITEMS", { payload: payload, response: getAttempt.data })
            return true
        }
        return false
    },
    fetchBookingHistory: async ({ commit, state }, payload) => {
        let getAttempt = await ApiManager.get(`/api/dashboard/user/${payload}/booking-history`)
        if(getAttempt) {
            commit('SET_BOOKING_HISTORY', getAttempt.data)
            return true
        }
        return false
    },
    updateStatus: async ({ dispatch }, payload) => {
        let postAttemp = await ApiManager.post(`/api/dashboard/booking-items/${payload.booking}/update`, { status: payload.status})
        if(postAttemp) {
            if(postAttemp.data) {
                return true
            }
        }
        return false
    }
}

const mutations = {
    SET_BOOKING_ITEMS: (state, data) => {
        const { response, payload } = data
        if(payload.reset || payload.sort) {
            state.bookingItems[(payload.destination)] = [...normalizeBookingItems(payload.items == -1 ? response : response.data)]
        } else {
            state.bookingItems[(payload.destination)] = state.bookingItems[(payload.destination)] ?  [...state.bookingItems[(payload.destination)], ...normalizeBookingItems(response.data)] : normalizeBookingItems(response.data)
        }

        const removeDuplicates = array => {
            const map = new Map()
            array.forEach(item => map.set(item.id, item))
            
            return [...map.values()]
        }

        state.bookingItems[(payload.destination)] = removeDuplicates(state.bookingItems[(payload.destination)]);

        state.bookingItemsResponse[(payload.destination)] = response
    },
    SET_BOOKING_HISTORY: (state, data) => {
        state.bookingHistory = normalizeBookingItems(data)
    },
    CLEAR_BOOKING_HISTORY: (state) => {
        state.bookingHistory = {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}