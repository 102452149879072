<script setup>
    import { computed, ref } from 'vue';
    import SvgComponent from '@/components/SvgComponent'

    const emit = defineEmits(['update:modelValue']);
    
    // Props
    const props = defineProps({
        label: {type: String, default: ''},
        modelValue: {type: String, default: ''},
        placeholder: {type: String, default: ''},
        required: { type: Boolean, default: false},
        errors: { type: Array, default: [] }
    })

    // reactive Variables
    const text = ref(props.modelValue)
    const canViewPass = ref(false)
    const fieldErrors = computed(() => props.errors)

    // Methods
    function onInput() {
        emit('update:modelValue', text.value);
    }

    function toggleCanViewPass() {
        canViewPass.value = !canViewPass.value
    }
</script>

<template>
    <div class="input-field-wrapper">
        <label>{{ label }} {{ required ? '*' : '' }}</label>
        <div class="input-field-inner-wrapper">
            <input :type="canViewPass ? 'text' : 'password'" v-model="text" @input="onInput()" :placeholder="placeholder" :required="required" :class="{ 'is-invalid': fieldErrors.length }" />
            <SvgComponent v-if="!canViewPass" name="eye" class="show-hide-pass" @click="toggleCanViewPass"/>
            <SvgComponent v-else name="eye-slash" class="show-hide-pass" @click="toggleCanViewPass"/>
        </div>
        <div class="invalid-feedback" v-if="fieldErrors.length">
            <ul>
                <li v-html="fieldErrors.join('</li><li>')"></li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-field-inner-wrapper{
    position: relative;
}
.show-hide-pass{
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    cursor:pointer;

    ::v-deep{
        svg{
            opacity: 0.6;
            width:100%;
            height:auto;
        }
    }
}
</style>