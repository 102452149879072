import ApiManager from "@/helpers/ApiManager"

function paramsObjectToString(paramsObject) {
    let paramsArr = []
    for (let key in paramsObject) {
        if (paramsObject[key]) {
            paramsArr.push(key + "=" + paramsObject[key])
        }
    }

    return paramsArr.join("&");
}

const state = {
    requestedCodes: [],
    assignedCodes: [],
    usedCodes: [],
}

const getters = {
    getRequestedCodes: state => state.requestedCodes ?? [],
    getAssignedCodes: state => state.assignedCodes ?? [],
    getUsedCodes: state => state.usedCodes ?? [],
}

const actions = {
    fetchPromoCodes: async ({ commit }, payload) => {
        const paramsString = paramsObjectToString({ page: 1, ...payload })
        const getAttempt = await ApiManager.get(`api/super/promo-codes?${paramsString}`)

        if (getAttempt) {
            if (payload.status === 'requested') {
                commit('SET_REQUESTED_PROMO_CODES', getAttempt.data.content.data)
            }

            if (payload.status === 'assigned') {
                commit('SET_ASSIGNED_PROMO_CODES', getAttempt.data.content.data)
            }

            if (payload.status === 'used') {
                commit('SET_USED_PROMO_CODES', getAttempt.data.content.data)
            }

            return true
        }

        return false
    },
    create: async ({ commit }, payload) => {
        const response = await ApiManager.post(`api/super/promo-codes`, payload)
        return response
    },
    update: async ({ commit }, payload) => {
        const response = await ApiManager.post(`api/super/promo-codes/${payload.id}/update`, payload)
        return response
    },
    updateStatus: async ({ commit }, payload) => {
        const response = await ApiManager.post(`api/super/promo-codes/${payload.id}/status`, {
            status: payload.status
        })

        return response
    },
    updateRequestStatus: async ({ commit }, payload) => {
        const response = await ApiManager.post(`api/super/promo-codes/request/${payload.id}/status`, {
            status: payload.status
        })

        return response
    },
}

const mutations = {
    SET_REQUESTED_PROMO_CODES: (state, payload) => {
        state.requestedCodes = payload
    },
    SET_ASSIGNED_PROMO_CODES: (state, payload) => {
        state.assignedCodes = payload
    },
    SET_USED_PROMO_CODES: (state, payload) => {
        // state.usedCodes = payload
        state.usedCodes = []
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}