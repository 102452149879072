import ApiManager from "@/helpers/ApiManager"

function paramsObjectToString(paramsObject) {
    let paramsArr = []
    for (let key in paramsObject) {
        if (paramsObject[key]) {
            paramsArr.push(key + "=" + paramsObject[key])
        }
    }

    return paramsArr.join("&");
}

const state = {
    activeServices: [],
    inactiveServices: [],
    draftServices: [],
    promotedServices: [],
}

const getters = {
    getActiveServices: state => state.activeServices ?? [],
    getInactiveServices: state => state.inactiveServices ?? [],
    getDraftServices: state => state.draftServices ?? [],
    getPromotedServices: state => state.promotedServices ?? [],
}

const actions = {
    fetchServices: async ({ commit }, payload) => {
        const paramsString = paramsObjectToString({ page: 1, ...payload })
        const getAttempt = await ApiManager.get(`api/super/${payload.type}?${paramsString}`)

        if (getAttempt) {
            if (payload.status === 'active') {
                commit('SET_ACTIVE_SERVICES', getAttempt.data.content.data)
            }

            if (payload.status === 'inactive') {
                commit('SET_INACTIVE_SERVICES', getAttempt.data.content.data)
            }

            if (payload.status === 'draft') {
                commit('SET_DRAFT_SERVICES', getAttempt.data.content.data)
            }

            if (payload.status === 'promoted') {
                commit('SET_PROMOTED_SERVICES', getAttempt.data.content.data)
            }

            return true
        }

        return false
    },
}

const mutations = {
    SET_ACTIVE_SERVICES: (state, payload) => {
        state.activeServices = payload
    },
    SET_INACTIVE_SERVICES: (state, payload) => {
        state.inactiveServices = payload
    },
    SET_DRAFT_SERVICES: (state, payload) => {
        state.draftServices = payload
    },
    SET_PROMOTED_SERVICES: (state, payload) => {
        state.promotedServices = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}