<template>
    <router-view />
    <audio ref="chatAlert" :src="require('@/assets/audio/alert.mp3')"></audio>
</template>

<script setup>
import { computed, provide, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const auth = computed(() => store.getters['user/getUser'])
const chatAlert = ref(null)
provide('auth', auth)
provide('chatAlert', chatAlert)

function changeTemplate(template) {
    if (template === 'default') {
        const oldLink = document.getElementById('dynamic-css');
        if (oldLink) {
            document.head.removeChild(oldLink);
        }
        return;
    }
    
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    
    link.href = `/themes/${template}.css`;
    
    const oldLink = document.getElementById('dynamic-css');
    if (oldLink) {
        document.head.removeChild(oldLink);
    }
    
    link.id = 'dynamic-css';
    document.head.appendChild(link);
}
</script>