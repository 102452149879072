import ApiManager from "@/helpers/ApiManager"

function paramsObjectToString(paramsObject) {
    let paramsArr = []
    for (let key in paramsObject) {
        if (paramsObject[key]) {
            paramsArr.push(key + "=" + paramsObject[key])
        }
    }

    return paramsArr.join("&");
}

const state = {
    sales: [],
    total: [],
}

const getters = {
    getSales: state => state.sales ?? [],
    getTotal: state => state.total ?? [],
}

const actions = {
    fetchSales: async ({ commit }, payload) => {
        const paramsString = paramsObjectToString({ page: 1, ...payload })
        const getAttempt = await ApiManager.get(`api/super/sales?${paramsString}`)

        if (getAttempt) {
            commit('SET_SALES', getAttempt.data.content.data)
            return true
        }

        return false
    },
    fetchTotal: async ({ commit }, payload) => {
        const paramsString = paramsObjectToString({ ...payload })
        const getAttempt = await ApiManager.get(`api/super/sales/total?${paramsString}`)

        if (getAttempt) {
            commit('SET_TOTAL', getAttempt.data.content)
            return true
        }

        return false
    }
}

const mutations = {
    SET_SALES: (state, payload) => {
        state.sales = payload
    },
    SET_TOTAL: (state, payload) => {
        state.total = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}