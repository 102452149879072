<script setup>
import { computed, ref, watch } from 'vue';
import Tooltip from '@/components/Tooltip.vue'

const emit = defineEmits(['update:modelValue']);

// Props
const props = defineProps({
    label: { type: String, default: '' },
    modelValue: { type: Number, default: 0 },
    errors: { type: Array, default: [] },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    leftOption: { type: Object },
    rightOption: { type: Object },
    tooltip: { type:String, default: null },
})

// reactive Variables
const val = ref(props.modelValue ? props.modelValue : 0)
const fieldErrors = computed(() => props.errors)

// Methods
function checkTheBox() {
    if(val.value === 0) {
        val.value = 1
    } else {
        val.value = 0
    }
    emit('update:modelValue', val.value);
}

// Watchers
watch(() => props.modelValue, (newVal) => {
    val.value = newVal
})
</script>

<template>
    <div class="checkbox-wrap input-field-wrapper" :class="{checked:val, 'is-invalid': fieldErrors.length}">
        <!-- <div v-if="label" class="fake-checkbox-input" @click="checkTheBox()"> -->
        <div v-if="label" class="fake-checkbox-input">
            <label>{{ label }} {{ required ? '*' : '' }}</label>
            <Tooltip class="tooltip-component" v-if="tooltip">
                <div v-html="tooltip"></div>
            </Tooltip>
        </div>

        <div class="switch" @click="checkTheBox()">
            <div class="switch-option" :class="{ active: val === leftOption.val }">
                <span>{{leftOption.label}}</span>
            </div>
            <div class="switch-option" :class="{ active: val === rightOption.val }">
                <span>{{rightOption.label}}</span>
            </div>
        </div>

        <div class="invalid-feedback" v-if="fieldErrors.length">
            <ul>
                <li v-html="fieldErrors.join('</li><li>')"></li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.checkbox-wrap{
    margin-bottom:18px;
    cursor:pointer;
    .fake-checkbox-input{
        display:flex;
        justify-content: space-between;
        // margin-top:20px;
        // div{
        //     width:15px;
        //     height:15px;
        //     border-radius: 100px;
        //     background:var(--light-secondary-color);
        //     border:2px solid var(--primary-text-color);
        //     transition:all 0.1s ease;
        // }
    
        span{
            color: var(--secondary-text-color);
            text-align: justify;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: block;
            width:calc(100% - 30px);
        }
    }

    &.checked{
        .fake-checkbox-bullet{
            background:var(--primary-text-color);
        }
    }

}
.switch{
    min-width:193px;
    border:1px solid #FC6030;
    border-radius: 12px;
    display: inline-flex;
    justify-content: space-between;
    transition:all 0.1s ease;
    background:#fff;

    .switch-option{
        transition:all 0.1s ease;
        color: #FC6030;
        font-family: Montserrat;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
        text-align: center;
        box-sizing: content-box;

        min-width:calc(50% - 12px);
        padding:10px 6px;
        text-align: center;
        display: flex;
        align-items: center;
        margin:-1px;
        border-radius: 9px;
        justify-content: center;

        &.active{
            border: 0.5px solid var(--primary-color);
            background: var(--primary-color);
            color:var(--light-color);
        }
    }
}

.tooltip-component {
    margin-bottom: 6px;
    margin-left: 6px;
}
</style>