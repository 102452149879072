import ApiManager from "@/helpers/ApiManager"

function paramsObjectToString(paramsObject) {
    let paramsArr = []
    for (let key in paramsObject) {
        if (paramsObject[key]) {
            paramsArr.push(key + "=" + paramsObject[key])
        }
    }

    return paramsArr.join("&");
}

const state = {
    team: [],
    roles: [],
}

const getters = {
    getTeam: state => state.team ?? [],
    getRoles: state => state.roles ?? [],
    getRoleOptions: state => {
        return state.roles.reduce((result, role) => {
            result[role.id] = {
                label: role.role_name,
                description: role.description
            }
            return result
        }, {})
    }
}

const actions = {
    fetchTeam: async ({ commit }, payload) => {
        const paramsString = paramsObjectToString({ page: 1, ...payload })
        const getAttempt = await ApiManager.get(`api/super/team?${paramsString}`)

        if (getAttempt) {
            commit('SET_TEAM', getAttempt.data.content.data)
            return true
        }

        return false
    },
    fetchRoles: async ({ commit }, payload) => {
        const getAttempt = await ApiManager.get('api/super/roles')

        if (getAttempt) {
            commit('SET_ROLES', getAttempt.data.content)
            return true
        }

        return false
    },
    create: async ({ commit }, payload) => {
        const response = await ApiManager.post(`api/super/team`, payload)
        return response
    },
    update: async ({ commit }, payload) => {
        const response = await ApiManager.post(`api/super/team/${payload.id}/update`, payload)
        return response
    },
}

const mutations = {
    SET_TEAM: (state, payload) => {
        state.team = payload
    },
    SET_ROLES: (state, payload) => {
        state.roles = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}