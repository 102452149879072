<script setup>
    import { computed, ref } from 'vue';

    const emit = defineEmits(['update:modelValue']);
    
    // Props
    const props = defineProps({
        label: {type: String, default: ''},
        modelValue: {type: String, default: ''},
        placeholder: {type: String, default: ''},
        required: { type: Boolean, default: false},
        errors: { type: Array, default: [] },
    })

    // reactive Variables
    const fieldErrors = computed(() => props.errors)
    const text = ref(props.modelValue);

    // Methods
    function onInput() {
        emit('update:modelValue', text.value);
    }
</script>

<template>
    <div class="input-field-wrapper">
        <label>{{ label }} {{ required ? '*' : '' }}</label>
        <input type="email" v-model="text" @input="onInput()" :placeholder="placeholder" :required="required" :class="{ 'is-invalid': fieldErrors.length }" />
        <div class="invalid-feedback" v-if="fieldErrors.length">
            <ul>
                <li v-html="fieldErrors.join('</li><li>')"></li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>