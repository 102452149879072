const defaultWarningModalState = () => {
    return {
        show: false,
        title: 'Warning',
        htmlCopy: '<p>Do you wish to proceed?</p>',
        buttons: [
            {
                copy: "Yes",
                action: null,
                htmlAttributes: {"color": "primary", "style": "min-width:84px;"},
            },
            {
                copy: "No",
                action: null,
                htmlAttributes: {"bordered": true, "class": "btn-decline", "style": "min-width:84px;"}
            }
        ],
        exitAction:null
    }
}

const state = {
    sidebar: {
        isToggled: false
    },
    warningModal: defaultWarningModalState()
}

const getters = {
    getIsToggled: state => state.sidebar.isToggled,
    getWarningModal: state => state.warningModal
}

const actions = {
    // async

    showWarningModal: ({ commit }, payload) => {
        
        let modalData = defaultWarningModalState()

        modalData.show = true
        
        if (payload.title) {
            modalData.title = payload.title
        }

        if (payload.buttons) {
            modalData.buttons = payload.buttons
        }

        if (payload.htmlCopy) {
            modalData.htmlCopy = payload.htmlCopy
        }

        if (payload.exitAction) {
            modalData.exitAction = payload.exitAction
        }

        commit("SET_WARNING_MODAL", modalData)
    },

    closeWarningModal: ({ commit }) => {
        let modalData = defaultWarningModalState()

        commit("SET_WARNING_MODAL", modalData)
    }
}

const mutations = {
    TOGGLE_SIDEBAR: (state) => {
        state.sidebar.isToggled = !state.sidebar.isToggled
    },
    SET_WARNING_MODAL: (state, payload) => {
        state.warningModal = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}