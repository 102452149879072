<script setup>
    // import libraries
    import { ref } from 'vue'

    // reactiveVariables
    const isOpen = ref(false)

    // Methods
    function onClickToggle() {
        isOpen.value = !isOpen.value
    }

    function onClickOptions() {
        isOpen.value = false
    }

</script>

<template>
<div class="dropdown-baloon">
    <div class="toggle" @click="onClickToggle()">
        <slot name="header"></slot>
    </div>
    <div class="options" v-show="isOpen" @click="onClickOptions()">
        <slot name="body"></slot>
    </div>
</div>
</template>

<style lang="scss" scoped>
.dropdown-baloon{
    position:relative;
}
.options{
    top:100%;
    position:absolute;
    right:0;
    min-width: 100%;
    z-index: 3;
    background: var(--light-color);
}
</style>