import { computed, inject, ref } from "vue"
import { useStore } from 'vuex'
import io from 'socket.io-client'
import router from "@/router"
import { Howl } from 'howler'

export function useChat() {
    const store = useStore()
    const socket = io(process.env.VUE_APP_SOCKET_URL)
    const user = inject('auth')
    const audio  = inject('chatAlert')
    const search = ref('')

    audio.value.volume = 0.1
    const alertSound = new Howl({
        src: [audio.value.src],
        volume: 0.1
    })

    const loadChatRooms = function() {
        store.dispatch('chat/loadChatRooms').then((chatRooms) => {
            if(chatRooms?.content?.length) {
                const rooms = chatRooms.content.map(chat => chat.id) ?? []
                socket.emit('enter', {user: user?.value?.id, chatRooms: rooms})
            }
        })
    }

    const createChatRoom = function(user) {
        store.dispatch('chat/createChatRoom', user).then((chatRoom) => {
            socket.emit('enter', {user: user?.value?.id, chatRooms: [chatRoom.content.id]})
            socket.emit('redirect')
            router.push({ name: 'ClientMessages', params: { id: chatRoom.content.id } })
        })
    }

    const chatList = computed(() => store.getters['chat/getChatList'])

    if(chatList.value.length === 0 && user?.value) {
        loadChatRooms()
    }
    
    const initChat = function (chatId) {
        if(chatId !== chat.value?.id) {
            store.dispatch('chat/initChat', chatId)
        }
        socket.emit('addUser', { user: user?.value?.id, chat_id: chatId })
        socket.emit('checkUsers')
    }

    const chat = computed(() => store.getters['chat/getCurrentChat'])

    const canRedirect = computed(() => user?.value?.user_type == 'SUPER' && chat.value?.redirect_options?.length)

    const sendMessage = function (message, attachments = null) {
        const payload = new FormData()
        payload.append('chat_id', chat.value.id ?? null)
        payload.append('message', message)
        payload.append('user', 101)
        if(attachments) {
            attachments.forEach(attachment => {
                payload.append('attachments[]', attachment)
            })
        }

        store.dispatch('chat/sendMessage', payload).then(response => {
            socket.emit('sendMessage', response.content)
        }).catch(error => {
            console.error(error)
        })
    }

    const viewChat = function() {
        if(chat.value.id && chat?.value?.messages?.length > 0 && chat?.value?.messages?.filter(m => m?.status === 'sent')?.length > 0) {
            store.dispatch('chat/readMessages', chat.value.id).then(() => {
                socket.emit('viewChat', chat.value.id)
            })
        }
    }

    const setTypingStatus = function(chatId, status) {
        socket.emit('typing', {chat_id: chatId, user: user?.value?.id, typing: status})
    }

    const redirect = function(to) {
        store.dispatch('chat/redirect', {chat: chat.value.id, to}).then(() => {
            socket.emit('redirect', chat.value.id)
            router.push('/user-panel/messages')
        })
    }
    
    socket.on('getMessage', (msg) => {
        store.commit('chat/ADD_MESSAGE', {content: msg}, { root: true})
        
        let isChatMuted = Number(localStorage.getItem('chatIsMuted') ?? 0)
        if(msg.sender_id !== user?.value?.id && !isChatMuted) {
            alertSound.play()
        }
    })
    
    socket.on('chatViewed', (chatId) => {
        store.dispatch('chat/updateChat', chatId)
    })
    
    socket.on('connectedUsers', (connectedUsers) => {
        let updateUsersStatus = connectedUsers.filter(id => id != user?.value?.id)
        store.dispatch('chat/setOnlineUsers', updateUsersStatus)
    })
    
    socket.on('userConnected', (connectedUser) => {
        if(user && connectedUser !== user?.value?.id)  {
            store.dispatch('chat/userWentOnline', connectedUser)
        }
    })
    
    socket.on('userDisconnected', (disconnectedUser) => {
        store.dispatch('chat/userWentOffline', disconnectedUser)
    })
    
    socket.on('userTyping', (data) => {
        if(user && data.user !== user?.value?.id)
            store.dispatch('chat/setUserTyping', data)
    })

    socket.on('redirected', () => {
        store.dispatch('chat/loadChatRooms')
    })

    const messages = computed(() => store.getters['chat/getMessages'])

    return {
        chat,
        chatList,
        sendMessage,
        messages,
        initChat,
        viewChat,
        setTypingStatus,
        canRedirect,
        redirect,
        createChatRoom,
        search
    }
}