<script setup>
import { ref } from 'vue'
import SvgComponent from './SvgComponent.vue';

// Props
const props = defineProps({
    svgIconName: {type: String, default:null}
})

// Reactive Variables
const iconElement = ref(null)
const tooltipElement = ref(null)
const tooltipLeft = ref(0)
const tooltipTop = ref(0)
const canShow = ref(false)
const tooltipPosition = ref("top")

// Methods
function setTooltipPosition() {
    if(!canShow.value) return
    
    let elementBounds = iconElement.value.getBoundingClientRect()
    if(elementBounds.top > tooltipElement.value.offsetHeight + 20) {
        setTooltipPositionTop(elementBounds)
    } else {
        setTooltipPositionBottom(elementBounds)
    }
}

function setTooltipPositionTop(elementBounds) {
    tooltipTop.value = elementBounds.top - tooltipElement.value.offsetHeight + 16
    tooltipLeft.value = elementBounds.left + (iconElement.value.offsetWidth/2)
    tooltipPosition.value = "top"
}

function setTooltipPositionBottom(elementBounds) {
    tooltipTop.value = elementBounds.top
    tooltipLeft.value = elementBounds.left + (iconElement.value.offsetWidth/2)
    tooltipPosition.value = "bottom"
}

function onMouseOver(event) {
    canShow.value = true
    setTooltipPosition()
}

function onMouseLeave(event) {
    setTimeout(() => {
        canShow.value = false
    }, 10)
}

// Callbacks Lifecycle
window.onscroll = () => {
    canShow.value = false
}
window.onresize = () => {
    setTooltipPosition()
}
</script>

<template>
    <div>
        <div v-if="!svgIconName" class="tootltip-icon" ref="iconElement" @mouseover="onMouseOver">
            <span>?</span>
        </div>
        <div v-else class="tootltip-svg-icon" ref="iconElement" @mouseover="onMouseOver">
            <SvgComponent :name="svgIconName"/>
        </div>
        <div
            class="tooltip-outter-wrap"
            ref="tooltipElement"
            :style="{
                left: `${tooltipLeft}px`,
                top:`${tooltipTop}px`,
                visibility: canShow ? 'visible' : 'hidden'
            }" 
            :class="{
                'active': canShow,
                'tooltip-bottom': tooltipPosition === 'bottom',
                'tooltip-top': tooltipPosition === 'top'
            }"
            @mouseleave="onMouseLeave"
            @scroll="onScrollEvent"
        >
            <div class="tooltip">
                <slot></slot>
            </div>
        </div>
        
    </div>
</template>

<style lang='scss' scoped>
.tootltip-icon{
    width: 16px;
    height: 15px;
    background:#C5E5F6;
    border-radius: 100px;
    position:relative;
    cursor:help;

    span{
        color: #FFF;
        font-family: Montserrat;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        position:absolute;
        line-height: 1;
        top:50%;
        left:50%;
        transform:translate(-50% , -50%);
    }
}
.tooltip{
    width:100%;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: Medium;
    line-height: normal;
    background: #7270FE;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 12px;
    text-align:left;
    position:relative;
    // box-shadow: 0px 0px 3px #333333;
}
.tooltip-outter-wrap{
    cursor:help;
    position:fixed;
    // width:149px;
    z-index: 800;
    visibility:hidden;
    opacity:0;
    transition:opacity 0.35s ease;

    &.active{
        visibility:visible;
        opacity:1;
    }

    &.tooltip-bottom{
        padding-top: 35px;
        margin-left: -25px;
        margin-top: -4px;
        &:before{
            top: 24px;
            left:20px;
            position:absolute;
            content:"";
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 0 5.5px 12px 5.5px;
            border-color: transparent transparent #7270FE transparent;
        }
    }

    &.tooltip-top{
        padding-bottom: 31px;
        margin-left: -25px;
        margin-top: -1px;
        &:before{
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 5.5px 0 5.5px;
            border-color: #7270FE transparent transparent transparent;
            left:20px;
            position:absolute;
            content:"";
            bottom: 20px;
        }
    }
}
</style>