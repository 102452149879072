<script setup>
// import libraries
import { computed, onMounted, inject, ref, reactive, watch } from 'vue'

import { useStore } from 'vuex'
import router from '@/router'
import CardComponent from '@/components/CardComponent.vue'
import RevenuePercentagesComponent from '@/components/RevenuePercentagesComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import ModalComponent2 from '@/components/ModalComponent2.vue'
import NumberInputComponent from '@/components/form/NumberInputComponent.vue'
import ApiManager from '@/helpers/ApiManager'
import { useToast } from 'vue-toast-notification'

// (variables)
const auth = inject('auth')
const store = useStore()
const userPromoCode = ref(null)
const newPromoCode = ref(null)
const showModal = ref(false)
const newCode = ref(null)
const form = reactive({})

const revenues = ref([])

const toast = useToast()

// ComputedVariables
const isAuthenticated = computed(() => store.getters['user/getIsLoggedIn'])

// Methods
function onClickInfoButton() {
    alert("onClickInfoButton")
}

function capitalize(str) {
    str = str.toLowerCase()
    return str.charAt(0).toUpperCase() + str.slice(1)
}

function editCode() {
    showModal.value = true
}

function closeModal() {
    showModal.value = false
}

function fetchData() {
    ApiManager.get('api/user/promo-code').then(response => {
        const data = response.data
        if (data.status) {
            userPromoCode.value = data.content

            form.discount_value = data.content.discount_value
            form.comission_value = data.content.comission_value
        }
    })

    // getSoldItems
    ApiManager.get('api/revenue', {
        params: {
            'affiliate': true
        }
    }).then(response => {
        const data = response.data
        if (data.status) {
            revenues.value = data.content?.data?.map(revenue => {
                return {
                    'id': revenue.id,
                    'service_name': revenue.unserialized_service.service_name,
                    'service_id': revenue.unserialized_service.id,
                    'date': JSON.parse(revenue.annotations)?.date,
                    'booking_date': revenue.booking_date,
                    'booking_id': revenue.booking_id,
                    'price': revenue.value,
                    'revenue': Number(revenue.value - revenue.discount).toFixed(2),
                    'user_name': revenue.booking.user.first_name + ' ' + revenue.booking.user.last_name,
                    'user_id': revenue.booking.user.id,
                    'status': revenue.booking_item_status,
                    'code': revenue.promo_code.code,
                    'discount': Number(revenue.discount).toFixed(2),
                    'percentage_gained': (revenue.discount * 100 / revenue.value)+'%'
                }
            })
        }
    })
}

async function requestChangeCode() {
    const { data } = await ApiManager.post('api/promo-code/request-change', {
        promo_code_id: userPromoCode.value.id,
        code: userPromoCode.value.code,
        new_code: newCode.value,
    })

    if (! data.status) {
        showModal.value = false
        toast.error(data.message)
        return false
    }

    newCode.value = null
    showModal.value = false
    
    toast.success("Request sent successfully")
}

async function saveCode() {
    const { data } = await ApiManager.post(`api/promo-code/${userPromoCode.value.id}/update`, form)

    if (! data.status) {
        showModal.value = false
        toast.error(data.message)
        return false
    }

    showModal.value = false
    fetchData()
    
    toast.success("Code saved successfully")
}

watch(() => form.comission_value, () => form.discount_value = 10 - form.comission_value)
watch(() => form.discount_value, () => form.comission_value = 10 - form.discount_value)

// Callbacks Lifecycle
onMounted(() => {
    if (!isAuthenticated.value) {
        router.push({ "name": "login" })
    }

    fetchData()
})
</script>

<template>
    <div class="salutation">
        <h1>Overview</h1>
        <p>Get your codes and check your status</p>
        <!-- <ButtonComponent @click="onClickInfoButton" color="primary" class="info-button">
            INFO
        </ButtonComponent> -->
    </div>
    <!-- <div class="promoter-area-section-1">

        <CardComponent class="generate-promo-code-form" title="GENERATE YOUR CODE">
            <p class="generate-promo-code-form-description"> Get your discount codes here</p>
            <div class="generate-promo-code-form-section-1">
                <ButtonComponent bordered color="secondary" :size="{width:'calc(56.83% - 5px)'}">
                    SELECT SERVICE
                </ButtonComponent>
                <ButtonComponent color="primary" :size="{width:'calc(43.17% - 5px)'}">
                    GENERATE
                </ButtonComponent>
            </div>
            <div class="promo-code-stage" :class="{active:newPromoCode}">
                <span>{{newPromoCode ? newPromoCode : '*************'}}</span>
            </div>
        </CardComponent>

        <CardComponent class="revenue-chart-wrap" title="REVENUE CHART">
            <ButtonComponent class="btn-dots" color="transparent" simple :size="{ width: '27px', height: '27px' }" v-if="false">
                <SvgComponent name="IconEllipsis" />
            </ButtonComponent>
            chart goes here        
        </CardComponent>
    </div> -->
    
    <div class="row d-flex direction-column">
        <CardComponent title="YOUR CODE" class="code-panel" v-if="userPromoCode">
            <div class="d-flex code-info">
                <div class="affiliate-code-container">
                    <span class="affiliate-code">{{ userPromoCode.code }}</span>
                </div>
                <div class="affiliate-field">
                    <span class="affiliate-label">Comission {{ userPromoCode.discount_type == 'PERCENT' ? 'percentage' : 'value' }}</span>
                    <span class="affiliate-value">{{ userPromoCode.comission_value }}{{ userPromoCode.discount_type == 'PERCENT' ? '%' : '€' }}</span>
                </div>
                <div class="affiliate-field">
                    <span class="affiliate-label">Discount {{ userPromoCode.discount_type == 'PERCENT' ? 'percentage' : 'value' }}</span>
                    <span class="affiliate-value">{{ userPromoCode.discount_value }}{{ userPromoCode.discount_type == 'PERCENT' ? '%' : '€' }}</span>
                </div>
                <div class="affiliate-field">
                    <span class="affiliate-label">Usage count</span>
                    <span class="affiliate-value">{{ userPromoCode.total_usage }}</span>
                </div>
                <div class="affiliate-field">
                    <span class="affiliate-label">Most Used Service</span>
                    <span class="affiliate-value">{{ capitalize(userPromoCode?.most_used_service?.service?.company?.company_type ?? '--')}}</span>
                </div>
                <div>
                    <ButtonComponent color="secondary" bordered class="edit-button" @click="editCode">
                        Edit
                    </ButtonComponent>
                </div>
            </div>
        </CardComponent>
        <RevenuePercentagesComponent :revenuePercentages="revenues" title="USED CODES" description="REVENUE DATA" />
    </div>
    
    <ModalComponent2 width="700px" :showModal="showModal" @close="closeModal()">
        <div class="modal-edit-code">
            <div class="modal-edit-header">
                <h1>EDIT YOUR CODE</h1>
            </div>
            <div class="modal-edit-body">
                <label>SUGGEST A NEW CODE</label>
                <div class="input-code">
                    <input placeholder="**********" v-model="newCode" maxlength="20"/>
                </div>
                <small>max. 20 characters</small>

                <ButtonComponent color="primary" @click="requestChangeCode" :disabled="!newCode">
                    REQUEST CHANGE
                </ButtonComponent>

                <div class="divider"></div>

                <div class="form-wrap form-wrap-adjusted-for-desktop">
                    <NumberInputComponent v-model="form.comission_value" label="COMISSION PERCENTAGE" placeholder="10" min="0" max="10" required />
                    <NumberInputComponent v-model="form.discount_value" label="DISCOUNT PERCENTAGE" placeholder="0" min="0" max="10" required />
                </div>

                <ButtonComponent color="primary" @click="saveCode">
                    SAVE CODE
                </ButtonComponent>
            </div>
            <div class="modal-edit-footer"></div>
        </div>
    </ModalComponent2>
</template>

<style lang="scss" scoped>
.modal-edit-code {
    .modal-edit-header {
        h1 {
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
        }
    }

    .modal-edit-body {
        margin-top: 30px;

        label {
            color: var(--primary-color);
            font-family: Montserrat;
            font-weight: 500;
        }

        input {
            width: 100%;
            font-family: Montserrat;
            font-size: 14px;
            background-color: var(--panels-blue);
            padding: 20px;
            border: none;
            border-radius: 10px;
            align-items: center;
            text-transform: uppercase;
        }

        small {
            display: flex;
            justify-content: flex-end;
            font-family: Montserrat;
            color: var(--placeholder-color);
            text-align: right;
        }

        .input-code {
            margin: 10px 0px;
        }
    }
    .modal-edit-footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 100px;
    }
}
.code-info {
    background-color: var(--panels-blue);
    justify-content: space-between;
    padding: 38px 31px;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 6px;

    @media screen and (max-width: 767px){
        padding: 31px 20px;

        .affiliate-code-container {
            width: 100%;
        }
    }

    .edit-button {
        width: 86.23px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        font-family: Montserrat;
        font-weight: 600;
        margin: 10px 0;
    }

    .affiliate-code {
        display: block;
        border: 2px solid var(--secondary-text-color);
        padding: 19px 17px;
        border-radius: 8px;
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 700;

        @media screen and (max-width: 767px){
            margin: auto;
            width: fit-content;
            text-align: center;
            margin-bottom: 30px;
        }
    }

    .affiliate-field {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 10px 0;

        .affiliate-label {
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 15px;
            font-weight: 600;

            @media screen and (max-width: 767px){
                font-size: 12px;
            }
        }

        .affiliate-value {
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 15px;
            font-weight: normal;
            background-color: var(--light-color);
            width: 124px;
            height: 23px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            border-radius: 11.5px;
            margin-left: -10px;


            @media screen and (max-width: 767px){
                width: 100px;
            }
        }
    }
}
.code-panel {
    margin-top: 104px;
    margin-bottom: 20px;
}
.promoter-area-section-1{
    display: flex;
    gap:19px;
    margin-bottom:19px;

    @media (min-width:1200px) {
        .generate-promo-code-form{
            width:calc(34.46% - 9.5px);
        }
        .revenue-chart-wrap{
            width:calc(65.54% - 9.5px);
        }
    }

    @media (max-width:1199px) {
        gap:28px;
        flex-direction: column;
        margin-bottom:28px;

        .generate-promo-code-form{
            width:100%;
        }
        .revenue-chart-wrap{
            width:100%;
        }
    }
}
.generate-promo-code-form-description{
    color: val(--secondary-text-color);
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top:-21px;
}
.generate-promo-code-form-section-1{
    display:flex;
    gap:10px;
    width:100%;
    margin-top:49px;
}
.promo-code-stage{
    width:100%;
    height: 50px;
    border-radius: 12px;
    border: 1.5px solid var(--light-primary-color);
    color:var(--secondary-text-color);
    text-align:center;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:60px;
    margin-bottom:17px;
}
.generate-promo-code-form{
    ::v-deep .generate-promo-code-form {
        padding-left: 18px;
        padding-right: 18px;
        @media (max-width:767px) {
            padding:0 17px;
        }
    }

}
.btn-dots{
    position:absolute;
    top:6px;
    right: 16px;
}
.info-button{
    margin-top:29px;
    min-width: 115px;
}

.divider {
    margin: 30px 0px;
    border-bottom: 1px solid #d0d0d0;
}
</style>