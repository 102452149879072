<script setup>
import { reactive, inject } from 'vue';
import VueApexCharts from "vue3-apexcharts";
import CardComponent from '../CardComponent.vue';
import { useScreenSizeBehaviours } from '@/composables/useScreenSizeBehaviours';
import ButtonComponent from '../ButtonComponent.vue';
import SvgComponent from '../SvgComponent.vue';

const { isMobileSize } = useScreenSizeBehaviours()
const auth = inject('auth')

const data = reactive({
    //TODO: estes dados não podem estar hardcoded
    series: [25, 35, 40],
    chartOptions: {
        labels: ['Scheduled', 'Canceled', 'Total'],
        colors: ['#2DC6F1', '#1C88D7', '#0330AD'],
        chart: {
            type: 'donut',
            width: 380,
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            position: 'right',
            offSetY: 0,
            height: 230
        },
    }
})
</script>

<template>
    <CardComponent>
        <template v-slot:header>
            <header>
                <h1 class="card-title">BOOKING HISTORY</h1>
                <ButtonComponent v-if="auth.company.company_type === 'TRANSFERS'" bordered color="secondary">
                    Transfers
                </ButtonComponent>
                <ButtonComponent v-else class="btn-dropdown d-flex align-items-center" bordered color="secondary">
                    <span>Tours</span>
                    <SvgComponent name="IconArrowDown" />
                </ButtonComponent>
            </header>
        </template>
        <div id="bookingHistoryChart">
            <VueApexCharts id="chart" type="donut" :options="data.chartOptions" :series="data.series"></VueApexCharts>
        </div>
    </CardComponent>
</template>

<style lang="scss" scoped>

::v-deep main {
    padding-bottom: 5px;
}

#bookingHistoryChart {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 204px;

    #chart {
        width: 100%;
    }

    
}
::v-deep header .btn-dropdown {
    border-color: var(--primary-icon-color-30);
    padding: 15.7px 11.5px;

    span {
        margin-right: 21.03px;
    }
}
</style>