export default {
    code: 'en',
    buttonText: {
        prev: 'Prev',
        next: 'Next',
        prevYear: 'PrevYear',
        nextYear: 'NextYear',
        year: 'Year',
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        list: 'List',
    }
}