<script setup>
import { reactive, ref, onMounted, computed, nextTick } from 'vue';
import VueApexCharts from "vue3-apexcharts";
import ToggleCardComponent from '../ToggleCardComponent.vue';
import { useScreenSizeBehaviours } from '@/composables/useScreenSizeBehaviours';

const { isMobileSize } = useScreenSizeBehaviours()


const props = defineProps({
    values: Array
})

const series = computed(() => {
    return [
        Number(props.values[0] * 100 / props.values[2]).toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits: 2 }),
        Number(props.values[1] * 100 / props.values[2]).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
        Number(100).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
    ]
})

const data = reactive({
    series: series.value,
    chartOptions: {
        chart: {
            type: 'radialBar',
            height: 150,
            offsetY: 0,
            offsetX: 0,
        },
        labels: ['Scheduled', 'Canceled', 'Total'],
        colors: ['#2FC6F1', '#1989D5', '#0030AC'],
        legend: {
            fontSize: '16px',
            show: false,
        },

        plotOptions: {
            radialBar: {
                // inverseOrder: false,
                hollow: {
                    margin: 0,
                    size: '35%',
                    background: 'transparent',
                },
                track: {
                    show: true,
                    background: '#e1e5ff',
                    strokeWidth: '10%',
                    opacity: 1,
                    margin: 15, // margin is in pixels
                },
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    offsetY: 0,
                    offsetX: 0
                },
                legend: {
                    position: 'bottom',
                    offsetX: 0,
                    offsetY: 0
                }
            }
        }],

        fill: {
            opacity: 1
        },
    }
})
</script>

<template>
    <ToggleCardComponent title="BOOKED SERVICES" :toggable="isMobileSize">
        <div class="total">
            <div>
                <h2>Total</h2>
                <h1>{{ values[2] }}</h1>
            </div>
        </div>
        <div class="main-info">
            <div id="bookedServicesChart">
                <VueApexCharts id="chart" :options="data.chartOptions" :series="data.series">
                </VueApexCharts>
            </div>
            <div class="labels">
                <div>
                    <h2>Scheduled</h2>
                    <h1>{{ Number(Number(values[0]) * 100 / Number(values[2])).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2}) }}%</h1>
                </div>
                <div>
                    <h2>Canceled</h2>
                    <h1>{{ Number(Number(values[1]) * 100 / Number(values[2])).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2}) }}%</h1>
                </div>
                <div>
                    <h2>Total</h2>
                    <h1>100%</h1>
                </div>
            </div>
        </div>
    </ToggleCardComponent>
</template>

<style lang="scss" scoped>
::v-deep main {
    padding-bottom: 22px;
}

#bookedServicesChart {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}
.main-info {
    @media screen and (min-width: 992px) {
        display: flex;

        #bookedServicesChart {
            flex: 2;
        }

        .labels {
            flex-direction: column;
            flex: 1;

            div {
                padding-left: 63px;
                margin-bottom: 30px;
                justify-content: flex-start;

                &:before {
                    width: 49px;
                    height: 49px;
                }
            }

            h2 {
                color: var(--grey-text-color);
                font-size: 18px;
                font-weight: 600;
            }

            h1 {
                color: var(--dark-text-color);
                font-size: 36px;
                font-weight: 600;
            }
        }   
    }
}

.total {
    display: flex;
    max-width: 320px;
    margin: auto 17px;
    flex-wrap: wrap;


    div {
        display: flex;
        flex: 1;
        position: relative;
        height: 39px;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 21px;

        h2 {
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0;
        }

        h1 {
            color: var(--primary-text-color);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
        }
    }
}
.labels {
    display: flex;
    max-width: 320px;
    margin: auto 29px;
    flex-wrap: wrap;


    div {
        display: flex;
        flex: 1;
        padding-left: 49px;
        position: relative;
        height: 39px;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 28px;

        &:before {
            position: absolute;
            width: 40px;
            height: 40px;
            content: "";
            display: block;
            background-color: var(--medium-blue);
            border-radius: 12px;
            left: 0;
        }

        &:nth-child(2) {
            &:before {
                background-color: var(--primary-color);
            }
        }

        &:nth-child(3) {
            &:before {
                background-color: var(--dark-blue);
            }
        }

        h2 {
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0;
        }

        h1 {
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
        }
    }
}
</style>