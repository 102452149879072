import ApiManager from "@/helpers/ApiManager"

const state = {
    customers: [],
    companies: [],
    services: [],
    selectedService: {},
}

const getters = {
    getCustomers: state => {
        return state.customers.reduce((result, customer) => {
            result[customer.id] = `${customer.first_name} ${customer.last_name} | User Id: ${customer.id}`
            return result
        }, {})
    },
    getCompanies: state => {
        return state.companies.reduce((result, partner) => {
            result[partner.id] = `${partner.trade_name} | Partner Id: ${partner.id}`
            return result
        }, {})
    },
    getServices: state => {
        return state.services.reduce((result, service) => {
            result[service.id] = `${service.service_name} | Service Id: ${service.id}`
            return result
        }, {})
    },
    getSelectedService: state => state.selectedService,
}

const actions = {
    fetchCustomers: async ({ commit }) => {
        const getAttempt = await ApiManager.get('api/super/customers')

        if (getAttempt) {
            commit('SET_CUSTOMERS', getAttempt.data.content)
            return true
        }

        return false
    },
    fetchCompanies: async ({ commit }) => {
        const getAttempt = await ApiManager.get('api/super/companies')

        if (getAttempt) {
            commit('SET_COMPANIES', getAttempt.data.content)
            return true
        }

        return false
    },
    fetchServices: async ({ commit }, companyId) => {
        const getAttempt = await ApiManager.get(`api/super/companies/${companyId}/services`)

        if (getAttempt) {
            commit('SET_SERVICES', getAttempt.data.content)
            return true
        }

        return false
    },
    fetchSelectedService: async ({ commit }, serviceId) => {
        const getAttempt = await ApiManager.get(`api/super/services/${serviceId}`)

        if (getAttempt) {
            commit('SET_SELECTED_SERVICE', getAttempt.data.content)
            return true
        }

        return false
    },
    resetSelectedService: ({ commit }) => {
        commit('SET_SELECTED_SERVICE', {})

        return true
    },
    processPayment: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            let payloadData = {
                payment_method: 'GIFT',
                ...payload
            }

            ApiManager.post('api/super/manual-bookings', payloadData, null, 'Operation could not be completed. Please try again or change your payment method.').then(response => {
                if (response.data && !response.data.status) {
                    alert("Operation could not be completed. Please try again or change your payment method.")
                    return reject(response)
                }
                
                return resolve(response)
            })
        })
    },
}

const mutations = {
    SET_CUSTOMERS: (state, payload) => {
        state.customers = payload
    },
    SET_COMPANIES: (state, payload) => {
        state.companies = payload
    },
    SET_SERVICES: (state, payload) => {
        state.services = payload
    },
    SET_SELECTED_SERVICE: (state, payload) => {
        state.selectedService = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}