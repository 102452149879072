import ApiManager from '@/helpers/ApiManager.js'

const getDefaultState = () => ({
    chats: [],
    currentChat: {},
    request_status: 'closed'
})

const state = getDefaultState()

const getters = {
    // getChatList: state => state.chats,
    getChatList: state => {
        return state.chats.sort((roomA, roomB) => {
            const latestMessageA = roomA.messages.length > 0 ? roomA.messages[roomA.messages.length - 1].id : null;
            const latestMessageB = roomB.messages.length > 0 ? roomB.messages[roomB.messages.length - 1].id : null;
            
            if (latestMessageA && latestMessageB) {
                return latestMessageB - latestMessageA;
            } else if (latestMessageA) {
                return -1;
            } else if (latestMessageB) {
                return 1;
            }
            return 0;
        });
    },
    getCurrentChat: state => state.currentChat,
    getMessages: (state) => state.currentChat.messages ?? [],
    canMakeRequest: (state) => state.request_status === 'closed',
    getDefaultChatRoom: (state, getters) => {
        return getters.getChatList[0]?.id ?? null
    }
}

const actions = {
    createChatRoom: ({ dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            ApiManager.post(`api/chat/create`, {user: payload}).then(response => {
                const data = response.data
                if (data.status) {
                    dispatch('loadChatRooms')
                    return resolve(data)
                }
                reject(data)
            })
        })
    },
    loadChatRooms: ({ commit, getters }) => {
        return new Promise((resolve, reject) => {
            if(getters.canMakeRequest) {
                commit('START_REQUEST')
                ApiManager.get('api/chat/list').then(response => {
                    const data = response?.data
                    if (data && data.status) {
                        commit('LOAD_CHAT_ROOMS', data)
                        commit('FINISH_REQUEST')
                        return resolve(data)
                    }
                    commit('FINISH_REQUEST')
                    return reject(data)
                })
            } else {
                return resolve([])
            }
        })
    },
    initChat: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            ApiManager.get(`api/chat/${payload}`).then(response => {
                const data = response.data
                if (data.status) {
                    commit('INIT_CHAT', data)
                    resolve(data)
                }
                reject(data)
            })
        })
    },
    redirect: ({ dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            ApiManager.post(`api/chat/${payload.chat}/redirect`, payload).then(response => {
                const data = response.data
                if (data.status) {
                    resolve(data)
                    dispatch('loadChatRooms')
                }
                reject(data)
            })
        })
    },
    updateChat: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            ApiManager.get(`api/chat/${payload}`).then(response => {
                const data = response.data
                if (data.status) {
                    commit('UPDATE_CHAT', data)
                    resolve(data)
                }
                reject(data)
            })
        })
    },
    sendMessage: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            ApiManager.post('api/chat/message', payload).then(response => {
                const data = response.data
                if (data.status) {
                    // commit("ADD_MESSAGE", data)
                    return resolve(data)
                }
                return reject(data)
            })
        })
    },
    readMessages: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            ApiManager.post(`api/chat/${payload}/read`, payload).then(response => {
                const data = response.data
                if (data.status) {
                    commit('UPDATE_CHAT', data)
                    return resolve(data)
                }
                return reject(data)
            })
        })
    },
    setOnlineUsers: ({commit}, payload) => {
        commit('SET_ONLINE_USERS', payload)
    },
    userWentOnline: ({commit}, payload) => {
        commit('SET_USER_ONLINE', payload)
    },
    userWentOffline: ({commit}, payload) => {
        commit('SET_USER_OFFLINE', payload)
    },
    setUserTyping: ({commit}, payload) => {
        commit('SET_USER_TYPING', payload)
    },
}

const mutations = {
    LOAD_CHAT_ROOMS: (state, chatRooms) => {
        state.chats = chatRooms.content
    },
    INIT_CHAT: (state, chat) => {
        state.currentChat = chat.content
        state.chats.find(chat => chat.id === state.currentChat.id).messages = chat.content.messages
        state.currentChat.status = state.chats.find(chat => chat.id === state.currentChat.id).status ?? 'offline'
    },
    UPDATE_CHAT: (state, chat) => {
        let status = 'offline'
        if(chat.content.id === state.currentChat.id) {
            status = state.chats.find(stateChat => stateChat.id === chat.content.id).status
            state.currentChat = {...chat.content, status}
        }
        state.chats.find(stateChat => stateChat.id === chat.content.id).messages = chat.content.messages
    },
    ADD_MESSAGE: (state, message) => {
        message = message.content
        let messages = []
        if(message.chat_id === state.currentChat.id) {
            messages = state.currentChat?.messages ?? []
            messages.push(message)
            state.currentChat['messages'] = messages
        } else {
            messages = state.chats.find(chat => chat.id === message.chat_id).messages ?? []    
            messages.push(message)
        }
        state.chats.find(chat => chat.id === message.chat_id)['messages'] = messages
    },
    START_REQUEST: state => {
        state.request_status = 'open'
    },
    
    FINISH_REQUEST: state => {
        state.request_status = 'closed'
    },
    SET_ONLINE_USERS: (state, data) => {
        state.chats.forEach((chat, index) => {
            if(data.includes(chat.participant_1) || data.includes(chat.participant_2)) {
                state.chats[index]['status'] = 'online'
                if(chat.id === state.currentChat.id) {
                    state.currentChat['status'] = 'online'
                }
            } else {
                state.chats[index]['status'] = 'offline'
                if(chat.id === state.currentChat.id) {
                    state.currentChat['status'] = 'offline'
                }
            }
        })
    },
    SET_USER_ONLINE: (state, data) => {
        let chatIndex = state.chats.findIndex(chat => chat.participant_1  == data || chat.participant_2  == data)
        let userChat = state.chats[chatIndex]
        
        if(userChat) {
            state.chats[chatIndex]['status'] = 'online'
            if(userChat.id === state.currentChat.id) {
                state.currentChat['status'] = 'online'
            }
        }
    },
    SET_USER_OFFLINE: (state, data) => {
        let chatIndex = state.chats.findIndex(chat => chat.participant_1  == data || chat.participant_2  == data)
        let userChat = state.chats[chatIndex]
        
        if(userChat) {
            state.chats[chatIndex]['status'] = 'offline'
            if(userChat.id === state.currentChat.id) {
                state.currentChat['status'] = 'offline'
            }
        }
    },
    SET_USER_TYPING: (state, data) => {
        let chatIndex = state.chats.findIndex(chat => chat.participant_1  == data.user || chat.participant_2  == data.user)
        let userChat = state.chats[chatIndex]
        
        if(userChat) {
            state.chats[chatIndex]['typing'] = data.typing
            if(userChat.id === state.currentChat.id) {
                state.currentChat['typing'] = data.typing
            }
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}