<script setup>
// import libraries
import { computed, onMounted, ref, watch } from 'vue';

import SvgComponent from './SvgComponent.vue';

// Props
const props = defineProps({
    elevatorVisibleFloors: {type: Number, default:3},
    slidesLength: { type: Number, default:0}
})

const emit = defineEmits(['nextSlide'])

// consts, variables
const currentIndex = ref(0)
const elevatorElement = ref(null)
const stageHeight = ref(0)

// Computed

// const slidesLength = computed(() => {
//     if(!elevatorElement.value) return 0
//     return elevatorElement.value.children.length
// })

const currentIndexScrollTop = computed(() => {
    if(!elevatorElement.value) return 0

    let currentSlide = elevatorElement.value.getElementsByClassName(`the-item-${currentIndex.value}`)

    return currentSlide[0].offsetTop
})

const hasFullStage = computed(() => {
    return (props.slidesLength - (currentIndex.value + props.elevatorVisibleFloors) ) >= 0
})

const canClickNextArrow = computed(() => {
    let desiredIndex = currentIndex.value + props.elevatorVisibleFloors
    return desiredIndex < props.slidesLength
        && !isSlideVisible(desiredIndex)
})

const canClickPrevArrow = computed(() => {
    return currentIndex.value !== 0
})

const isElevator = computed(() => props.slidesLength > props.elevatorVisibleFloors)

// Methods
function onClickPrevArrow() {
    let desiredIndex = currentIndex.value - props.elevatorVisibleFloors
    if(desiredIndex >= 0) {
        currentIndex.value = desiredIndex
    } else {
        currentIndex.value = 0
    }
}

function onClickNextArrow() {
    if(canClickNextArrow.value) {
        currentIndex.value = currentIndex.value + props.elevatorVisibleFloors
        emit('nextSlide')
    }
}

function isSlideVisible(index) {
    return index > currentIndex.value && index < currentIndex.value + props.elevatorVisibleFloors
}

function performSlide() {
    elevatorElement.value.scrollTo({
        top: currentIndexScrollTop.value,
        left: 0,
        behavior: "smooth",
    })

    calcStageHeight()
}

function getVisibleItemsHeight() {
    let tempStageHeight = 0
    for (let i = 0; i < elevatorElement.value.children.length; i++) {
        if(
            i >= currentIndex.value && 
            i < currentIndex.value + props.elevatorVisibleFloors
        ) {
            tempStageHeight += elevatorElement.value?.children[i]?.offsetHeight
        }
    }
    
    return tempStageHeight
}

function calcStageHeight() {
    if(!elevatorElement.value || !elevatorElement.value.children){
        setTimeout(() => calcStageHeight, 100)
    } else if(hasFullStage.value) {
        stageHeight.value = getVisibleItemsHeight()
    } else {
        stageHeight.value =  elevatorElement.value.children[currentIndex.value]?.offsetHeight * props.elevatorVisibleFloors
    }
    
}

watch(currentIndex, (newVal) => {
    performSlide()
} )


window.addEventListener("load", () => {
    calcStageHeight()
});
window.addEventListener("resize", () => {
    calcStageHeight()
});
onMounted(() => {
    calcStageHeight()
})
</script>

<template>
    <div class="items-outter-wrap">
        <div 
            ref="elevatorElement"
            class="items-wrap" 
            :class="{'elevator-wrap':isElevator}"
            :style="{
                height: isElevator ? `${stageHeight}px` : 'auto'
            }"
            @mouseenter="onMounseEnterElevator"
            @mouseleave="onMounseLeaveElevator"
        >
            <slot></slot>
        </div>
        <template v-if="isElevator">    
            <button @click="onClickPrevArrow()" class="elevator-arrow prev-arrow" :disabled="!canClickPrevArrow"><SvgComponent name="SimpleTopArrow" /></button>
            <button @click="onClickNextArrow()" class="elevator-arrow next-arrow" :disabled="!canClickNextArrow"><SvgComponent name="SimpleBottomArrow" /></button>
        </template>
    </div>
</template>

<style lang="scss" scoped>

    .elevator-wrap{
        overflow:hidden;
        position: relative;
        transition:height 0.2s ease;
        
        ::v-deep{
            .the-item:last-child{
                margin-bottom:999px;
            }
        }
    }
    
    .elevator-arrow{
        width: 30px;
        height: 30px;
        background:#F3F4F7;
        position:absolute;
        right:0px;
        border-radius:100px;

        &.next-arrow{
            bottom:0px;
        }
        &.prev-arrow{
            top:7px;
            @media (max-width:767px) {
                top:24px;
            }
        }

        ::v-deep{
            svg{
                width:14px;
                height:8px;
                position:absolute;
                top:50%;
                left:50%;
                transform:translate(-50%, -50%);
            }
        }

        &:disabled{
            opacity:0.3;
        }
    }
    .items-outter-wrap{
        position: relative;
        padding-bottom:47px;
    }
</style>