<script setup>
import ButtonComponent from '@/components/ButtonComponent.vue'
import UserButtonComponent from '@/components/UserButtonComponent.vue'
import SvgComponent from '@/components/SvgComponent.vue'
import { useLoginForm } from '@/composables/useLoginForm'
import { useScreenSizeBehaviours } from '@/composables/useScreenSizeBehaviours'
import router from '@/router'

import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';
const { isMobileSize } = useScreenSizeBehaviours()

defineEmits(['startBoarding'])

const store = useStore()
const { logout } = useLoginForm()

const auth = inject('auth')

const showDropdown = ref(false)
const unreadMessages = computed(() => store.getters['user/getUnreadMessages'])

const profileRoute = computed(() => {
    if (auth.value.user_type === 'AFFILIATE') {
        return '/affiliates/profile'
    }

    return '/dashboard/profile'
})

function toggleSidebar() {
    store.commit('event/TOGGLE_SIDEBAR')
}

function toggleDropdown() {
    showDropdown.value = !showDropdown.value
}

function performLogout() {
    router.push({ name: "home" }).then(() => {
        logout()
    })
}
</script>

<template>
    <nav class="main-menu">
        <router-link class="see-logo" to="/">
            <img src="@/assets/images/logo_horiz_pt.png" alt="SeeCountries PT">
        </router-link>
        <div class="navbar" id="welcome">
            <div v-if="!isMobileSize && auth.user_type === 'SUPPLIER'" class="see-tutorial" @click="$emit('startBoarding')">
                <span>SEE</span><span>tutorial</span>
            </div>
            <router-link to="/user-panel/messages" custom v-slot={navigate}>
                <ButtonComponent color="light-primary" class="action-buttons" sm @click="navigate">
                    <SvgComponent name="IconChat" />
                </ButtonComponent>
                <span v-if="unreadMessages > 0" class="badge bagde-messages">{{ unreadMessages }}</span>
            </router-link>
            <ButtonComponent color="light-primary" class="action-buttons" sm>
                <SvgComponent name="IconNotification" />
            </ButtonComponent>
            <div class="user-info" v-if="auth">
                <span class="user-name" v-if="auth.company">{{ auth.company.trade_name }}</span>
                <span class="user-name" v-else>{{ auth.first_name }} {{ auth.last_name }}</span>
                <span class="user-type">{{ auth.user_type }}</span>
            </div>
            <UserButtonComponent :user="auth" @click="toggleDropdown" />
            <ButtonComponent class="menu-icon" color="transparent" sm simple :size="{ width: '20px' }" @click="toggleSidebar">
                <SvgComponent name="IconHamburger" width="18.49px" />
            </ButtonComponent>
        </div>
        <div v-if="showDropdown" class="dropdown-menu" @click="close">
            <router-link :to="profileRoute" class="dropdown-item" @click="toggleDropdown()">
                <img src="@/assets/images/user.png" alt="User Profile" />
                <span>PROFILE</span>
            </router-link>
            <div class="dropdown-item" @click="performLogout">
                <img src="@/assets/images/logout.png" alt="User Profile" />
                <span>LOGOUT</span>
            </div>
        </div>
    </nav>
</template>

<style lang="scss" scoped>
.main-menu {
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
        .see-logo {
            img{
                display: none;
            }
        }
    }

    .navbar {
        display: flex;
        align-items: center;
        gap: 11px;

        .user-info {
            display: none;
        }

        @media screen and (min-width: 768px) {
            gap: 15px;

            .user-button {
                width: 57.39px;
                height: 57.36px;
            }

            .menu-icon {
                display: none;
            }

            .action-buttons {
                width: 57.939px;
                height: 56.639px;

                ::v-deep svg {
                    width: 29.266px;
                    height: 29.266px;
                }
            }

            .see-tutorial {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 57.939px;
                height: 56.639px;
                padding: 10px;
                color: white;
                background-color: #5E5CD8;
                font-family: Montserrat;
                font-size: 12px;
                border-radius: 8px;
                cursor: pointer;
            }

            .user-info {
                display: flex;
                flex-direction: column;

                .user-name {
                    color: var(--primary-color);
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                .user-type {
                    text-transform: uppercase;
                    color: var(--secondary-text-color);
                    font-family: Montserrat;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-align: right;
                    margin-top: 5px;
                }

            }
        }
    }

    .dropdown-menu {
        position: fixed;
        top: 120px;
        right: 50px;
        width: 140px;
        border: 1px solid #f5f5f5;
        background-color: #ffffff;
        border-radius: 20px;
        text-align: center;
        font-family: Montserrat;
        padding: 10px 0;
        color: #999999;
    }

    .dropdown-item {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 10px;
        text-decoration: none;
        cursor: pointer;
    }

    .dropdown-item:visited {
        color: #999999;
    }

    .dropdown-item:hover {
        color: #000000;
        font-weight: 600;
    }
    
    @media screen and (max-width: 768px) {
        .dropdown-menu {
            top: 70px;
            font-size: 13px;
        }
    }

    .bagde-messages {
        position: absolute;
        top: 10px;
        margin-left: 40px;
        width: 25px;
        height: 25px;
        font-size: 14px;
    }
}
</style>