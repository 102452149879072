<script setup>
import { computed, reactive, inject } from 'vue'
import { useStore } from 'vuex'
import MenuItemComponent from './MenuItemComponent.vue'

const store = useStore()
const auth = inject('auth')

const activeUser = computed(() => store.getters['superAdm/getActiveUser'])

// const clientMenus = reactive([
//     {
//         text: 'MESSAGES',
//         icon: 'MenuIconMessages',
//         goTo: `/super-admin/client/${activeUser.value.id}/messages`,
//         exact: true,
//     },
//     {
//         text: 'BOOKINGS',
//         icon: 'MenuIconBookings',
//         goTo: `/super-admin/client/${activeUser.value.id}/bookings`,
//         exact: true,
//     },
//     {
//         text: 'PROFILE',
//         icon: 'MenuIconProfile',
//         goTo: `/super-admin/client/${activeUser.value.id}/profile`,
//         exact: true,
//     },
//     {
//         text: 'SETTINGS',
//         icon: 'MenuIconSettings',
//         goTo: `/super-admin/client/${activeUser.value.id}/settings`,
//         exact: true,
//     },
//     {
//         text: 'HELP',
//         icon: 'MenuIconHelp',
//         goTo: `/super-admin/client/${activeUser.value.id}/help`,
//         exact: true,
//     },
// ])

const supplierMenus = reactive([
    {
        text: 'DASHBOARD',
        icon: 'IconDashboard',
        goTo: '/dashboard',
        exact: true,
    },
    {
        text: 'CALENDAR & BOOKINGS',
        icon: 'IconCalendarMenu',
        goTo: '/dashboard/calendar-and-bookings',
    },
    {
        text: 'EXPERIENCES',
        icon: 'IconServicesMenu',
        goTo: '/dashboard/services/preview',
        availableForCompanyType: "EXPERIENCES"
    },
    {
        text: 'TRANSFERS',
        icon: 'IconServicesMenu',
        goTo: '/dashboard/vehicles/preview',
        availableForCompanyType: "TRANSFERS"
    },
    {
        text: 'SALES',
        icon: 'IconSalesMenu',
        goTo: '/dashboard/sales',
    },
    {
        text: 'AFFILIATE AREA',
        icon: 'IconPromoterArea',
        goTo: '/dashboard/promoter-area',
    },
    {
        text: 'PROFILE',
        icon: 'IconProfile',
        goTo: '/dashboard/profile?impersonate=true',
    },
])

const affiliateMenus = reactive([
    {
        text: 'DASHBOARD',
        icon: 'IconDashboard',
        goTo: '/affiliates/dashboard',
        exact: true,
    },
    {
        text: 'AFFILIATE AREA',
        icon: 'IconPromoterArea',
        goTo: '/affiliates',
    },
    {
        text: 'PROFILE',
        icon: 'IconProfile',
        goTo: '/dashboard/profile?impersonate=true',
    },
])

const superMenus = reactive([
    {
        text: 'DASHBOARD',
        icon: 'IconDashboard',
        goTo: '/super-admin/dashboard',
        exact: true,
        roles: ['super-admin', 'finance'],
    },
    {
        text: 'BOOKING & QUOTES',
        icon: 'IconCalendarMenu',
        goTo: '/super-admin/bookings-and-quotes',
        roles: ['super-admin', 'partner-department', 'customer-service'],
    },
    {
        text: 'SERVICES',
        icon: 'IconServicesMenu',
        goTo: '/super-admin/services',
        roles: ['super-admin', 'partner-department', 'affiliate-department'],
    },
    {
        text: 'PARTNER MANAGEMENT',
        icon: 'IconSalesMenu',
        goTo: '/super-admin/partners',
        roles: ['super-admin', 'partner-department'],
    },
    {
        text: 'AFFILIATE MANAGEMENT',
        icon: 'IconSalesMenu',
        goTo: '/super-admin/affiliates',
        roles: ['super-admin', 'affiliate-department'],
    },
    {
        text: 'CLIENT MANAGEMENT',
        icon: 'IconSalesMenu',
        goTo: '/super-admin/customers',
        roles: ['super-admin', 'customer-service'],
    },
    {
        text: 'CODE MANAGEMENT',
        icon: 'IconPromoterArea',
        goTo: '/super-admin/promo-codes',
        roles: ['super-admin', 'partner-department', 'affiliate-department'],
    },
    {
        text: 'SALES',
        icon: 'IconSalesMenu2',
        goTo: '/super-admin/sales',
        roles: ['super-admin', 'finance'],
    },
    {
        text: 'WEBSITE MANAGEMENT',
        icon: 'IconWebsiteMenu',
        goTo: '/super-admin/website',
        roles: ['super-admin'],
    },
    {
        text: 'TEAM MANAGEMENT',
        icon: 'IconTeamMenu',
        goTo: '/super-admin/team',
        roles: ['super-admin'],
    },
])

const currentMenu = computed(() => {
    if (activeUser.value.user_type === 'CLIENT') {
        return clientMenus
    }

    if (activeUser.value.user_type === 'AFFILIATE') {
        return affiliateMenus
    }

    if (activeUser.value.user_type === 'SUPPLIER') {
        return supplierMenus
    }

    return superMenus
})

function canShow(menuItem) {
    if (menuItem.roles && ! menuItem.roles.includes(auth.value?.role?.role_slug)) {
        return false;
    }

    if (menuItem.availableForCompanyType) {
        const modules = JSON.parse(activeUser.value?.company?.modules ?? '{}')
       
        if (menuItem.availableForCompanyType === 'EXPERIENCES') {
            return modules.experiences == 1;
        }
    
        if (menuItem.availableForCompanyType === 'TRANSFERS') {
            return modules.transfers == 1;
        }
    }

    return true;
}
</script>

<template>
    <div class="menu-container">
        <template v-for="(menuItem, index) of currentMenu" :key="index">
            <router-link v-if="canShow(menuItem)" :to="menuItem.goTo" custom v-slot="{isExactActive, isActive, navigate}">
                <MenuItemComponent :text="menuItem.text" :icon="menuItem.icon" :active="menuItem.exact ? isExactActive : isActive" @click="navigate" />
            </router-link>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.menu-container {
    ::v-deep {
        button {
            margin-bottom: 13.5px;
        }
    }

    &.toggled {
        ::v-deep {
            button {
                padding: 8px;
                margin-top: 13.5px;
                margin-bottom: 13.5px;

                span {
                    display: none;
                }
            }

            .menu-arrow {
                display: none;
            }
        }
    }
}
</style>