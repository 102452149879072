<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import Tooltip from '@/components/Tooltip.vue'

const emit = defineEmits(['update:modelValue']);

// Props
const props = defineProps({
    label: {type: String, default: ''},
    modelValue: {type: Number, default: null},
    placeholder: {type: String, default: ''},
    required: { type: Boolean, default: false},
    errors: { type: Array, default: [] },
    disabled: { type: Boolean, default: false },
    min: {type: Number, default: 0},
    max: {type: Number, default: 10000},
    tooltip: {type:String, default: null},
})

// reactive Variables
const text = ref(props.modelValue);
const fieldErrors = computed(() => props.errors)

// Methods
function onInput() {
    emit('update:modelValue', text.value);
}

watch(() => props.modelValue, (newValue) => {
    text.value = newValue
})
</script>

<template>
    <div class="input-field-wrapper" :class="{'is-disabled': disabled}">
        <div class="label-wrap">
            <label v-if="label">{{ label }} {{ required ? '*' : '' }}</label>
            <Tooltip class="tooltip-component" v-if="tooltip">{{ tooltip }}</Tooltip>
        </div>
        <input :disabled="disabled" type="number" v-model="text" @input="onInput()" :placeholder="placeholder" :required="required" :class="{ 'is-invalid': fieldErrors.length }" :min="min" :max="max"/>
        <div class="invalid-feedback" v-if="fieldErrors.length">
            <ul>
                <li v-html="fieldErrors.join('</li><li>')"></li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.label-wrap{
    width:100%;
    display:flex;
    align-items:center;
}

.tooltip-component{
    margin-bottom:6px;
    margin-left:6px;
}
</style>