<script setup>
// import libraries
import { computed, ref } from 'vue'
import { RouterLink } from 'vue-router';
import ButtonComponent from './ButtonComponent.vue';
import store from '@/store';
import RatingStars from './RatingStars.vue';
import { convertDateFormat, durationFormat, longDate} from '@/composables/dateFormat.js'
import { useChat } from '@/composables/useChat'
import { generateCancelationPolicy } from '@/composables/useService.js'
import { priceFormat } from '@/composables/helper'

// custom imports
import SvgComponent from '@/components/SvgComponent.vue'

// consts (props, otherConstants)
const props = defineProps({
    booking: Object,
    type: { type: [String, null], default: null}
})
const { createChatRoom } = useChat()
const emit = defineEmits(['onClick'])

const isChecked = ref(false)

const isNew = computed(() => props.booking.status === 'pending')

const servicdData = computed(() => props.booking.servicdData ? props.booking.servicdData : null)

const isTransfer = computed(() => servicdData.value && servicdData.value.vehicle_id)

const allCats = computed(() => store.getters['frontend/getServiceCategories'])

const serviceCat = computed(() => {
    if (
        servicdData.value && 
        servicdData.value.category_id &&
        allCats.value &&
        allCats.value[servicdData.value.category_id]
    ) {
        return allCats.value[servicdData.value.category_id]
    }

    return null
})

const parentCat = computed(() => {
    if(serviceCat.value && allCats.value[serviceCat.value.parent_id]) {
        return allCats.value[serviceCat.value.parent_id]
    }
    return null
})

const pickUpLocation = computed(() => {
    if (
        props.booking.details &&
        props.booking.details.pickupLocation
    ) {
        return props.booking.details.pickupLocation
    }

    return null
})

const dropOffLocation = computed(() => {

    if (
        props.booking.details &&
        props.booking.details.dropOffLocation
    ) {
        return props.booking.details.dropOffLocation
    }

    return null
})

function onClick(value) {
    emit('onClick', { status: value, booking: props.booking.id })
}

function onClickDetals() {
    store.dispatch('booking/fetchBookingHistory', props.booking.user.id)
}

function getFormattedSeats(seats) {
    return seats.map(seat => {
        if (seat.qty > 0)
            return `${seat.name}: ${seat.qty}`;
    }).join(', ')
}

function kidsSeatsCopy(selectedSeats) {
    let copyArr = [];
    if (selectedSeats[0].qty > 0) {
        copyArr.push("Baby seats: " + selectedSeats[0].qty)
    }

    if (selectedSeats[1].qty > 0) {
        copyArr.push("Child Seats: " + selectedSeats[1].qty)
    }

    if (selectedSeats[2].qty > 0) {
        copyArr.push("Booster seats: " + selectedSeats[2].qty)
    }

    return copyArr.join(" | ")
}
</script>

<template>
    <main 
        class="service-item-component-wrap" 
        :class="booking.pendingHours > 48 ? 'back-after-48-hours' : (booking.pendingHours > 24 ? 'back-after-24-hours' : '')"
    >
        <div class="service-item-desktop">
            <h1>ID: {{ booking.id }}</h1>
            <div class="service-item-desktop-inner-wrap">
                <div class="section-a">
                    <div class="picture-wrap">                        
                        <label class="checkbox">
                            <input type="checkbox" v-model="isChecked" />
                            <span></span>
                        </label>
                        <div class="user-avatar" :class="{ new: booking.status === 'pending' }">
                            <img :src="booking.user.avatar" :class="{ new: booking.status === 'pending' }" v-if="type !== 'history'" />
                        </div>
                    </div>
                    <div class="chat-button-wrap">
                        <a class="chat-button" @click="createChatRoom(booking.user.id)">
                            <SvgComponent class="chat-icon" name="MenuIconMessages" />
                            <span>Chat now</span>
                        </a>
                    </div>
                    <div class="historic-button-wrap">
                        <span class="historic-button" @click="onClickDetals" v-if="type != 'history'">Historic</span>
                    </div>
                </div>
                <div class="section-b">
                    <div class="section-b-line-1">
                        <h2 class="customer-name">{{ booking.user.name }}</h2>
                        <RatingStars :rating="booking.rating" />
                        <h2 class="service-price">{{ priceFormat(booking.value - Number(booking.discount)) }}</h2>
                    </div>
                    <p class="user-id">User ID: {{ booking.user.id }}</p>
                    <p class="service-title">{{ servicdData.service_name }}</p>
                    <p v-if="booking?.details?.selectedOption" class="service-selected-option">
                        <div class="dot"></div> {{ booking.details.selectedOption.title }}
                    </p>
                    <p class="booking-item-date">{{ longDate(booking.createdAt) }}</p>
                    <hr class="section-b-underline" :class="booking.pendingHours > 24 && 'section-b-underline-black'" />
                    <div class="info-grid" v-if="!isTransfer">
                        <div class="info-grid-item" v-if="booking.details.seats">
                            <SvgComponent name="IconCartUser" class="info-grid-item-icon" />
                            <span>
                                <strong>Participants:</strong><br>
                                {{ getFormattedSeats(booking.details.seats) }}
                            </span>
                        </div>
                        <div class="info-grid-item" v-if="booking.details.participants">
                            <SvgComponent name="IconCartUser" class="info-grid-item-icon" />
                            <span>
                                <strong>Participants:</strong>
                                <br>
                                {{ booking.details.participants }}
                            </span>
                        </div>
                        <div class="info-grid-item" v-if="booking.details.language">
                            <SvgComponent name="IconCartLanguages" class="info-grid-item-icon" />
                            <span>
                                <strong>Language:</strong>
                                <br>
                                {{ booking.details.language }}
                            </span>
                        </div>
                        <div class="info-grid-item">
                            <SvgComponent name="IconCartCalendar" class="info-grid-item-icon" />
                            <span>
                                <strong>Booking Date:</strong>
                                <br>
                                {{ booking.details.date }}h
                            </span>
                        </div>
                        <div class="info-grid-item">
                            <SvgComponent name="IconCartCheck" class="info-grid-item-icon" />
                            <span>
                                <strong>Free cancellation until:</strong>
                                <br>
                                {{ longDate(booking.freeCancelation) }}
                            </span>
                        </div>
                    </div>
                    <div class="info-grid" v-if="isTransfer">
                        <div class="info-grid-item" v-if="pickUpLocation">
                            <SvgComponent name="location-cart" class="info-grid-item-icon" />
                            <span>
                                <strong>Pick Up Location:</strong>
                                <br>
                                {{  pickUpLocation }}
                            </span>
                        </div>
                        <div class="info-grid-item" v-if="dropOffLocation">
                            <SvgComponent name="locationPin2" class="info-grid-item-icon" />
                            <span>
                                <strong>Drop Off Location:</strong>
                                <br>
                                {{  dropOffLocation }}
                            </span>
                        </div>
                        <div class="info-grid-item" v-if="booking.details.pickupDate">
                            <SvgComponent name="IconCartCalendar" class="info-grid-item-icon" />
                            <span>
                                <strong>Arrival Pick Up Date:</strong>
                                <br>
                                {{ convertDateFormat(booking.details.pickupDate) }}&nbsp;&nbsp;
                                <template v-if="booking.details.pickupTime">
                                    {{ durationFormat(booking.details.pickupTime) }}h
                                </template>
                            </span>
                        </div>
                        <div class="info-grid-item" v-if="booking.details.returnPickupDate">
                            <SvgComponent name="calendar-filled" class="info-grid-item-icon" />
                            <span>
                                <strong>Return Pick Up Date:</strong>
                                <br>
                                {{ convertDateFormat(booking.details.returnPickupDate) }}&nbsp;&nbsp;
                                <template v-if="booking.details.returnPickupTime">
                                    {{ durationFormat(booking.details.returnPickupTime) }}h
                                </template>
                            </span>
                        </div>
                    </div>
                    <hr class="section-b-underline" :class="booking.pendingHours > 24 && 'section-b-underline-black'">
                    <div class="info-grid" v-if="isTransfer">
                        <div class="info-grid-item" v-if="booking.details.numberOfPassengers">
                            <SvgComponent name="IconCartUser" class="info-grid-item-icon" />
                            <span>
                                <strong>Passengers:</strong>
                                <br>
                                x{{  booking.details.numberOfPassengers }}
                            </span>
                        </div>
                        <div class="info-grid-item" v-if="booking.details.selectedLanguage">
                            <SvgComponent name="IconCartLanguages" class="info-grid-item-icon" />
                            <span>
                                <strong>Language:</strong>
                                <br>
                                {{ booking.details.selectedLanguage }}
                            </span>
                        </div>
                        <div class="info-grid-item" v-if="booking.details.luggagePieces">
                            <SvgComponent name="cart-luggage" class="info-grid-item-icon" />
                            <span>
                                <strong>Luggage Pieces:</strong>
                                <br>
                                {{  booking.details.luggagePieces }}
                            </span>
                        </div>
                        <div class="info-grid-item" v-if="booking.freeCancelation">
                            <SvgComponent name="IconCartCheck" class="info-grid-item-icon" />
                            <span>
                                <strong>Free cancellation until:</strong>
                                <br>
                                {{ longDate(booking.freeCancelation) }}
                            </span>
                        </div>
                        <div class="info-grid-item" v-if="kidsSeatsCopy(booking.details.selectedSeats)">
                            <SvgComponent name="babySeat" class="info-grid-item-icon" />
                            <span>
                                <strong>Child Seats:</strong>
                                <br>
                                {{ kidsSeatsCopy(booking.details.selectedSeats) }}
                            </span>
                        </div>
                        
                    </div>
                </div>
                <div class="section-c">
                    <div class="section-c-line-1" :class="booking.pendingHours > 24 && 'section-c-line-1-black'">
                        <div class="cat-wrap" v-if="!isTransfer">
                            <p class="parent-cat" v-if="parentCat">{{parentCat.category_name}}</p>
                            <p class="cat" v-if="serviceCat">{{serviceCat.category_name}}</p>
                        </div>
                        <div class="cat-wrap" v-if="isTransfer">
                            <p class="parent-cat">Transfers</p>
                            <p class="cat">{{ servicdData.vehicle.car_type }}</p>
                            <p v-if="booking.details.isRoundTrip" class="cat">Round Trip</p>
                            <p v-else class="cat">One Way</p>
                        </div>
                        <div v-if="isNew" class="buttons-wrap">
                            <ButtonComponent color="primary" @click="onClick('confirmed')">ACCEPT</ButtonComponent>
                            <ButtonComponent class="btn-decline" bordered color="secondary" @click="onClick('declined')">DECLINE</ButtonComponent>
                        </div>
                        <div v-else class="buttons-wrap">
                            <span :class="`status ${booking.status.toLowerCase()}`">{{ booking.status.toUpperCase() }}</span>
                        </div>
                    </div>
                    <div class="notes-wrap" v-if="booking.notes">
                        <div class="notes">
                            {{ booking.notes }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="service-item-mobile">
            <label class="checkbox">
                <input type="checkbox" v-model="isChecked" />
                <span></span>
            </label>
            <h1>ID: {{ booking.id }}</h1>
            
            <div class="mobile-user-info">
                <div class="section-g">
                    <div class="mobile-picture-wrap">
                        <div class="mobile-user-avatar" :class="{ new: booking.status === 'pending' }">
                            <img :src="booking.user.avatar" v-if="type !== 'history'" />
                        </div>
                    </div>
                </div>
                <div class="section-h">
                    <h2 class="mobile-customer-name">{{ booking.user.name }}</h2>
                    <p class="mobile-user-id">User ID: {{ booking.user.id }}</p>
                    <RatingStars :rating="booking.rating" />
                    <div class="section-h-line-4">
                        
                        <a class="mobile-chat-button" @click="createChatRoom(booking.user.id)">
                            <SvgComponent class="mobile-chat-icon" name="MenuIconMessages" />
                            <span>Chat now</span>
                        </a>
                        
                        
                        <span class="mobile-historic-button" @click="onClickDetals" v-if="type != 'history'">Historic</span>
                        
                    </div>
                </div>
            </div>
            
            <p class="mobile-service-title">{{ servicdData.service_name }}</p>
            <p class="mobile-booking-item-date">{{ longDate(booking.createdAt) }}</p>
            
            <hr class="mobile-section-underline" />

            <div class="mobile-info-grid" v-if="!isTransfer">
                <div class="mobile-info-grid-item" v-if="booking.details.seats">
                    <SvgComponent name="IconCartUser" class="mobile-info-grid-item-icon" />
                    <span>
                        <strong>Participants:</strong><br>
                        {{ getFormattedSeats(booking.details.seats) }}
                    </span>
                </div>
                <div class="mobile-info-grid-item" v-if="booking.details.participants">
                    <SvgComponent name="IconCartUser" class="mobile-info-grid-item-icon" />
                    <span>
                        <strong>Participants:</strong>
                        <br>
                        {{ booking.details.participants }}
                    </span>
                </div>
                <div class="mobile-info-grid-item" v-if="booking.details.language">
                    <SvgComponent name="IconCartLanguages" class="mobile-info-grid-item-icon" />
                    <span>
                        <strong>Language:</strong>
                        <br>
                        {{ booking.details.language }}
                    </span>
                </div>
                <div class="mobile-info-grid-item">
                    <SvgComponent name="IconCartCalendar" class="mobile-info-grid-item-icon" />
                    <span>
                        <strong>Booking Date:</strong>
                        <br>
                        {{ booking.details.date }}h
                    </span>
                </div>
                <div class="mobile-info-grid-item">
                    <SvgComponent name="IconCartCheck" class="mobile-info-grid-item-icon" />
                    <span>
                        <strong>Free cancellation until:</strong>
                        <br>
                        {{ longDate(booking.freeCancelation) }}
                    </span>
                </div>
            </div>

            <div class="mobile-info-grid" v-if="isTransfer">
                <div class="mobile-info-grid-item" v-if="pickUpLocation">
                    <SvgComponent name="location-cart" class="mobile-info-grid-item-icon" />
                    <span>
                        <strong>Pick Up Location:</strong>
                        <br>
                        {{  pickUpLocation }}
                    </span>
                </div>
                <div class="mobile-info-grid-item" v-if="dropOffLocation">
                    <SvgComponent name="locationPin2" class="mobile-info-grid-item-icon" />
                    <span>
                        <strong>Drop Off Location:</strong>
                        <br>
                        {{  dropOffLocation }}
                    </span>
                </div>
                <div class="mobile-info-grid-item" v-if="booking.details.pickupDate">
                    <SvgComponent name="IconCartCalendar" class="mobile-info-grid-item-icon" />
                    <span>
                        <strong>Arrival Pick Up Date:</strong>
                        <br>
                        {{ convertDateFormat(booking.details.pickupDate) }}&nbsp;&nbsp;
                        <template v-if="booking.details.pickupTime">
                            {{ durationFormat(booking.details.pickupTime) }}h
                        </template>
                    </span>
                </div>
                <div class="mobile-info-grid-item" v-if="booking.details.returnPickupDate">
                    <SvgComponent name="calendar-filled" class="mobile-info-grid-item-icon" />
                    <span>
                        <strong>Return Pick Up Date:</strong>
                        <br>
                        {{ convertDateFormat(booking.details.returnPickupDate) }}&nbsp;&nbsp;
                        <template v-if="booking.details.returnPickupTime">
                            {{ durationFormat(booking.details.returnPickupTime) }}h
                        </template>
                    </span>
                </div>
                <div class="mobile-info-grid-item" v-if="booking.details.numberOfPassengers">
                    <SvgComponent name="IconCartUser" class="mobile-info-grid-item-icon" />
                    <span>
                        <strong>Passengers:</strong>
                        <br>
                        x{{  booking.details.numberOfPassengers }}
                    </span>
                </div>
                <div class="mobile-info-grid-item" v-if="booking.details.selectedLanguage">
                    <SvgComponent name="IconCartLanguages" class="mobile-info-grid-item-icon" />
                    <span>
                        <strong>Language:</strong>
                        <br>
                        {{  booking.details.selectedLanguage }}
                    </span>
                </div>
                <div class="mobile-info-grid-item" v-if="booking.details.luggagePieces">
                    <SvgComponent name="cart-luggage" class="mobile-info-grid-item-icon" />
                    <span>
                        <strong>Luggage Pieces:</strong>
                        <br>
                        {{  booking.details.luggagePieces }}
                    </span>
                </div>
                <div class="mobile-info-grid-item" v-if="booking.freeCancelation">
                    <SvgComponent name="IconCartCheck" class="mobile-info-grid-item-icon" />
                    <span>
                        <strong>Free cancellation until:</strong>
                        <br>
                        {{ longDate(booking.freeCancelation) }}
                    </span>
                </div>
                <div class="mobile-info-grid-item" v-if="kidsSeatsCopy(booking.details.selectedSeats)">
                    <SvgComponent name="babySeat" class="mobile-info-grid-item-icon" />
                    <span>
                        <strong>Child Seats:</strong>
                        <br>
                        {{ kidsSeatsCopy(booking.details.selectedSeats) }}
                    </span>
                </div>
            </div>

            <hr class="mobile-section-underline" :class="booking.pendingHours > 24 && 'section-b-underline-black'" />

            <div class="mobile-cat-wrap">
                <p class="mobile-parent-cat" v-if="parentCat">{{parentCat.category_name}}</p>
                <p class="mobile-cat" v-if="serviceCat">{{serviceCat.category_name}}</p>
            </div>
            
            <div class="mobile-notes" v-if="booking.notes">
                {{ booking.notes }}
            </div>
            
            <div v-if="isNew" class="mobile-buttons-wrap">
                <ButtonComponent color="primary" @click="onClick('confirmed')">ACCEPT</ButtonComponent>
                <ButtonComponent class="btn-decline" bordered color="secondary" @click="onClick('declined')">DECLINE</ButtonComponent>
            </div>
            <div v-else class="mobile-buttons-wrap">
                <span :class="`status ${booking.status.toLowerCase()}`">{{ booking.status.toUpperCase() }}</span>
            </div>
                        
        </div>
    </main>
</template>

<style lang="scss" scoped>
main {
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #ECF8FE;
    margin-left: 0;
    margin-right: 0;
    gap: 10px;
    @media (min-width: 768px) {
        gap: 53px;
    }

    @media (max-width: 767px) {
        .stats {
            margin-top: -25px;
        }
    }

    @media screen and (min-width: 1081px) and (max-width: 1157px) {
        gap: 15px;
    }

    @media screen and (max-width: 1081px) {
        flex-direction: column;
        align-items: flex-start;
    }
    
    &:last-child {
        padding-bottom: 24px;
    }
    
    .column-1 {
        @media screen and (max-width: 1081px) {
            flex-direction: column;
            width: 100%;
            align-items: flex-start;
            margin: 0;
        }
        @media  screen and (min-width: 1082px) {
            // min-width: 400px;
        }
        img {
            min-width: 89px;
            max-height: 89px;
            border-radius: 12px;
            object-fit: cover;
            object-position: center;
    
            &.new {
                border: 3px solid #FC6030;
            }

            &:not(.new) {
                @media(min-width: 1082px) {
                    margin-left: 31px;
                }
            }
        }
        gap: 15px;

        ul {
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;

            @media (min-width: 767px) {
                &.history {
                    margin-left: 141px;
                }
            }

            li {
                h1 {
                    color: var(--secondary-text-color);
                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin: 0 8px 0 0;
                    padding: 0;
                }

                h3 {
                    color: var(--secondary-text-color);
                    font-family: Montserrat;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding: 0;
                    margin: 0;
                }

                p {
                    font-family: Montserrat;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 0;
                    padding: 0;
                }

                &.row-1 {
                    gap: 25px;
                    margin-bottom: 10px;
                }

                &.row-2 {
                    margin-bottom: 9px;

                    span {
                        color: var(--secondary-text-color);
                        font-family: Montserrat;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }

                &.row-3 {
                    margin-bottom: 7px;

                    p {
                        min-width: 136px;
                    }

                    span {
                        color: var(--secondary-text-color);
                        font-family: Montserrat;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }

                &.row-4 {
                    width: 100%;

                    span {
                        color: var(--secondary-text-color);
                        font-family: Montserrat;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    button {
                        background-color: transparent;
                        color: var(--primary-color);
                        font-family: Montserrat;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-decoration-line: underline;
                        margin-right: 11px;
                    }
                }

                .mobile-direction-column {
                    @media screen and (max-width: 1081px) {
                        flex-direction: column;
    
                        h1 {
                            margin-bottom: 6.95px;
                        }
                    }
                }

            }

        }
    }

    .column-2 {
        div{ 
            padding-left: 16px;
            border-left: 1px solid var(--light-secondary-color);
            @media screen and (max-width: 1081px) {
                padding-left: 0;
                border-left: none;;
            }
        }

        h1 {
            color: var(--primary-color);
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0 0 5px 0;
        }

        p {
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0;
        }
    }

    .column-3 {
        gap: 10px;

        button {
            padding: 15.7px;

            @media screen and (max-width: 1081px) {
                flex: 1;
            }
        }
        .btn-edit {
            border-color: var(--primary-icon-color-30);
        }
        .btn-decline {
            border-color: var(--tertiary-color);
            color: var(--tertiary-color);
        }

        .status {
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            &.cancelled, &.declined {
                color: var(--tertiary-color);
            }
        }
    }

    .mobile-direction-column {
        h1 {
            min-width: 115.783px;
            margin-right: 6.31px;
        }
    }
}


.visible-md-block {
    @media screen and (min-width: 1082px) {
        display: none;
    }

    @media screen and (max-width: 1081px) {
        display: block;
    }
}

.visible-lg-block {
    @media screen and (max-width: 1801px) {
        display: none;
    }

    @media screen and (min-width: 1082px) {
        display: block;
    }
}


.transfer-parkour{
    color: #162659;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom:8px;
    display: block;
}
.transfer-cancelation{
    color: #162659;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    strong{
        color: #162659;
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
li.transfer-data{
    display:flex;
    gap:25px;

    div.transfer-data-b{
        p{
            color: #162659;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}
.transfer-data-b-paragraf{
    color: #162659;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.iconed-info-item{
    display:flex;
    align-items: center;

    span{
        color: #162659;
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    ::v-deep{
        svg{
            width: 12px;
            height: 12px;
            margin-right:2px;
        }
    }
}


.service-item-desktop{
    display:block;
    width:100%;

    @media(max-width:767px){
        display:none;
    }

    h1{
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width:100%;
        padding-left:32px;
    }
    .service-item-desktop-inner-wrap{
        display:flex;
        width:100%;
        flex-wrap: wrap;
    }
    .picture-wrap{
        padding-left:30px;
        position:relative;
        margin-bottom:14px;

        .checkbox{
            top:50%;
            left:0;
            transform:translateY(-50%);
            position: absolute;
        }
    }
    .user-avatar{
        width:89px;
        height:89px;
        border-radius: 12px;
        overflow:hidden;

        &.new{
            border: 3px solid #FC6030;
        }

        img{
            width:100%;
            height:100%;
            object-fit: cover;
        }
    }
    .chat-button-wrap{
        padding-left:26px;
        margin-bottom:20px;
    }
    .chat-icon{
       width:16px;
       height:16px;
       margin-right:4px;
       ::v-deep{
           svg{
               width:100%;
               height:100%;
               margin-right:4px;
           }
       }
    }
    .chat-button{
        display:flex;
        align-items: center;
        cursor:pointer;
        span{
            color: var(--secondary-text-color);
            text-align: center;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-decoration-line: underline;
        }
    }
    .historic-button-wrap{
        width: 100%;
        text-align: right;
    }
    .historic-button{
        background-color: transparent;
        color: var(--primary-color);
        font-family: Montserrat;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        cursor:pointer;
    }
    .section-a{
        margin-right:15px;
    }
    .customer-name, .service-price {
        margin:0;
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .section-b-line-1 {
        display:flex;
        align-items: center;
        justify-content: space-between;
        gap: 25px;
        margin-right: 10px;
    }

    .section-b {
        width:calc(60% - 134px);

        @media(max-width:1199px){
            width:calc(100% - 134px);
        }
    }
    .section-c{
        width: 40%;
        @media(max-width:1199px){
            width:100%;
            padding-top:40px;
        }
    }
    .section-b-underline{
        width:calc(100% - 38px);
        border:1px solid #ECF8FE;
        margin: 0 38px 8px 0;
        padding:0;
    }
    .user-id{
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin:0;
    }
    .service-title {
        margin:4px 0 0 0;
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        max-width: 309px;
    }
    .service-selected-option {
        display: flex;
        align-items: center;
        gap: 6px;
        margin: 4px 0 0 0;
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        max-width: 309px;
    }
    .dot {
        width: 4px;
        height: 4px;
        background-color: black;
        border-radius: 50%;
    }
    .booking-item-date{
        color: var(--placeholder-color);
        font-family: Montserrat;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin:4px 0 5px 0;
    }
    .info-grid-item{
        width:50%;
        padding:5px 30px 5px 0;
        display: flex;
        gap:8px;
        span{
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .info-grid{
        width:100%;
        max-width:933px;
        display: flex;
        flex-wrap: wrap;
        background-color: #F6F6F8;
        padding:12px;
    }
    .info-grid-item-icon{
        width: 13px;
        height: 13px;

        ::v-deep{
            svg{
                width:100%;
                height:100%;
                object-fit: contain;
            }
        }
    }

    .cat-wrap{
        .parent-cat{
            color: var(--primary-color);
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin:0 0 2px 0;
        }
        .cat{
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin:0;
        }
    }
    .section-c-line-1{
        width:100%;
        display: flex;
        justify-content:space-between;
        align-items:center;
        padding-left:15px;
        border-left:1px solid #ECF8FE;

        @media(max-width:1199px){
            padding-left: 28px;
        }
    }
    .buttons-wrap{
        display:flex;
        gap:10px;
    }
    .notes-wrap{
        padding-left:15px;
        @media(max-width:1199px){
            padding-left: 28px;
        }
    }
    .notes{
        background:#F6F6F8;
        padding:12px;
        width:100%;
        border-radius:10px;
        margin-top:24px;
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        min-height: 80px;
    }

    .buttons-wrap .status{
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &.cancelled, &.declined {
            color: var(--tertiary-color);
        }
    }
}

.service-item-mobile{
    display:none;
    @media(max-width:767px){
        display:block;
    }

    h1{
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin:9px 0 14px 0;
    }

    .mobile-user-avatar{
        width:89px;
        height:89px;
        border-radius: 12px;
        overflow:hidden;
        
        &.new{
            border: 3px solid #FC6030;
        }

        img{
            width:100%;
            height:100%;
            object-fit: cover;
        }
    }
    .mobile-user-info{
        display:flex;
        gap:11px;
        align-items: center;
    }
    .mobile-customer-name{
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin:0;
    }
    .mobile-user-id{
        margin:0;
        font-family: Montserrat;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color:var(--placeholder-color)
    }
    .section-h-line-4{
        display: flex;
        justify-content: space-between;
        align-items:center;
        width: 100%;
        margin-top:9px;
    }
    .section-h{
        flex-grow:1;
        
    }
    .mobile-chat-icon{
       width:16px;
       height:16px;
       margin-right:4px;
       ::v-deep{
           svg{
               width:100%;
               height:100%;
               margin-right:4px;
           }
       }
    }
    .mobile-chat-button{
        display:flex;
        align-items: center;
        cursor:pointer;
        span{
            color: var(--secondary-text-color);
            text-align: center;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-decoration-line: underline;
        }
    }
    .mobile-historic-button{
        color: var(--primary-color);
        text-align: right;
        font-family: Montserrat;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
    }
    .mobile-service-title{
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom:0;
    }
    .mobile-booking-item-date{
        font-family: Montserrat;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color:var(--placeholder-color);
        margin-top:7px;
    }
    .mobile-section-underline{
        border:1px solid #ECF8FE;
        padding:0;
        width:100%;
    }
    .mobile-info-grid-item{
        width: 100%;
        padding-bottom:12px;
        display:flex;
        gap:8px;

        .mobile-info-grid-item-icon{
            width: 12px;
            height: 12px;

            ::v-deep{
                svg{
                    width:100%;
                    height:100%;
                    object-fit: contain;
                }
            }   
        }

        span{
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            strong{
                font-weight: 600;
            }
        }
    }
    .mobile-parent-cat{
        color: var(--primary-color);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 0;
    }
    .mobile-cat{
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin:0;
    }
    .mobile-notes{
        color: #162659;
        font-family: Montserrat;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding:10px;
        width:100%;
        border-radius: 10px;
        background:#F6F6F8;
        margin-top:14px;
    }
    .mobile-buttons-wrap{
        display: flex;
        gap:9px;
        justify-content: flex-end;
        margin-top:17px;
    }
    
    .mobile-buttons-wrap .status{
        color: var(--secondary-text-color);
        font-family: Montserrat;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        
        &.cancelled, &.declined {
            color: var(--tertiary-color);
        }
    }
}

.back-after-24-hours {
    background-color: #FC603020;
}

.back-after-48-hours {
    background-color: #FC603075;
}

.section-b-underline-black {
    border: none !important;
    border-bottom: 0.5px solid var(--placeholder-color) !important;
}

.section-c-line-1-black {
    border-left: 1px solid var(--placeholder-color) !important;
}
</style>