<script setup>
import { reactive, ref, onMounted } from 'vue';
import VueApexCharts from "vue3-apexcharts";
import ToggleCardComponent from '../ToggleCardComponent.vue';
import { useScreenSizeBehaviours } from '@/composables/useScreenSizeBehaviours';

const { isMobileSize } = useScreenSizeBehaviours()

const props = defineProps({
    revenue: Number,
    profit: Number
})

const data = reactive({
    series: [
        {
            name: "Profit",
            data: [0,0,0,0,0,0,0],
        },
        {
            name: "Total",
            data: [0,0,0,0,0,0,0]
        }
    ],
    chartOptions: {
        chart: {
            type: 'bar',
            height: 150,
            stacked: true,
            toolbar: {
                show: false
            },
            sparkline: {
                // enabled: true
            },
            offsetX: -10,
        },
        colors: ['#25AAE5', '#0B55BD'],
        plotOptions: {
            bar: {
                columnWidth: "25%",
                endingShape: "rounded",
                borderRadiusWhenStacked: "all",
                borderRadius: 5,

                colors: {
                    backgroundBarColors: ['#E7F6FD', '#E7F6FD', '#E7F6FD', '#E7F6FD'],
                    backgroundBarOpacity: 1,
                    backgroundBarRadius: 5,
                },

            },
            distributed: true
        },
        grid: {
            show: false,
        },
        legend: {
            show: false
        },
        fill: {
            opacity: 1
        },
        dataLabels: {
            enabled: false,
            colors: ['#000'],
            dropShadow: {
                enabled: true,
                top: 1,
                left: 1,
                blur: 1,
                opacity: 1
            }
        },
        xaxis: {
            categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            labels: {
                style: {
                    colors: '#162659',
                    fontSize: '13px',
                    fontFamily: 'Montserrat',
                    fontWeight: 100,
                    cssClass: 'apexcharts-xaxis-label',
                },
            },
            crosshairs: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },

        yaxis: {
            show: false
        },

        tooltip: {
            x: {
                show: true
            }
        }
    }
})
</script>

<template>
    <ToggleCardComponent title="REVENUE IN €" :show="true" :toggable="isMobileSize">
        <div class="labels">
            <div>
                <h2>Total</h2>
                <h1>{{ revenue }}</h1>
            </div>
            <div>
                <h2>Profit</h2>
                <h1>{{ profit }}</h1>
            </div>
        </div>
        <div id="revenueChart">
            <VueApexCharts id="chart" type="bar" :options="data.chartOptions" :series="data.series"></VueApexCharts>
        </div>
    </ToggleCardComponent>
</template>

<style lang="scss" scoped>

::v-deep main {
    padding-bottom: 5px;
}

#revenueChart {
    display: flex;
    justify-content: center;
    width: 100%;
}
.labels {
    display: flex;
    max-width: 320px;
    margin: auto 29px;

    
    div {
        display: flex;
        flex: 1;
        padding-left: 17px;
        position: relative;
        height: 39px;
        flex-direction: column;
        justify-content: center;
        
        &:before {
            position: absolute;
            width: 9px;
            content: "";
            display: block;
            background-color: var(--dark-blue);
            height: 100%;
            border-radius: 4.5px;
            left: 0;
        }

        &:nth-child(2) {
            &:before {
                background-color: var(--primary-color);
            }
        }

        h2 {
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0;
        }
        h1 {
            color: var(--secondary-text-color);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
        }
    }
}
</style>