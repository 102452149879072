<script setup>
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
    name: String,
    width: {
        type: String,
        require: false
    },
    height: {
        type: String,
        require: false
    },
})

let svgContent = ref();
const styles = computed(() => {
    if(props.width) {
        return {
            width: props.width,
            height: props.height
        }
    }
})

onMounted(async () => {
    try {
        // Dynamically import the SVG file based on the 'name' prop.
        const svgModule = await import(`@/assets/svg/${props.name}.svg`);
        const response = await fetch(svgModule.default);
        svgContent.value = await response.text();
    } catch (error) {
        console.error(`Error loading SVG file for '${props.name}':`, error);
    }
})
</script>

<template>
    <div v-html="svgContent" :style="styles" />
</template>

<style lang="scss" scoped>
div {
    margin: 0;
    padding: 0;
}
</style>