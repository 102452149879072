<script setup>
    // import libraries
    import { computed } from 'vue'
    import { useStore } from 'vuex'
    import ButtonComponent from '@/components/ButtonComponent.vue'
    import SvgComponent from '@/components/SvgComponent.vue'

    // reactiveVariables
    const store = useStore()

    // ComputedVariables
    let warningModal = computed(() => {
        return store.getters['event/getWarningModal']
    })

    // Methods
    function closeModal() {
        store.dispatch('event/closeWarningModal')
    }

    function onClickButton(buttonIndex) {
        if(
            warningModal.value.buttons[buttonIndex].action
            && typeof warningModal.value.buttons[buttonIndex].action === "function"
        ) {
            warningModal.value.buttons[buttonIndex].action()
        }

        closeModal()
    }

    function onClickExit() {
        if(
            warningModal.value.exitAction
            && typeof warningModal.value.exitAction === "function"
        ) {
            warningModal.value.exitAction()
        }

        closeModal()
    }

</script>

<template>
<div class="warning-modal-wrap" v-if="warningModal.show">
    <div class="warning-modal-overlay" @click="onClickExit()"></div>
    <div class="warning-modal">
        <p class="warning-modal-title">{{ warningModal.title }}</p>
        <div class="warning-modal-body" v-html="warningModal.htmlCopy"></div>
        <div class="buttons-wrap">
            <ButtonComponent
                v-for="(button, i) in warningModal.buttons"
                :key="'warning-button-' + i"
                @click="onClickButton(i)"
                v-bind="button.htmlAttributes"
            >
                {{ button.copy }}
            </ButtonComponent>
        </div>

        <ButtonComponent class="close-modal" color="transparent" simple :size="{ width: '27px', height: '27px' }" @click="onClickExit()">
            <SvgComponent name="IconClose" />
        </ButtonComponent>
    </div>
</div>
</template>

<style lang="scss" scoped>
.warning-modal-wrap{
    position:fixed;
    top:0;
    left:0;
    height:100%;
    width:100%;
    z-index: 19990;
    padding:0 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.warning-modal-overlay{
    width:100%;
    position: absolute;
    height:100%;
    top:0;
    left:0;
    background:rgba(0,0,0,0.7);
}
.warning-modal{
    width:100%;
    max-width:276px;
    border-radius: 12px;
    border: 1.5px solid var(--light-primary-color);
    background: var(--light-color);
    border-radius: 12px;
    position: relative;
    padding:22px 30px 20px 30px;
}
.warning-modal-title{
    margin:0 0 9px 0;
    color: var(--secondary-text-color);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align:center;
}
.warning-modal-body{
    margin-bottom:22px;
}
.warning-modal-body, .warning-modal-body p{
    color: var(--secondary-text-color);
    text-align: center;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    strong, b{
        font-weight: 600;
    }
}
.buttons-wrap{
    display: inline-flex;
    justify-content: space-between;
    width:100%;
}
.close-modal{
    position: absolute;
    top: 8px;
    right: 8px;
}
</style>