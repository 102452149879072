import ApiManager from "@/helpers/ApiManager"

function paramsObjectToString(paramsObject) {
    let paramsArr = []
    for (let key in paramsObject) {
        if (paramsObject[key]) {
            paramsArr.push(key + "=" + paramsObject[key])
        }
    }

    return paramsArr.join("&");
}

const state = {
    affiliates: [],
}

const getters = {
    getAffiliates: state => state.affiliates ?? [],
    getAffiliateOptions: state => {
        return state.affiliates.reduce((result, affiliate) => {
            result[affiliate.id] = `${affiliate.first_name} ${affiliate.last_name}`
            return result
        }, {})
    },
}

const actions = {
    fetchAffiliates: async ({ commit }, payload) => {
        const paramsString = paramsObjectToString({ page: 1, ...payload })
        const getAttempt = await ApiManager.get(`api/super/affiliates?${paramsString}`)

        if (getAttempt) {
            commit('SET_AFFILIATES', getAttempt.data.content.data)
            return true
        }

        return false
    },
}

const mutations = {
    SET_AFFILIATES: (state, payload) => {
        state.affiliates = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}