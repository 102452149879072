<script setup>
    // import libraries
    import { computed, onMounted, reactive, ref, watch } from 'vue'
    import { useStore } from 'vuex'
    import router from '@/router'
    // custom imports
    /* import CustomComponent from '@/components/customComponent' */
    import RevenueChartComponent from '@/components/charts/RevenueChartComponent.vue'
    import ViewsChartComponent from '@/components/charts/ViewsChartComponent.vue'
    import BookedServicesChartComponent from '@/components/charts/BookedServicesChartComponent.vue'
    import StatsComponent from '@/components/StatsComponent.vue'
    import HeaderLayout from '@/layouts/HeaderLayout.vue'
    import SidebarLayout from '@/layouts/SidebarLayout.vue'
    import { useScreenSizeBehaviours } from '@/composables/useScreenSizeBehaviours'
    import AdComponent from '@/components/AdComponent.vue'
    import ButtonComponent from '@/components/ButtonComponent.vue'
    import SvgComponent from '@/components/SvgComponent.vue'

    // consts (props, otherConstants)
    // (variables)
    const store = useStore()
    let name = 'HomeView'

    const { isMobileSize } = useScreenSizeBehaviours()

    // reactiveVariables

    // ComputedVariables
    const isAuthenticated   = computed(() => store.getters['user/getIsLoggedIn'])
    const scheduledBookings = computed(() =>  store.getters['booking/getScheduledBookingItems']?.length ?? 0)
    const cancelledBookings = computed(() =>  store.getters['booking/getCancelledBookingItems']?.length ?? 0)
    const completedBookings = computed(() =>  store.getters['booking/getCompletedBookingItems']?.length ?? 0)
    const archivedBookings  = computed(() =>  store.getters['booking/getArchivedBookingItems']?.length ?? 0)
    const revenue           = computed(() =>  {
        if(store.getters['booking/getCompletedBookingItems']?.length) {
            return store.getters['booking/getCompletedBookingItems'].reduce((accumulator, currentValue) => accumulator + Number(currentValue.value), 0)
        }
        return 0
    })
    const totalBookings = computed(() => scheduledBookings.value + archivedBookings.value)
    const serviceViews = computed(() => store.getters['company/getServiceViews'])

    // Methods
    function logout() {
        localStorage.setItem('user', '{}')
        router.push({"name":"login"})
    }

    function onClickAd() {
        alert('Go to ad url')
    }

    function onClickChatIcon() {
        alert('Go to chat')
    }

    // watchers

    // Callbacks Lifecycle
    onMounted(() => {
        if (! isAuthenticated.value) {
            router.push({ "name" : "login" })
        }

        store.dispatch('booking/fetchBookingItems', {reset: true, destination: 'new', statuses: ['pending'], items: -1})
        store.dispatch('booking/fetchBookingItems', {reset: true, destination: 'accepted', statuses: ['confirmed'], items: -1})
        store.dispatch('booking/fetchBookingItems', {reset: true, destination: 'archived', statuses: ['cancelled', 'completed', 'declined'], items: -1})

        store.dispatch('company/fetchServiceViews')
    })
</script>

<template>
    <div class="salutation">
        <h1>Welcome</h1>
        <p>See all your latest results</p>
    </div>
    <div class="row d-flex">
        <RevenueChartComponent :revenue="revenue" :profit="revenue" />
        <ViewsChartComponent v-if="serviceViews" :views="serviceViews" />
    </div>
    <div class="row d-flex">
        <AdComponent @click="onClickAd"/>
    </div>
    <div class="row d-flex" v-if="totalBookings">
        <BookedServicesChartComponent :values="[Number(scheduledBookings), Number(cancelledBookings), Number(totalBookings)]" />
    </div>
    <div class="row d-flex">
        <StatsComponent color="#0330AD" icon="IconCalendar" title="BOOKINGS SINCE DAY 1" :value="Number(totalBookings)" />
        <StatsComponent color="#0B55BD" icon="IconMoneyUp" title="TOTAL REVENUE SINCE DAY 1" :value="`€${revenue}`" />
    </div>
    <div class="row d-flex">
        <StatsComponent color="#1C88D7" icon="IconMoney" title="TOTAL INVESTIMENT IN ADVERTISING" value="0" />
        <StatsComponent color="#2DC6F1" icon="IconPeople" title="HAPPY TRAVELLERS" :value="completedBookings" />
    </div>
</template>

<style lang="scss" scoped>
.row {
    flex-direction: column;
    gap: 19px;
    margin-bottom: 19px;

    @media screen and (min-width: 1200px) {
        flex-direction: row;
    }
}
</style>