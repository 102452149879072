import ApiManager from "@/helpers/ApiManager"

function paramsObjectToString(paramsObject) {
    let paramsArr = []
    for (let key in paramsObject) {
        if (paramsObject[key]) {
            paramsArr.push(key + "=" + paramsObject[key])
        }
    }

    return paramsArr.join("&");
}

const state = {
    clients: [],
}

const getters = {
    getClients: state => state.clients ?? [],
}

const actions = {
    fetchClients: async ({ commit }, payload) => {
        const paramsString = paramsObjectToString({ page: 1, ...payload })
        const getAttempt = await ApiManager.get(`api/super/clients?${paramsString}`)

        if (getAttempt) {
            commit('SET_CLIENTS', getAttempt.data.content.data)
            return true
        }

        return false
    },
}

const mutations = {
    SET_CLIENTS: (state, payload) => {
        state.clients = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}