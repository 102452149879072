import ApiManager from "@/helpers/ApiManager"

const state = {
    tabs: JSON.parse(localStorage.getItem('tabs')) ?? [],
    activeTab: localStorage.getItem('activeTab') ?? 0,
    activeUser: JSON.parse(localStorage.getItem('activeUser')) ?? {}
}

const getters = {
    getTabs: state => state.tabs ?? [],
    getActiveTab: state => state.activeTab ?? 0,
    getActiveUser: state => state.activeUser ?? {},
    getActiveUserSettings: (state, getters) => {
        const user = getters.getActiveUser

        return {
            ...user,
            ...user?.profile,
            ...user?.company,
            contact: user.phone,
            first_name: user.first_name,
            last_name: user.last_name,
            vat: user?.profile?.vatnumber ?? '',
            iban: user?.profile?.iban ?? user?.company?.iban,
            bicswift: user?.profile?.bicswift ?? user?.company?.bicswift,
        }
    },
    getDataForProfileForm: (state) => {
        let data = {
            firstName: state.activeUser.first_name,
            lastName: state.activeUser.last_name,
            password: '',
            confirmPassword: '',
            phone: state.activeUser.phone,
            country: "pt"
        }

        if (state.activeUser.company) {
            const modules = JSON.parse(state.activeUser.company.modules)
            
            data = {
                ...data,
                isExperience: modules.experiences == 1 ? true: false,
                isTransfer: modules.transfers == 1 ? true: false,
                businessName: state.activeUser.company.business_name,
                taxNumber: state.activeUser.company.tax_number,
                address: state.activeUser.company.address,
                logo: state.activeUser.company.logos.map((logo) => logo.url),
                zipCode: state.activeUser.company.zip_code,
                city: state.activeUser.company.city,
                numberOfEmployees: state.activeUser.company.number_of_employees,
                tradeName: state.activeUser.company.trade_name,
                description: state.activeUser.company.description,
                website: state.activeUser.company.website,
                email: state.activeUser.company.email
            }
        }

        return data;
    },
}

const actions = {
    initTabs: ({ commit }, payload) => {
        commit('INIT_TABS', payload)
        commit('SELECT_TAB', 0)
        commit('FETCH_ACTIVE_USER')
    },
    addTab: ({ commit, state }, payload) => {
        commit('ADD_TAB', payload)
        commit('SELECT_TAB', state.tabs.length - 1)
        commit('FETCH_ACTIVE_USER')
    },
    selectTab: ({ commit }, index) => {
        commit('SELECT_TAB', index)
        commit('FETCH_ACTIVE_USER')
    },
    removeTab: ({ commit }, index) => {
        commit('REMOVE_TAB', index)
        commit('SELECT_TAB', 0)
        commit('FETCH_ACTIVE_USER')
    },
    fetchActiveUser: ({ commit }) => commit('FETCH_ACTIVE_USER'),
}

const mutations = {
    INIT_TABS: (state, payload) => {
        state.tabs = []
        state.tabs.push(payload)

        localStorage.setItem('tabs', JSON.stringify(state.tabs))
    },
    ADD_TAB: (state, payload) => {
        state.tabs.push(payload)

        if (state.tabs.length > 3) {
            state.tabs.splice(1, 1)
        }

        localStorage.setItem('tabs', JSON.stringify(state.tabs))
    },
    SELECT_TAB: async (state, index) => {
        if (! state.tabs[index]) {
            return false
        }

        state.activeTab = index

        localStorage.setItem('activeTab', JSON.stringify(state.activeTab))
    },
    REMOVE_TAB: (state, index) => {
        state.tabs.splice(index, 1)
        localStorage.setItem('tabs', JSON.stringify(state.tabs))
    },
    FETCH_ACTIVE_USER: async (state) => {
        if (state.activeTab === 0) {
            state.activeUser = state.tabs[state.activeTab]
        } else {
            const activeUser = state.tabs[state.activeTab]
            const result = await ApiManager.get(`api/user/${activeUser.id}`).then((response) => {
                if (response?.data?.status) {
                    return response.data.content
                }
    
                return false
            })
    
            state.activeUser = result
            state.tabs[state.activeTab] = result
            
            localStorage.setItem('tabs', JSON.stringify(state.tabs))
        }

        localStorage.setItem('activeUser', JSON.stringify(state.activeUser))
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}