<script setup>
// import libraries
import { computed } from 'vue'
import SvgComponent from '@/components/SvgComponent.vue'

const emit = defineEmits(['click']);

// consts (props, otherConstants)
const props = defineProps({
    type: { type: String, default: 'button' },
    simple: { type: Boolean, default: false },
    full: { type: Boolean, default: false },
    icon: { type: Boolean, default: false },
    sm: { type: Boolean, default: false },
    circular: { type: Boolean, default: false },
    bordered: { type: [Boolean, String], default: false },
    color: { type: String, default: 'light' },
    size: { type: Object, required: false },
    rounded: { type: Boolean, default: false},
    disabled: { type: Boolean, default: false},
    isProcessing: { type: Boolean, default: false},
})

// ComputedVariables
let styleClasses = computed(() => ({
    [(props.color)]: props.color,
    full: props.full,
    simple: props.simple,
    icon: props.icon,
    sm: props.sm,
    circular: props.circular,
    bordered: props.bordered,
    rounded: props.rounded
}))

// Methods
function onClick() {
    emit('click')
}
</script>

<template>
    <button :type="type" @click="onClick" :class="styleClasses" :style="[size, isProcessing ? { padding: '14px' } : {}]"  :disabled="disabled || isProcessing">
        <SvgComponent v-if="isProcessing" name="spinner" class="icon" />
        <slot v-else></slot>
    </button>
</template>

<style lang="scss" scoped></style>