<script setup>
import store from '@/store';
import ModalComponent2 from './ModalComponent2.vue';
import BookingRequestComponent from './BookingRequestComponent.vue';
import { computed } from 'vue';
import BookingHistoryHeaderComponent from './BookingHistoryHeaderComponent.vue';

// Emits
const emit = defineEmits(['onClose'])

// props
const props = defineProps({
    showModal: { type: Boolean, default: false }
})

const bookingHistory = computed(() => store.getters['booking/getBookingHistory'])
// const bookings = computed(() => bookingHistory.)

// Methods
function onCloseModal() {
    store.commit('booking/CLEAR_BOOKING_HISTORY')
}
</script>

<template>
    <ModalComponent2 class="modal2" :showModal="showModal" @close="onCloseModal()" width="979px">
        <BookingHistoryHeaderComponent :info="bookingHistory[0]" :numberOfServices="bookingHistory.length" />
        <BookingRequestComponent
            v-for="booking, index in bookingHistory" 
            :key="index" 
            :booking="booking"
            type="history"
            :class="`the-item the-item-${index}`"
        />
    </ModalComponent2>
</template>

<style lang="scss" scoped>
.modal2 {
    ::v-deep {
        .modal2 {
            overflow-y: auto;
            max-width: 979px;
        }
    }
}
</style>