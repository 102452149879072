<script setup>
// import libraries
import { computed, inject, onMounted, reactive, ref, nextTick } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'
import { dateFormat2 } from '@/composables/dateFormat.js'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import locales from '@fullcalendar/core/locales-all'
import en from '@/locale/calendar/en'
import '@/assets/styles/calendar.scss'
import CardComponent from '@/components/CardComponent.vue'
import SvgComponent from '@/components/SvgComponent.vue'
import BookingsComponent from '@/components/BookingsComponent.vue'
import BookingRequestComponent from '@/components/BookingRequestComponent.vue'
import BookingHistoryChartComponent from '@/components/charts/BookingHistoryChartComponent.vue'
import BookingHistoryComponent from '@/components/BookingHistoryComponent.vue'
import RatingStars from '@/components/RatingStars.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

// consts (props, otherConstants)
// (variables)
const auth = inject('auth')
const store = useStore()
const user = ref({})
const fullCalendar = ref(null)

// const { isMobileSize } = useScreenSizeBehaviours()

const calendarOptions = reactive({
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    firstDay: 1,
    headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,timeGridDay'
    },
    locales: [...locales, en],
    locale: 'en',
    events: computed(() => {
        return Object.values(store.getters['booking/getScheduledBookingItems']).map((event) => (
            {
                title: event.user.name,
                date: event.eventDate,
                end: event.eventDate,
            })
        )
        ?? []
    }),
    customButtons: {
        next: {
            click: (e) => {
                let calendarApi = fullCalendar.value.getApi();
                calendarApi.next();
                let date = new Date(calendarApi.getDate())
                paginate('scheduled', date)
            }
        }
    },
    eventClick: onClickEvent,
})
// reactiveVariables

const showButton = (date, view) => {
    if(view.type !== 'dayGridMonth') return false
    return calendarOptions.events.find(event => event.date === date.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}))
}
// ComputedVariables
const isAuthenticated = computed(() => store.getters['user/getIsLoggedIn'])

const newBookings = computed(() => store.getters['booking/getNewBookingItems'])
const acceptedBookings = computed(() =>  store.getters['booking/getAcceptedBookingItems'])
const archivedBookings = computed(() =>  store.getters['booking/getArchivedBookingItems'])
const totalAccepted = computed(() => store.getters['booking/getAcceptedBookingsTotal'])
const totalArchived = computed(() => store.getters['booking/getArchivedBookingsTotal'])
const totalNewBookings = computed(() => store.getters['booking/getNewBookingsTotal'])
const bookingHistory = computed(() => store.getters['booking/getBookingHistory'])
const isSuper = computed(() => store.getters['user/isSuper'])
const activeUser = computed(() => store.getters['superAdm/getActiveUser'])

// Methods
function logout() {
    localStorage.setItem('user', '{}')
    router.push({ "name": "login" })
}

function onClickAd() {
    alert('Go to ad url')
}

function handleViewRender(info) {
    console.log('handleView', info)
}

function onClickEvent(e) {
    // alert(e.event.title)
}

async function updateStatus(payload) {
    await store.dispatch('booking/updateStatus', payload)
    await nextTick()
    initializeBookings(true)
}
// watchers

function initializeBookings(reset = false)
{
    store.dispatch('booking/fetchBookingItems', {reset: reset, service_type: 'experiences', destination: 'new', statuses: ['pending']})
    store.dispatch('booking/fetchBookingItems', {reset: reset, service_type: 'experiences', destination: 'accepted', statuses: ['confirmed']})
    store.dispatch('booking/fetchBookingItems', {reset: reset, service_type: 'experiences', destination: 'archived', statuses: ['cancelled', 'completed', 'declined'], orderDirerction: 'desc',  orderBy: 'updated_at'})

    const currentDate = new Date();
    const startDate   = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'})
    const endDate     = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'})

    store.dispatch('booking/fetchBookingItems', {service_type: 'experiences', destination: 'scheduled', statuses: ['confirmed'], startDate: startDate, endDate: endDate})
}

function changeServiceType(destination, type) {
    if (destination === 'scheduled') {
        store.dispatch('booking/fetchBookingItems', { reset: true, service_type: type, destination: 'scheduled', statuses: ['confirmed']})
    } else if(destination === 'accepted') {
        store.dispatch('booking/fetchBookingItems', { reset: true, service_type: type, destination: 'accepted', statuses: ['confirmed'], items: 6 })
    } else if(destination === 'new') {
        store.dispatch('booking/fetchBookingItems', { reset: true, service_type: type, destination: 'new', statuses: ['pending'], items: 6 })
    } else {
        store.dispatch('booking/fetchBookingItems', { reset: true, service_type: type, destination: 'archived', statuses: ['cancelled', 'completed', 'declined'], items: 6 })
    }
}

function paginate(destination, type='experiences', startDate = null) {
    if (destination === 'scheduled') {
        const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'})

        store.dispatch('booking/fetchBookingItems', { service_type: type, destination: 'scheduled', statuses: ['confirmed'], startDate: startDate, endDate: endDate})
    } else if(destination === 'accepted'){
        store.dispatch('booking/fetchBookingItems', { service_type: type, destination: 'accepted', statuses: ['confirmed'], items: 6 })
    } else if(destination === 'new'){
        store.dispatch('booking/fetchBookingItems', { service_type: type, destination: 'new', statuses: ['pending'], items: 6 })
    } else {
        store.dispatch('booking/fetchBookingItems', { service_type: type, destination: 'archived', statuses: ['cancelled', 'completed', 'declined'], items: 6 })
    }
}

function order(destination, direction, type='experiences', startDate = null) {
    if (destination === 'scheduled') {
        const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'})

        store.dispatch('booking/fetchBookingItems', { service_type: type, destination: 'scheduled', statuses: ['confirmed'], startDate: startDate, endDate: endDate,  orderDirerction: direction, sort: true,  orderBy: 'updated_at'})
    } else if(destination === 'accepted'){
        store.dispatch('booking/fetchBookingItems', { service_type: type, destination: 'accepted', statuses: ['confirmed'], items: 6,  orderDirerction: direction, sort: true,  orderBy: 'updated_at' })
    } else if(destination === 'new'){
        store.dispatch('booking/fetchBookingItems', { service_type: type, destination: 'new', statuses: ['pending'], items: 6,  orderDirerction: direction, sort: true,  orderBy: 'updated_at' })
    } else {
        store.dispatch('booking/fetchBookingItems', { service_type: type, destination: 'archived', statuses: ['cancelled', 'completed', 'declined'], items: 6,  orderDirerction: direction, sort: true,  orderBy: 'updated_at' })
    }
}

const goDayView = (date, view) => {
    // Navigate to day view for the specified date
    const calendarApi = fullCalendar.value.getApi();
    calendarApi.changeView('timeGridDay', date);
};

const stars = computed(() => {
    let totalStars = 0
    for (let review of auth.value.company.reviews) {
        totalStars+=review.stars
    }

    return (totalStars === 0)
        ? 0
        : totalStars/auth.value.company.reviews.length
})

const getUser = () => {
    user.value = isSuper.value && activeUser.value.id !== -1
        ? activeUser.value 
        : auth.value
}

// Callbacks Lifecycle
onMounted(() => {
    initializeBookings()
    getUser()

    if (!isAuthenticated.value) {
        router.push({
            "name": "login"
        })
    }
})
</script>

<template>
    <div>
        <div class="salutation">
            <h1>Calendar & Bookings</h1>
            <p>Manage your schedule</p>
        </div>
        <div class="row d-flex">
            <div class="column" v-if="auth">
                <CardComponent class="company-card">
                    <div :class="user.company?.company_type === 'EXPERIENCES' ? 'image-container-service' : 'image-container-transfer'">
                        <img v-if="user.avatar_image" :src="user.avatar_image" />
                        <img v-else-if="auth.avatar_image" :src="auth.avatar_image"/>
                        <img v-else src="@/assets/images/user_fallback_avatar.png" />
                    </div>
                    <div class="company-info">
                        <h1>{{ user.company?.trade_name }}</h1>
                        <p class="company-type">{{ user.company?.company_type }}</p>
                        <span class="reviews">
                            <RatingStars :rating="stars" />
                            {{user.company?.reviews.length}} reviews
                        </span>
                        <span class="last-activity">
                            <SvgComponent name="IconClock" /> Last active {{ dateFormat2(user.last_active_at) }}
                        </span>
                    </div>
                </CardComponent>
                <BookingHistoryChartComponent />
            </div>
            <div class="column">
                <CardComponent title="Schedule">
                    <FullCalendar ref="fullCalendar" class="app-fullcalendar fc fc-unthemed fc-ltr" :options="calendarOptions"  @viewRender="handleViewRender">
                        <template v-slot:eventContent='arg'>
                            <b>{{ arg.event.title }}</b>
                        </template>
                        <template v-slot:dayCellContent="{date, view}">
                            <span>{{ date.getDate() }}</span>
                            <span class="eventButton" @click="goDayView(date)" v-if="showButton(date, view)">+</span>
                        </template>
                    </FullCalendar>
                </CardComponent>
            </div>
        </div>

        <div class="row d-flex direction-column">
            <BookingsComponent :bookings="newBookings" title="NEW" description="Manage new bookings" @onClick="updateStatus" @onChangeServiceType="(type) => changeServiceType('new', type)" @onPaginate="paginate('new')" @onSort="(direction) => order('new', direction)" :slidesLength="totalNewBookings" />
            <BookingsComponent :bookings="acceptedBookings" title="ACCEPTED" description="See your accepted services here" @onChangeServiceType="(type) => changeServiceType('accepted', type)" @onPaginate="paginate('accepted')" @onSort="(direction) => order('accepted', direction)" :slidesLength="totalAccepted" />
            <BookingsComponent :bookings="archivedBookings" title="ARCHIVED" description="See your completed, declined and canceled services" @onChangeServiceType="(type) => changeServiceType('archived', type)" @onPaginate="paginate('archived')" @onSort="(direction) => order('archived', direction)" :slidesLength="totalArchived" />
        </div>
        <BookingHistoryComponent :showModal="Object.entries(bookingHistory).length" />
    </div>
</template>

<style lang="scss" scoped>
.row {
    flex-direction: column;
    gap: 25px;
    margin-bottom: 20px;

    &:not(.direction-column) {
        @media screen and (min-width: 1200px) {
            flex-direction: row;
        }
    }

    .column {
        display: flex;
        gap: 15px;
        flex: 1;

        &:first-child {
            @media screen and (min-width: 1200px) {
                max-width: 348px;
                flex-direction: column;
            }

            @media screen and (max-width: 1199px) {
                flex-direction: column;
                gap: 25px;
            }
        }

        .app-fullcalendar {
            width: 100%;
            max-height: 380px;
        }
    }
}

.company-card {
    ::v-deep .company-card {
        display: flex;
        flex-direction: row;
        min-height: 114px;
        padding: 17px;
        gap: 12px;

        &>div {
            flex: 1;
            position: relative;

            &.image-container-service {
                max-width: 101.3px;
                height: 101.3px;
                box-sizing: border-box;
                margin-bottom: 20px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 8px;
                }

                &:after {
                    display: block;
                    content: "";
                    width: 46.25px;
                    height: 46.25px;
                    position: absolute;
                    background-color: var(--primary-color);
                    background-image: url(@/assets/svg/IconRoute.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    border: 2px solid #ffffff;
                    border-radius: 50%;
                    right: -20px;
                    bottom: -20px;
                }
            }

            &.image-container-transfer {
                max-width: 101.3px;
                height: 101.3px;
                box-sizing: border-box;
                margin-bottom: 20px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 8px;
                }

                &:after {
                    display: block;
                    content: "";
                    width: 46.25px;
                    height: 46.25px;
                    position: absolute;
                    background-color: var(--primary-color);
                    background-image: url(@/assets/svg/IconTransfer.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 26px 26px;
                    border: 2px solid #ffffff;
                    border-radius: 50%;
                    right: -20px;
                    bottom: -20px;
                }
            }

            &.company-info {
                margin-left: 16px;

                h1 {
                    color: var(--primary-color-v2);
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding: 0;
                    margin: 0;
                }

                p {
                    color: #162659;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 6px 0;
                    margin: 0;
                }

                .reviews {
                    display: flex;
                    align-items: center;
                    gap: 11.75px;
                    color: #162659;
                    font-family: Montserrat;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 12.77px;
                    ;
                }

                .last-activity {
                    display: flex;
                    align-items: center;
                    color: #162659;
                    font-family: Montserrat;
                    font-size: 9px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    svg{
                        margin-right: 7.68px;
                        display: flex;
                    }
                }
            }
        }
    }
}

.direction-column {
    gap: 54px;
}

.company-type{
    text-transform:lowercase;

    &:first-letter{
        text-transform: uppercase;
    }
}
</style>