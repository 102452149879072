import { computed } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'

export function useLoginForm() {
    const store = useStore()

    let form = store.getters['user/getForm']

    for (const [key, value] of Object.entries(form)) {
        form[(key)] = computed({
            get: () => value,
            set: (field) => {
                store.dispatch('user/setFormData', { [(key)]: field })
            }
        })
    }

    const errors = computed(() => {
        return store.getters['user/getFormErrors']
    })

    const login = async () => {
        store.dispatch('user/resetErrors')

        return await store.dispatch('user/login')
            .then((response) => {
                if (response.status) {
                    return response
                }

                if (Object.keys(errors.value).length > 0) {
                    console.log('errors')
                }
                return false
            })
            .catch((errors) => {
                console.error('Front end action for errors after submission', errors)
            })
    }

    const logout = () => {
        store.dispatch('user/logout')
        window.open('/', '_parent')
    }

    return { form, errors, login, logout }
}