<script setup>
    // import libraries
    import { ref, computed, inject, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import router from '@/router'

    // custom imports
    import HeaderLayout from '@/layouts/HeaderLayout.vue'
    import SidebarLayout from '@/layouts/SidebarLayout.vue'
    import ButtonComponent from '@/components/ButtonComponent.vue'
    import SvgComponent from '@/components/SvgComponent.vue'
    import WarningComponentModal from '@/components/WarningComponentModal.vue'
    import { useScreenSizeBehaviours } from '@/composables/useScreenSizeBehaviours'

    import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding'
    import 'v-onboarding/dist/style.css'

    const wrapper = ref(null)
    const { start } = useVOnboarding(wrapper)

    // consts (props, otherConstants)
    // (variables)
    const auth = inject('auth')
    const store = useStore()
    const { isMobileSize } = useScreenSizeBehaviours()

    // ComputedVariables
    const isSuper = computed(() => store.getters['user/isSuper'])
    const isAuthenticated = computed(() => store.getters['user/getIsLoggedIn'])
    const onboardingDone = computed(() => localStorage.getItem('onboarding-home') ?? false)

    const steps = [
        { 
            attachTo: { element: '#welcome' }, 
            content: { 
                title: 'Let’s explore? - 1/6',
                description: 'Welcome to your profile! <br><br> In this menu, you can access and manage all aspects of your partner account.',
                html: true
            },
            options: {
                hideButtons: {
                    exit: true
                },
            },
        },
        { 
            attachTo: { element: '#dashboard' }, 
            content: { 
                title: 'Management - 2/6',
                description: 'Stay in control with our tools and manage your revenue, profile views, and booked services. <br><br> Get your latest results, align your outcomes or promote your services.',
                html: true
            },
            options: {
                hideButtons: {
                    exit: true
                },
            },
        },
        { 
            attachTo: { element: '#calendar-bookings' }, 
            content: { 
                title: 'Your bookings - 3/6',
                description: 'Manage your schedule effortlessly: <br><br> View, Accept or Decline bookings, all in one place.',
                html: true
            },
            options: {
                hideButtons: {
                    exit: true
                },
            },
        },
        { 
            attachTo: { element: '#services' }, 
            content: { 
                title: 'Services - 4/6',
                description: 'Here is where you can Add, Edit, Promote or Delete your Services. <br><br> Want to SEE how?',
                html: true
            },
            options: {
                hideButtons: {
                    exit: true
                },
            },
        },
        { 
            attachTo: { element: '#sales' }, 
            content: { 
                title: 'Sales - 5/6',
                description: 'Here you can SEE all sales made through this platform.',
                html: true
            },
            options: {
                hideButtons: {
                    exit: true
                },
            },
        },
        { 
            attachTo: { element: '#affiliate-area' }, 
            content: { 
                title: 'Monetize your audience - 6/6',
                description: 'Request discount codes for your clients and track revenue earned through their use.',
                html: true
            },
            options: {
                hideButtons: {
                    exit: true
                },
            },
            on: {
                afterStep: function (options) {
                    localStorage.setItem('onboarding-home', true)
                }
            }
        },
    ]

    function onClickChatIcon() {
        alert('Go to chat')
    }

    // Callbacks Lifecycle
    onMounted(() => {
        if (!store.getters["frontend/getPlatformCountries"]) {
            store.dispatch('frontend/fetchPlatformCountries')
        }

        if (!store.getters["frontend/getServiceCategories"]) {
            store.dispatch('frontend/fetchServiceCategories')
        }

        if (! isAuthenticated.value) {
            router.push({ "name" : "login" })
        }

        if (!onboardingDone.value && !isMobileSize.value && !isSuper.value && auth.value.role_id == 6) {
            start()
        }
    })
</script>

<template>
    <div class="home" country="pt">
        <VOnboardingWrapper ref="wrapper" :steps="steps" />
        <SidebarLayout />
        <main>
            <HeaderLayout @startBoarding="start"/>
            <router-view />
        </main>
        <router-link to="/user-panel/messages" custom v-slot={navigate}>
            <ButtonComponent id="openChatIcon" color="primary" circular :size="{ width: '50px', height: '50px' }" class="visible-mobile-flex" @click="navigate">
                <SvgComponent name="IconChat" width="25px" height="25px" />
            </ButtonComponent>
        </router-link>

        <WarningComponentModal />
    </div>
</template>

<style>
[data-popper-placement] {
  margin-top: 25px !important;
}

.v-onboarding-item {
    background-color: #7270FE !important;
    font-family: Montserrat;
}

.v-onboarding-item__header-title {
    color: #FFFFFF !important;
}

.v-onboarding-item__description {
    color: #FFFFFF !important;
}

.v-onboarding-btn-primary {
    background-color: #FFFFFF !important;
    color: #162659 !important;
}

.v-onboarding-btn-secondary {
    background-color: #7270FE !important;
    color: #FFFFFF !important;
    border: 1px solid #FFFFFF;
}
</style>

<style lang="scss" scoped>
.home {
    display: flex;
    margin: 20px 26px;

    main {
        width: 100%;
        @media screen and (min-width: 1200px) {
            padding: 30px;
        }
    }
}
</style>